@import "styles/mixins";

:root {
  --base-card-padding-horizontal: 50px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--focus-mode-overlay-z-index);
  background-color: var(--background-color);
  cursor: default;
}

.closeButtonContainer {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.closeButton {
  width: 45px;
  height: 45px;
  padding-top: 4px;
  border: 1px solid;
  border-color: #dae0e5 #cdd0d3 #b7babd;
  box-shadow: 0 2px 4px var(--button-shadow-color);
  border-radius: 50%;

  &:hover {
    border-color: var(--primary-color);
  }
}

.closeButtonTip {
  color: var(--light-text-color);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

@include dark {
  .closeButton {
    border-color: #000;

    &:hover {
      border-color: var(--primary-color);
    }
  }
}

.streamScreenWrapper {
  z-index: var(--focus-mode-visible-z-index);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.streamScreen {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  padding-top: 70px;
  min-height: 100vh;
  padding-bottom: 20vh;

  & > * {
    max-width: 990px;
    width: 100%;
    margin: 0 auto;
  }

  .editorContainer {
    display: flex;
    flex: 1;
  }
}

.noScroll {
  overflow: hidden;
}
