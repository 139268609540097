@import "styles/mixins";

.emoji-mart {
  border: none;
  border-radius: 14px;
  background-color: transparent;
}

.emoji-mart-bar {
  border: none;
}

.emoji-mart-preview {
  display: none;
}

.emoji-mart-category-label {
  * {
    background-color: var(--background-color) !important;
  }
}

@include dark {
  .emoji-mart-category-label {
    * {
      background-color: var(--page-background-color) !important;
    }
  }
}
