@import "styles/mixins";

:root {
  --keyboard-shortcut-color: rgba(var(--thunk-grey-400), 1);
}

@include dark {
  --keyboard-shortcut-color: rgba(var(--thunk-grey-600), 1);
}

.menuItem {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2px 10px 2px 10px;
  background: transparent;
  color: var(--text-color);
  font-weight: 500;
  fill: var(--text-color);
  cursor: pointer;

  // &:last-child {
  //   box-shadow: 0px -2px 0px 0px var(--component-menu-inset-color) inset;
  //   border-bottom-left-radius: 10px;
  //   border-bottom-right-radius: 10px;
  //   padding-bottom: 5px;

  //   &.focused {
  //     box-shadow: 0px -2px 0px 0px var(--component-menu-focused-inset-color) inset;

  //     &:hover {
  //       box-shadow: 0px -2px 0px 0px var(--component-menu-hover-inset-color) inset;
  //     }
  //   }
  // }

  .menuIcon {
    svg {
      margin-top: 2px;
      height: 20px;
      width: 20px;
    }
  }

  .menuItemText {
    display: block;
    font-size: 14px;
    line-height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rightArrow {
    margin-left: auto;
    height: 20px;
    svg {
      height: 20px;
      width: 20px;
    }
  }

  .menuItemKeyboardShortcut {
    fill: var(--keyboard-shortcut-color);
    margin-left: auto;
    padding-bottom: 1px;
    display: flex;
    svg {
      height: 26px;
    }
  }

  &.focused {
    background-color: var(--component-menu-item-focused-background);
    color: var(--component-menu-item-focused-color);
    fill: var(--component-menu-item-focused-color);

    .menuItemKeyboardShortcut {
      fill: rgba(var(--thunk-blue-400), 1);
    }
  }

  &.backButton {
    .menuIcon {
      
      width: 30px;
      // margin-top: 1px;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  &.focusMode {
    border-top: 1px solid var(--card-toolbar-menu-divider-line-color);
  }

  &.delete {
    border-top: 1px solid var(--card-toolbar-menu-divider-line-color);
    padding-top: 3px;
    padding-bottom: 3px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: -5px;

    &.focused,
    &:hover {
      box-shadow: 0px -2px 0px 0px var(--component-menu-delete-inset-color) inset;
      background-color: var(--component-menu-delete-color) !important;
      color: white;
      fill: white;

    }
  }
}

body[input-mode="mouse"] {
  .menuItem {
    &:hover {
      background-color: var(--component-menu-item-hovered-background);

      .menuItemKeyboardShortcut {
        fill: rgba(var(--thunk-grey-500), 1);
      }
    }
  }
}
