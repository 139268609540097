@import "styles/mixins";

.menuTooltip {
  --menu-inset-shadow-height: -2px;

  --menu-bg-clr: var(--thunk-drowdown-menu-bg-color);
  --menu-br-clr: var(--thunk-drowdown-menu-border-color);
  --menu-box-shadow: 0px var(--menu-inset-shadow-height) 0px 0px var(--thunk-dropdown-menu-inset-color) inset,
    0px 2px 4px 0px rgba(27, 38, 51, 0.1);
}

@include dark {
  .menuTooltip {
    --menu-box-shadow: 0px var(--menu-inset-shadow-height) 0px 0px
    var(--thunk-dropdown-menu-inset-color) inset,
      0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  }
}

.menuTooltip {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  background: var(--menu-bg-clr);
  border: 1px solid var(--menu-br-clr);
  border-radius: 10px;
  box-shadow: var(--menu-box-shadow);
  transition: opacity 0.2s, height 0.2s;
  max-width: 45vw;
  min-width: 350px;
  overflow: hidden;
  // padding-bottom: 20px;
}

.visible {
  visibility: visible;
  opacity: 1;
}
