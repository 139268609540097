@import "styles/mixins";

.spinnerContainer {
  width: 100%;
  height: 100vh;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboardingContainerSm {
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
}

.onboardingContainerSplit {
  display: flex;
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
}

.onboardingForm {
  flex: 1;
  margin-right: 20px;
}

.onboardingFormContainer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.onboardingPreview {
  flex: 1;
}

.onboardingHeadlineSm {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  text-align: left;
  margin-bottom: 1rem;
}

.onboardingHeadline {
  font-size: 24px;
  font-weight: bold;
  line-height: 140%;
  text-align: left;
}

.onboardingHeadlineLg {
  font-size: 36px;
  font-weight: bold;
  line-height: 120%;
  margin-bottom: 1.5rem;
  text-align: left;
}

.onboardingCopy {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 1.5rem;
  text-align: left;
}

.onboardingImageContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.linkBtn {
  color: var(--blue-color) !important;
}

.secondaryLink {
  position: fixed;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: right;
  padding-right: 25px;
}

.easterEggLink {
  position: fixed;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: right;
  padding-right: 25px;
}

@include media-xs {
  .secondaryLink {
    position: fixed;
    bottom: 25px;
    left: 0;
    width: 100%;
    text-align: center;
    padding-right: 0px;
  }
}
@include media-sm {
  .onboardingForm {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .onboardingHeadlineLg {
    font-size: 20px;
    margin-bottom: 1rem;
  }
  .onboardingContainerSplit {
    flex-direction: column;
    margin-top: -3.5rem;
    .onboardingImageContainer {
      display: none;
    }
  }
  .easterEggLink {
    position: fixed;
    bottom: 25px;
    left: 0;
    width: 100%;
    text-align: center;
    padding-right: 0px;
  }
}
