.selectionToolbar {
  visibility: hidden;
  position: absolute;
  margin-top: -4px;
  transform: translate3d(0, 10px, 0);
  opacity: 0.6;
  transition: top 75ms ease-out 0s, left 75ms ease-out 0s, transform 125ms,
    opacity 125ms, width 125ms, background-color 125ms;
  z-index: var(--formatting-menu-z-index);

  &:not(.visible) {
    height: 0;
    overflow: hidden;
  }

  &.visible {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &.templates {
    background-color: var(--pink-color);
  }
}
