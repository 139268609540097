@import "styles/mixins";

.menuTooltip {
  --at-menu-background-color: #ffffff;
  --at-menu-inset-color: rgba(var(--thunk-grey-200), 1);
  --at-menu-inset-focused-color: #D9F2FA;
  --at-menu-shadow-color: rgba(0,0,0, 0.1);
  --at-menu-border-color: rgba(0,0,0, 0.1);
  --at-menu-create-bg-color: rgba(var(--thunk-grey-100), 1);
}

@include dark {
  .menuTooltip {
    --at-menu-background-color: rgba(var(--thunk-grey-900), 1);
    --at-menu-inset-focused-color: rgba(var(--thunk-blue-200-dark), 1);
    --at-menu-shadow-color: rgba(13, 15, 20, 0.5);
    --at-menu-inset-color: rgba(var(--thunk-grey-900), 1);
    --at-menu-border-color: black;
    --at-menu-create-bg-color: rgba(var(--thunk-grey-800), 1);
  }
}

.createNoteHeader {
  background-color: var(--at-menu-create-bg-color);
}

.menuTooltip {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: var(--range-popper-z-index);
  opacity: 0;
  visibility: hidden;
  background: var(--at-menu-background-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--at-menu-shadow-color), 0px -2px 0px 0px var(--at-menu-inset-color) inset, 0px 0px 0px 1px var(--at-menu-border-color);
  transition: opacity 0.2s, height 0.2s;
  max-width: 45vw;
  min-width: 350px;
  overflow: hidden;
  padding-bottom: 20px;
}

.visible {
  visibility: visible;
  opacity: 1;
}
