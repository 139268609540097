@import "styles/mixins";

:root {
  --tasks-section-background-color: rgba(var(--thunk-grey-200), 1);
  --tasks-collapse-arrow-background-color: rgba(var(--thunk-grey-200), 1);
  --tasks-collapse-arrow-background-hover-color: rgba(var(--thunk-grey-300), 1);
  --todo-section-title-background-collapse-hover-color: rgba(var(--thunk-grey-300), 1);
  --todo-section-title-background-hover-color: rgba(var(--thunk-purple-200), 1);
  --tasks-section-border-radius: 10px;
}


@include dark {
  --tasks-collapse-arrow-background-color: rgba(var(--thunk-grey-900), 1);
  --tasks-collapse-arrow-background-hover-color: rgba(var(--thunk-grey-800), 1);
  --tasks-section-background-color: rgba(var(--thunk-grey-900), 1);
  --todo-section-title-background-collapse-hover-color: rgba(var(--thunk-grey-800), 1);
  --todo-section-title-background-hover-color: rgba(var(--thunk-purple-200-dark), 1);
}

.sidePanelCard {
  background-color: var(--background-color);
  border-radius: 30px;
  margin-top: 10px;
  border: 1px solid var(--card-border-color);

  @include media-sm {
    border-radius: 0;
  }
}

.tasksContainer {
  margin-top: 0.5rem;
  margin-bottom: 10px;
  border-radius: var(--tasks-section-border-radius);
}

.taskSection {
  height: 40px;
  display: flex;
}

.editableToDos {
  margin-top: 10px;
}

// .tasksContainer {  
// }

// .tasksContainer:hover {
//   background-color: var(--tasks-section-background-color);
// }    
   

.taskItem {
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  padding: var(--card-padding-horizontal);
  padding-bottom: 16px;
}

.checkboxPointer {
  float: left; // make it out of elements flow to prevent text cursor position before pointer

  margin-top: 7px;
  margin-right: 6px;
}

.sectionTitle {
  pointer-events: auto;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  margin-bottom: 0.4rem;
  color: var(--backlink-color);
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  padding-left: 10px;
  background-color: var(--tasks-section-background-color);
  border-top-right-radius: var(--tasks-section-border-radius);
  border-bottom-right-radius: var(--tasks-section-border-radius);

  &:hover {
    text-decoration: none;
    background-color: white;
    background-color: var(--todo-section-title-background-hover-color);
  }
  &:focus {
    text-decoration: none;
  }
}

.titleText {
  max-width: calc(100% - 56px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.completedText {
  font-size: 14px;
  font-weight: 700;
  user-select: none;
  height: 40px;
  line-height: 40px;
  margin-left: auto;
  margin-right: 15px;
  white-space: nowrap;
  order: 2;
}

.taskCollapseArrow {
  pointer-events: auto;
  padding-left: calc(var(--card-pl) - 30px);
  width: calc(var(--card-pl) + 0px);
  height: 40px;
  border-top-left-radius: var(--tasks-section-border-radius);
  border-bottom-left-radius: var(--tasks-section-border-radius);
  background-color: var(--tasks-collapse-arrow-background-color);

  &:hover {
    background-color: var(--tasks-collapse-arrow-background-hover-color);
  }
}

[data-card-lg] {
  .taskCollapseArrow {
    border-radius: 0;
  }

  .sectionTitle{
    border-radius: 0;
  }
}

[data-card-xs] {
  .taskCollapseArrow {
    padding-left: 18px;
    width: 48px;
  }
}