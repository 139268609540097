.button {
  height: 32px;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: 500;
  line-height: inherit;

  .labelAndArrow {
    display: flex;
  }

  &:hover{
    color: var(--handle-icon-color);
    .label {
      color: var(--handle-icon-color);
    }
  }
}

.arrow {
  margin-top: 0px;
  margin-left: 3px;
}

.arrowIcon {
  margin-left: 5px;
  transition: 100ms transform;

  &.opened {
    transform: rotate(90deg);
  }
}

.label {
  color: var(--text-color);
}

.reverse {
  flex-direction: row-reverse;

  .label {
    margin-left: 10px;
  }
}