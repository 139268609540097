@import "../../../../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;

  .placeholder {
    width: 324px;
    height: 52px;
    padding-top: 14px;
    text-align: center;
  }
}
