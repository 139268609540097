@import "styles/mixins";

.cardToolbarButton {
  cursor: pointer;
  outline: none;
  border-radius: 40px;
  background-color: "";
  padding: 0;
  height: 28px;
  width: 28px;

  &:hover {
    background-color: "";
  }

  svg {
    margin-top: 0px;
  }
}

@include dark {
  .cardToolbarButton {
    background-color: "";
  }
}

.default {
  color: var(--primary-color);
  background-color: transparent;

  &:disabled {
    color: var(--button-disabled-color);
  }
}

.filled {
  background: var(--primary-color);
  color: var(--button-color);

  &:disabled {
    color: var(--button-disabled-color);
    background: var(--button-disabled-background-color);
    border: 1px solid transparent;
  }
}
