.favoritesItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px 0 38px;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 400;
  line-height: 24px;

  .itemTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  .removeButton {
    width: 18px;
    height: 18px;
  }

  .removeIcon {
    display: none;
    color: var(--light-text-color);
    opacity: 0.3;
  }

  &.active {
    color: var(--primary-color);
    //background-color: var(--page-background-color);
  }

  &:hover {
    color: var(--primary-color);

    .removeIcon {
      display: block;
    }
  }
}
