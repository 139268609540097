.hr {
  --hr-background-color: #e0e5eb;
}

.container {
  user-select: none;
}

.hr {
  cursor: pointer;
  background-clip: content-box;
  border-style: none;
  height: 2px;
  border-radius: 1px;
  background-color: var(--hr-background-color);
  margin: 0;
  padding: 12px 0;
  user-select: none;

  &.highlighted {
    background-color: var(--primary-color);
  }
}
