@import "styles/mixins";

.container {
  position: absolute;
  left: calc(50% - 250px - 30px);
  top: 2px;
  z-index: 2;
  border-radius: 11px;
  height: 42px;
  border: 1px solid rgba(var(--purple-color-rgb), 0.3);
  background-color: var(--background-color);
  box-shadow: 0 3px 6px rgba(var(--purple-color-rgb), 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 10px 15px 10px 10px;
}

@include dark {
  .container {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid black;
  }
}

.pointer {
  height: 3px;
  bottom: 0;
  background-color: var(--purple-color);
}

@include media-sm {
  .container {
    top: 16px;
    right: 12px;
    left: 90px;
    justify-content: space-between;
  }
}

@include media-xxs {
  .container {
    top: 16px;
    right: 12px;
    left: 50px;
  }
}
