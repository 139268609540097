@import "styles/mixins";



.filterNotifications {
  --filter-notification-color: #e9edf2;
}

@include dark {

  .filterNotifications {
    --filter-notification-color: #28384c;
  }

  .title {
  
    a {
      color: rgba(var(--thunk-purple-700-dark), 1);
    }
  }
}

.title {
  width: 100%;
  padding-left: calc(var(--spacing, 0px));

  a {
    color: rgba(var(--thunk-purple-700), 1);
    font-size: 16px;
    font-weight: 400;
  }
}

.date {
  font-size: 14px;
  font-weight: 400;
  min-width: 170px;
}

.tags {
  min-width: 230px;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.tagsContainerMobile {
  display: none;
  flex-wrap: wrap;
  float: right;
}

.tagFiltersSection{
  padding: 15px;
}

.tagFilterItem {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 6px;

  > input {
    margin-top: -3px;
  }

  > * + * {
    margin-left: 13px;
  }
}

.filterNotifications {
  display: flex;
  align-items: center;
}

.filterNotification {
  background-color: var(--filter-notification-color);
  border-radius: 6px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;

  > * {
    white-space: pre;
  }

  & + & {
    margin-left: 10px;
  }

  > * + * {
    margin-left: 5px;
  }

  > button {
    margin-top: 3px;
  }
}

.filterNotificationContent {
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.statement {
  color: rgba(var(--thunk-text), 0.5);
}

@include media-md {
  .filterNotifications {
    display: none;
  }

  .tagsContainerMobile {
    display: inline-flex;
  }

  .date,
  .tags {
    display: none;
  }
}
