@import "styles/mixins";

.filterContainer {
  --filter-bg-clr: #ffffff;
  --filter-br-clr: rgba(var(--thunk-grey-300), 1);
}

@include dark {
  .filterContainer {
    --filter-bg-clr: #1b2633;
    --filter-br-clr: black;
  }
}

.filterContainer {
  min-width: 230px;
  min-height: 300px;
}

.header {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
}

.title {
  font-weight: 500;
  font-size: 14px;
}

.divider {
  height: 1px;
  background-color: var(--t-filter-color);
  border: none;
  margin: 0;
}

.filterSection {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  padding: 15px 15px 0px;

  input {
    // border-radius: 0;
    // background: none;
    // border: 1px solid var(--t-filter-color);
    font-size: 14px;
  }

  &:hover {
    text-decoration: none;
  }

  svg {
    margin-right: 10px;
  }
}
