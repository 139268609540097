.container {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: var(--mobile-graph-editor-z-index);
  background-color: var(--background-color);
  padding: 10px;

  transform: translate3d(0, 0, 0);
  will-change: transform;
  transition-property: transform;
  transition-duration: 200ms;
}

.visible {
  transform: translate3d(0, -150px, 0);
}

.card {
  min-height: 300px;
}
