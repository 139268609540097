@import "styles/mixins";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.centered {
    flex: 1;
    width: 100%;
    min-height: 100%;
    justify-content: center;
    padding: 20px 30px 50px;
  }
}

.title {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 10px;
  max-width: 500px;
}

.text {
  max-width: 470px;
}

.imageContainer {
  margin-bottom: 20px;
}

.contacts {
  font-size: 13px;
  font-style: italic;
}

.actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}

[data-card-type="side-panel-card"],
[data-card-type="graph-card"] {
  .imageContainer {
    svg {
      width: 150px;
      height: 110px;
    }
  }
}

@include media-sm {
  .title {
    font-size: 24px;
  }
}
