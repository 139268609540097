@import "styles/mixins";

:root {
  --blue-color: #0177ff;
  --light-blue-color: #cce1ff;
  --green-color: #00cc88;
  --red-color: #ff5555;
  --purple-color: #8954ff;
  --purple-color-rgb: 137, 84, 255;
  --pink-color: #ff5599;
  --yellow-color: #ffbb00;
  --aqua-color: #22dddd;
  --bright-green-color: #80ffd4;
  --baby-blue-color: #08baff;

  --green-text-color: #00b277;
  --red-text-color: #ff0000;
  --purple-text-color: #4f00ff;
  --pink-text-color: #ff0066;
  --orange-text-color: #ff8000;
  --aqua-text-color: #1dbaba;
  --light-blue-text-color: #00a9eb;
  --blue-text-color: #0177ff;
  --light-grey-color: #5480b2;
  --light-grey-color-rgb: 102, 126, 153;
  --light-black-color: #1b2633;

  --primary-color: var(--blue-color);
  --primary-color-dark: #015fcc;
  --error-color: var(--red-color);
  --primary-color-rgb: 1, 119, 255;
  --success-color: var(--green-color);
  --validation-color: var(--blue-color);

  --text-color: #1b2633;
  --inverted-text-color: #c2cbd6;
  --text-color-rgb: 27, 38, 51;
  --light-text-color: #667e99;
  --placeholder-text-color: rgba(var(--thunk-grey-500), 1);

  --backlink-color: rgba(var(--thunk-purple-600), 1);

  --hover-button-group-background-color: #3d4b5c;
  --active-link-color: var(--primary-color);

  --card-box-shadow: rgba(0, 0, 0, 0.05);

  --input-border-color: rgba(var(--thunk-grey-300), 1);
  --input-background-color: rgba(var(--thunk-grey-200), 1);
  --input-focus-background-color: rgba(var(--thunk-blue-200), 1);
  --input-focus-border-color: rgba(var(--thunk-blue-300), 1);

  --writing-status-circle-background-color: var(--light-gray-background-color);
  --progressbar-background-color: var(--light-gray-background-color);

  --steps-progress-circle-background-color: var(--light-gray-background-color);

  --button-color: #fff;
  --button-disabled-color: #fff;
  --button-disabled-background-color: #e0e5eb;
  --button-disabled-border: 1px solid var(--button-disabled-color);

  --light-gray-color: #dadada;
  --light-gray-background-color: #f0f2f5;

  --thunk-select-color: rgba(var(--thunk-light-blue-200), 1);
  --thunk-drowdown-menu-bg-color: white;
  --thunk-dropdown-menu-inset-color: rgba(var(--thunk-grey-200), 1);
  --thunk-drowdown-menu-border-color: rgba(0,0,0,0.15);
  --thunk-dropdown-menu-item-hover-color: rgba(var(--thunk-grey-200), 1);
  --thunk-dropdown-menu-divider-line-color: rgba(var(--thunk-grey-300), 1);
  --thunk-inset-shadow-color: rgba(var(--thunk-grey-200), 1);

  --background-color: #fff;
  --inverted-background-color: #17202b;
  --background-color-rgb: 255, 255, 255;
  --page-background-color: #F2F3F5;
  --card-border-color: rgba(var(--thunk-grey-300), 1);

  --references-background-color: rgba(var(--thunk-grey-100), 1);
  --references-collapse-button-bg-color: rgba(var(--thunk-grey-200), 1);
  --references-collapse-button-bg-color-hover: rgba(var(--thunk-grey-300), 1);
  --page-background-color-rgb: 242, 243, 245;
  --modal-overlay-background-color: #050c14cc;
  --overlay-gradient: linear-gradient(
    rgba(var(--background-color-rgb), 1) 0%,
    rgba(var(--background-color-rgb), 1) calc(100% - 56px),
    rgba(var(--background-color-rgb), 0.3) calc(100% - 0px),
    rgba(var(--background-color-rgb), 0) 100%
  );

  --button-background-color-top: var(--background-color);
  --button-background-color-bottom: #fafbfc;
  --button-background-color: white;
  --button-border-color-top: #dae0e5;
  --button-border-color-leftright: #cdd0d3;
  --button-border-color-bottom: #b7babd;
  --button-border-color-hover-top: #015ac0;
  --button-border-color-hover-leftright: #014ca4;
  --button-border-color-hover-bottom: #012653;
  --button-shadow-color: rgba(27, 38, 51, 0.07);
  --button-inner-shadow-color: rgba(0,0,0, 0.1);

  --alert-background-color: #1b2633;
  --alert-gift-background-color: #fff0f5;
  --alert-gift-color: #ff0569;

  --note-text-area-background-color: #fff;

  --select-menu-border-color: #dae0e5;
  --select-menu-background-color: var(--background-color);
  --select-menu-shadow: drop-shadow(0px 8px 16px rgba(27, 38, 51, 0.1));
  --select-menu-border-radius: 16px;
  --select-menu-item-hovered-background-color: #d8f2ff;
  --select-menu-item-hovered-border-color: #97e1ff;

  --link-select-menu-background-color: #ffffff;
  --link-select-menu-even-item-color: #f8f5ff;
  --link-select-menu-border: #dbccff;
  --link-select-menu-shadow-color: rgba(110, 67, 204, 0.16);
  --link-select-menu-item-color: var(--purple-color);
  --link-select-menu-item-focused-color: #fff;
  --link-select-menu-item-focused-background: var(--purple-color);
  --link-select-menu-item-hover-background: #ede5ff;

  --time-select-menu-background-color: #ffffff;
  --time-select-menu-border-color: var(--select-menu-border-color);
  --time-select-menu-shadow-color: rgba(102, 126, 153, 0.16);
  --time-select-menu-item-color: #3c5b80;
  --time-select-menu-item-color-hint: #1b2633;
  --time-select-menu-item-focused-color: var(--slate-code-color);
  --time-select-menu-item-focused-color-hint: var(--text-color);
  --time-select-menu-item-focused-background: var(
    --slate-code-background-color
  );
  --time-select-menu-item-hover-background: var(
    --select-menu-item-hovered-background-color
  );

  --template-select-menu-background-color: #ffffff;
  --template-select-menu-border-color: #ffd7e7;
  --template-select-menu-shadow-color: rgba(153, 31, 80, 0.16);
  --template-select-menu-item-color: var(--pink-color);
  --template-select-menu-item-focused-color: #ffffff;
  --template-select-menu-item-focused-background: var(--pink-color);
  --template-select-menu-item-hover-background: #fff0f5;

  --card-toolbar-menu-background-color: #ffffff;
  --card-toolbar-menu-border-color: rgba(0,0,0, 0.1);
  --card-toolbar-menu-divider-line-color: rgba(var(--thunk-grey-300), 1);
  --card-toolbar-menu-shadow-color: rgba(102, 126, 153, 0.16);
  --card-toolbar-menu-inset-color: rgba(var(--thunk-grey-200), 1);
  --card-toolbar-menu-item-hover-background: rgba(var(--thunk-grey-200), 1);
  --card-toolbar-menu-delete-color: rgba(var(--thunk-red-500), 1);
  --card-toolbar-menu-delete-inset-color: rgba(var(--thunk-red-600), 1);

  --recent-menu-shadow-color: rgba(102, 126, 153, 0.16);
  --recent-menu-border-color: rgba(102, 126, 153, 0.3);

  --component-menu-background: #fff;
  --component-menu-border-color: rgba(0,0,0, 0.1);
  --component-menu-item-color: #000;
  --component-menu-item-focused-color: var(--text-color);
  --component-menu-inset-color: rgba(var(--thunk-grey-200), 1);
  --component-menu-item-focused-background: rgba(var(--thunk-light-blue-200), 1);
  --component-menu-item-hovered-color: #fff;
  --component-menu-item-hovered-background: var(--thunk-dropdown-menu-item-hover-color);
  --component-menu-shadow-color: rgba(102, 126, 153, 0.16);
  --component-menu-search-color: var(--text-color);
  --component-menu-delete-color: rgba(var(--thunk-red-500), 1);
  --component-menu-delete-inset-color: rgba(var(--thunk-red-600), 1);
  --component-menu-search-placeholder-color: rgba(var(--thunk-grey-400), 1);
  --component-menu-focused-inset-color:rgba(var(--thunk-blue-200), 1);
  --component-menu-hover-inset-color:rgba(var(--thunk-grey-300), 1);

  --twitter-mention-menu-background: #fff;
  --twitter-mention-menu-border: #d1d8e0;
  --twitter-mention-menu-item-color: #000;
  --twitter-mention-menu-item-focused-color: #fff;
  --twitter-mention-menu-item-focused-background: var(--blue-color);
  --twitter-mention-menu-item-hovered-color: #000;
  --twitter-mention-menu-item-hovered-background: #e7f0fe;
  --twitter-mention-menu-shadow-color: rgba(102, 126, 153, 0.16);
  --twitter-mention-menu-search-color: var(--text-color);

  --formatting-menu-background-color: var(--text-color);
  --formatting-menu-icon-color: #d1d8e0;
  --formatting-menu-icon-color-active: rgba(var(--thunk-blue-500), 1);

  --link-menu-input-color: #ffffff;
  --link-menu-close-icon-background-color: #000;
  --link-menu-close-icon-color: #ffffff;

  --search-menu-text: #1b2633;
  --search-menu-background: #e5f8ff;
  --search-menu-border-color: #d1d8e0;
  --search-menu-background-color: #ffffff;
  --search-menu-item-color: #ffffff;
  --search-menu-even-item-color: #f7f9fa;
  --search-menu-item-border-color: #b3def0;
  --search-menu-item-focused-background: var(--blue-color);
  --search-menu-mark-text-color: var(--text-color);
  --search-menu-mark-bg-color: rgba(var(--thunk-yellow-300), 1);
  --search-menu-item-hover-background: #e5f0ff;
  --search-menu-shadow-color: rgba(102, 126, 153, 0.16);

  --page-reference-section-background: #f7fafc;
  --reference-border-color: #dae0e8;

  --stream-component-background: #f7fafc;
  --stream-component-delete-color: #cccccc;
  --stream-component-delete-color-hover: #9e9e9e;

  --calendar-background-color: var(--primary-color);
  --calendar-text-color: white;
  --calendar-now-tile-background-color: #4da0ff;
  --calendar-now-tile-color: white;
  --calendar-weekend-day-color: #80baff;
  --calendar-hover-tile-background-color: #0053b2;
  --calendar-hover-tile-color: white;
  --calendar-selected-tile-background-color: white;
  --calendar-selected-tile-color: var(--calendar-background-color);

  --sidebar-user-color: var(--light-text-color);

  --notification-bakcground-color: #1b2633;
  --notification-text-color: #ffffff;
  --notification-center-background-color: #3d4b5c;

  --slate-keycap-background-gradient-start: rgb(255, 255, 255);
  --slate-keycap-background-gradient-end: rgb(230, 232, 235);
  --slate-keycap-border-color: #bbbec2;
  --slate-keycap-shadow-color: rgba(102, 126, 153, 0.16);

  --slate-code-color: rgba(var(--thunk-blue-700), 1);
  --slate-code-background-color: rgba(var(--thunk-blue-200), 1);
  --slate-code-border-color: rgba(var(--thunk-blue-300), 1);

  --slate-codeblock-color: rgba(var(--thunk-blue-400), 1);
  --slate-codeblock-text-color: rgba(var(--thunk-blue-400), 1);
  --slate-codeblock-background-color: rgba(var(--thunk-blue-200-dark), 1);

  --help-button-border-color: rgba(84, 128, 179, 0.2);
  --help-button-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  --help-drawer-shadow: 0px 6px 22px rgba(61, 75, 92, 0.16);

  --auth-header-height: 90px;
  --app-header-height: 60px;

  --template-card-border-color: #e7eaef;

  // z-indexes
  --list-line-z-index: 5;
  --side-panel-container: 10;
  --sidebar-z-index: 10;
  --card-title-image-z-index: 11;
  --side-panel-tabs-container-z-index: 13;
  --header-z-index: 49;
  --help-floating-button-z-index: 51;
  --mobile-graph-editor-z-index: 80;
  --menu-popper-z-index: 200;
  --electron-header-z-index: 800;
  --expired-notification-z-index: 801;
  --electron-search-z-index: 802;
  --calendar-z-index: 803;
  --help-drawer-z-index: 804;
  --focus-mode-overlay-z-index: 900;
  --focus-mode-visible-z-index: 901;
  --shortcuts-hint: 902;
  --formatting-menu-z-index: 950;
  --modal-z-index: 1000;
  --range-popper-z-index: 1001;
  --hover-popper-z-index: 1001;
  --recent-menu-z-index: 1002;

  --scrollbar-bg: var(--background-color);
  --scrollbar-thumb-bg: rgba(0, 0, 0, 0.4);
}

body {
  --expired-notification-height: 0px;
  --electron-header-height: 0px;

  --app-global-padding-top: calc(
    var(--expired-notification-height) + var(--electron-header-height)
  );
}

body[data-has-expired-notification] {
  --expired-notification-height: 40px;
}

body[data-electron-layout] {
  --electron-header-height: 60px;
}

@include media-xs {
  body[data-has-expired-notification] {
    --expired-notification-height: 60px;
  }
}

@include dark {
  color-scheme: dark;

  --text-color: rgba(var(--thunk-grey-500), 1);
  --inverted-text-color: #1b2633;
  --placeholder-text-color: rgba(255, 255, 255, 0.2);
  --primary-color: rgba(var(--thunk-blue-700-dark), 1);

  --backlink-color: rgba(var(--thunk-purple-500), 1);

  --text-color-rgb: 136, 142, 151;
  --background-color: rgba(var(--thunk-grey-1000), 1);
  --inverted-background-color: #fff;
  --background-color-rgb: 27, 38, 51;
  --page-background-color: black;
  --card-border-color: rgba(var(--thunk-grey-900), 1);

  --references-background-color: rgba(var(--thunk-grey-900), 1);
  --references-collapse-button-bg-color: rgba(var(--thunk-grey-800), 1);
  --references-collapse-button-bg-color-hover: rgba(var(--thunk-grey-900), 1);
  --page-background-color-rgb: 0, 0, 0;
  --input-border-color: rgba(var(--thunk-grey-800), 1);
  --input-background-color: rgba(var(--thunk-grey-900), 1);
  --input-focus-background-color: rgba(var(--thunk-blue-200-dark), 1);
  --input-focus-border-color: rgba(var(--thunk-blue-300-dark), 1);

  --thunk-select-color: rgba(var(--thunk-blue-200-dark), 1);
  --thunk-drowdown-menu-bg-color: rgba(var(--thunk-grey-800), 1);
  --thunk-dropdown-menu-inset-color: rgba(var(--thunk-grey-900), 1);
  --thunk-dropdown-menu-item-hover-color: rgba(var(--thunk-grey-900), 1);
  --thunk-drowdown-menu-border-color: black;
  --thunk-dropdown-menu-divider-line-color: black;
  --thunk-inset-shadow-color: rgba(var(--thunk-grey-900), 1);

  --writing-status-circle-background-color: rgba(
    var(--light-grey-color-rgb),
    0.1
  );
  --progressbar-background-color: rgba(var(--light-grey-color-rgb), 0.1);

  --steps-progress-circle-background-color: rgba(
    var(--light-grey-color-rgb),
    0.1
  );

  --button-disabled-background-color: rgba(var(--light-grey-color-rgb), 0.2);
  --button-disabled-color: rgba(255, 255, 255, 0.2);
  --button-disabled-border: 1px solid var(--button-disabled-background-color);
  --button-border-color-top: #000;
  --button-border-color-leftright: #000;
  --button-border-color-bottom: #000;

  --light-gray-background-color: var(--text-color);
  --active-link-color: var(--primary-color);
  --overlay-gradient: linear-gradient(
    rgba(var(--background-color-rgb), 1) 0%,
    rgba(var(--background-color-rgb), 1) calc(100% - 56px),
    rgba(var(--background-color-rgb), 0) calc(100% - 0px),
    rgba(var(--background-color-rgb), 0) 100%
  );

  --note-text-area-background-color: #1b2633;

  --button-background-color-top: var(--background-color);
  --button-background-color-bottom: #121921;
  --button-background-color: #0e131a;
  --button-border-color-top: #000;
  --button-border-color-leftright: #000;
  --button-border-color-bottom: #000;
  --button-border-color-hover-top: #001226;
  --button-border-color-hover-leftright: #001226;
  --button-border-color-hover-bottom: #000;
  --button-shadow-color: rgba(0, 0, 0, 0.2);
  --button-inner-shadow-color: #000;

  --alert-background-color: #667e99;
  --alert-gift-background-color: #2e2536;
  --alert-gift-color: #ff5599;

  --select-menu-border-color: #28384c;
  --select-menu-background-color: var(--page-background-color);
  --select-menu-item-hovered-background-color: #000;
  --select-menu-item-hovered-border-color: #000;
  --select-menu-shadow: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.2));

  --link-select-menu-background-color: #1b2633;
  --link-select-menu-even-item-color: #21283d;
  --link-select-menu-border: var(--select-menu-border-color);
  --link-select-menu-shadow-color: rgba(13, 15, 20, 0.5);
  --link-select-menu-item-hover-background: #10171f;

  --time-select-menu-background-color: #1b2633;
  --time-select-menu-border-color: var(--select-menu-border-color);
  --time-select-menu-shadow-color: rgba(13, 15, 20, 0.5);
  --time-select-menu-item-hover-background: #10171f;
  --time-select-menu-item-color-hint: #e5f0ff;

  --template-select-menu-background-color: #1b2633;
  --template-select-menu-border-color: var(--select-menu-border-color);
  --template-select-menu-shadow-color: rgba(13, 15, 20, 0.5);
  --template-select-menu-item-hover-background: #10171f;

  --card-toolbar-menu-background-color: var(--thunk-drowdown-menu-bg-color);
  --card-toolbar-menu-divider-line-color: rgba(var(--thunk-grey-1000), 1);
  --card-toolbar-menu-shadow-color: rgba(0,0,0, 0.5);
  --card-toolbar-menu-inset-color: rgba(var(--thunk-grey-900), 1);
  --card-toolbar-menu-item-hover-background: var(--thunk-dropdown-menu-item-hover-color);
  --card-toolbar-menu-delete-color: rgba(var(--thunk-red-600-dark), 1);
  --card-toolbar-menu-delete-inset-color: rgba(var(--thunk-red-500-dark), 1);

  --recent-menu-shadow-color: rgba(13, 15, 20, 0.5);

  --page-reference-section-background: #242f3d;
  --reference-border-color: #5d6064;

  --stream-component-background: #2a333f;

  --component-menu-background: rgba(var(--thunk-grey-900), 1);
  --component-menu-border-color: black;
  --component-menu-item-color: #fff;
  --component-menu-item-focused-color: rgba(var(--thunk-blue-300), 1);
  --component-menu-inset-color: rgba(var(--thunk-grey-900), 1);
  --component-menu-item-focused-background: rgba(var(--thunk-blue-300-dark), 1);
  --component-menu-shadow-color: rgba(13, 15, 20, 0.5);
  --component-menu-delete-color: rgba(var(--thunk-red-600-dark), 1);
  --component-menu-delete-inset-color: rgba(var(--thunk-red-500-dark), 1);
  --component-menu-search-placeholder-color: rgba(var(--thunk-grey-600), 1);
  --component-menu-focused-inset-color:rgba(var(--thunk-blue-200-dark), 1);
  --component-menu-hover-inset-color:rgba(var(--thunk-grey-900), 1);

  --twitter-mention-menu-background: var(--background-color);
  --twitter-mention-menu-border: rgba(102, 126, 153, 0.3);
  --twitter-mention-menu-item-color: #fff;
  --twitter-mention-menu-shadow-color: rgba(13, 15, 20, 0.5);
  --twitter-mention-menu-item-hovered-color: white;
  --twitter-mention-menu-item-hovered-background: #161e29;

  --search-menu-text: var(--text-color);
  --search-menu-background: #e5f8ff;
  --search-menu-border-color: #10161e;
  --search-menu-background-color: #1b2633;
  --search-menu-item-color: #1b2633;
  --search-menu-even-item-color: #17202b;
  --search-menu-item-focused-background: var(--blue-color);
  --search-menu-item-hover-background: #10171f;
  --search-menu-mark-text-color: rgba(var(--thunk-yellow-500), 1);
  --search-menu-mark-bg-color: rgba(var(--thunk-yellow-300-dark), 1);
  --search-menu-shadow-color: rgba(13, 15, 20, 0.5);

  --formatting-menu-background-color: rgba(var(--thunk-grey-300), 1);
  --formatting-menu-icon-color: rgba(var(--thunk-grey-800), 1);
  --formatting-menu-icon-color-active: rgba(var(--thunk-blue-600), 1);

  --link-menu-input-color: #1b2633;
  --link-menu-close-icon-background-color: #edf2f7;
  --link-menu-close-icon-color: #1b2633;

  --calendar-now-tile-background-color: rgba(var(--primary-color-rgb), 0.3);
  --calendar-now-tile-color: #66adff;

  --sidebar-user-color: var(--light-text-color);

  --notification-bakcground-color: #ffffff;
  --notification-text-color: #1b2633;
  --notification-center-background-color: #ffffff;

  --slate-keycap-background-gradient-start: #1b2633;
  --slate-keycap-background-gradient-end: #10161f;
  --slate-keycap-border-color: #29394d;
  --slate-keycap-shadow-color: rgba(11, 15, 20, 0.5);

  --slate-code-color: rgba(var(--thunk-blue-400), 1);
  --slate-code-background-color: rgba(var(--thunk-blue-200-dark), 1);

  --slate-code-border-color: rgba(var(--thunk-blue-300-dark), 1);

  --help-button-border-color: #10161e;
  --help-button-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);
  --help-drawer-shadow: 0px 6px 22px rgba(0, 0, 0, 0.16);

  --template-card-border-color: #081113;
}
