@import "styles/mixins";

.container {
  width: 100%;
  margin-top: 200px;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

@include media-sm {
  .container {
    margin-top: 0px;
  }
}

.success {
  display: flex;
  flex-direction: column;
}
