@import "styles/mixins";

:root {
  --at-menu-item-focused-background: rgba(var(--thunk-light-blue-200), 1);
  --at-menu-item-focused-color: rgba(var(--thunk-purple-600), 1);
  --at-menu-item-hovered-background: rgba(var(--thunk-grey-200), 1);
  --at-menu-item-even-background-color: rgba(var(--thunk-purple-200), 0);
  --at-menu-item-color: rgba(var(--thunk-purple-500), 1);
  --at-menu-item-hovered-color: rgba(var(--thunk-purple-600), 1);
  --at-menu-item-highlight-color: rgba(var(--thunk-yellow-300), 1);
  --at-menu-item-highlight-text-color: black;
  --at-menu-create-text-color: var(--text-color);
}

@include dark {
    --at-menu-item-highlight-color: rgba(var(--thunk-yellow-300-dark), 1);
    --at-menu-item-highlight-text-color: rgba(var(--thunk-yellow-500), 1);
    --at-menu-item-hovered-background: rgba(var(--thunk-grey-900), 1);
    --at-menu-item-color: rgba(var(--thunk-purple-800-dark), 1);
    --at-menu-item-focused-color: rgba(var(--thunk-purple-1000-dark), 1);
    --at-menu-item-focused-background: rgba(var(--thunk-blue-300-dark), 1);
}

.createText {
  font-size: 14px;
  color: var(--at-menu-create-text-color);
}

.itemIcon {
  color: rgba(var(--thunk-grey-800), 1) !important;
  fill: rgba(var(--thunk-grey-800), 1) !important;
}

.menuItem {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2px 12px 2px 12px;
  background: transparent;
  color: var(--at-menu-item-color);
  font-weight: 500;
  fill: rgba(var(--thunk-grey-800), 1);
  min-height: 29px;
  cursor: pointer;

  .text {
    font-size: 14px;
    line-height: 26px;
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .timestamp {
    background-color: var(--slate-code-background-color);
    border-radius: 6px;
    color: var(--slate-code-color);
    padding: 0px 8px;
    border: 1px solid var(--slate-code-border-color);
    font-size: 13px;
    font-weight: 700;
    height: 22px;
    line-height: 22px;
    white-space: pre-wrap;
    font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
      monospace;
  }

  .hint {
    margin-left: auto;
    color: rgba(var(--thunk-grey-500), 1);
    font-size: 14px;
    max-width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  &.vertical .hint {
    font-size: 12px;
    margin-left: 0;
    max-width: 100%;
    margin-top: 5px;
    margin-bottom: 2px;
  }

  &.vertical .textResult {
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  &.createNoteItem {
    background-color: var(--at-menu-create-bg-color);
  }

  &.focused {
    background-color: var(--at-menu-item-focused-background);
    color: var(--at-menu-item-focused-color);
    fill: var(--at-menu-item-focused-color);
    stroke: var(--at-menu-item-focused-color);

    &:last-child {
      padding-bottom: 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0px -2px 0px 0px var(--at-menu-inset-focused-color) inset;
    }
  }

  &:last-child {
    padding-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px -2px 0px 0px var(--at-menu-inset-color) inset;
  }

  svg {
    min-width: 19px;
    margin-top: -2px;
    margin-right: 4px;
  }
}

body[input-mode="mouse"] {
  .menuItem {
    &:hover {
      background-color: var(--at-menu-item-hovered-background);
      color: var(--at-menu-item-hovered-color);
      fill: var(--at-menu-item-hovered-color);
      stroke: var(--at-menu-item-hovered-color);
    }
  }
}

.mark {
  background-color: var(--at-menu-item-highlight-color);
  color: var(--at-menu-item-highlight-text-color);
  border-radius: 4px;
  padding: 4px 1px;
}
