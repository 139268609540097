@import "styles/mixins";

:root {
  --vertical-rule-color: rgba(255,255,255,0.15);
  --terminal-mode-hint-color: rgba(var(--thunk-grey-400), 1);
  --terminal-mode-hint-color-hover: white;
  --terminal-mode-clickable-hint-background-color: rgba(var(--thunk-grey-700), 1);
  --terminal-mode-clickable-hint-border-color: rgba(255,255,255, 0.2);
  --terminal-mode-clickable-hint-border-color-hover: rgba(255,255,255, 0.3);
}

@include dark {
  --vertical-rule-color: rgba(0,0,0,0.15);
  --terminal-mode-hint-color: rgba(var(--thunk-grey-800), 1);
  --terminal-mode-hint-color-hover: black;
  --terminal-mode-clickable-hint-background-color: rgba(var(--thunk-grey-600), 1);
  --terminal-mode-clickable-hint-border-color: rgba(0,0,0, 0.3);
  --terminal-mode-clickable-hint-border-color-hover: rgba(0,0,0, 0.5);
}

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 44px;
  background-color: var(--text-color);
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  transform: translate3d(0, 100%, 0);
  transition: 200ms transform, opacity, visibility;

  z-index: var(--shortcuts-hint);

  &.visible {
    transform: translate3d(0, 0, 0);
  }

  > * {
    display: flex;
  }
}

.showHints {
  position: fixed;
  bottom: 0;
  right: 8px;
  height: 38px;
  background-color: "";
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 4px;

  transform: translate3d(0, calc(100% + 10px), 0);
  transition: 200ms transform, opacity, visibility;

  border-radius: 10px;

  z-index: var(--shortcuts-hint);

  &.visible {
    transform: translate3d(0, 0, 0);
  }

  > * {
    display: flex;
  }

  .shortcut {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.rightContainer {
  align-items: center;
}

.shortcut {
  user-select: none;
  color: var(--terminal-mode-hint-color);
  fill: var(--terminal-mode-hint-color);
  font-weight: 500;
  font-size: 14px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
}

.clickableShortcut {
  user-select: none;
  color: var(--terminal-mode-hint-color);
  fill: var(--terminal-mode-hint-color);
  font-weight: 500;
  font-size: 14px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
}

.clickable {
  cursor: pointer;
  background-color: var(--terminal-mode-clickable-hint-background-color);
  height: 31px;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 8px;
  border-radius: 15px;
  box-shadow: 0px 0px 0px 1px var(--terminal-mode-clickable-hint-border-color);
  color: var(--terminal-mode-hint-color);
  fill: var(--terminal-mode-hint-color);

  .shortcutText {
    margin-top: 1px;
    margin-left: 8px;
    margin-right: 9px;
  }

  .shortcutIcons {
    margin-left: 2px;
    margin-top: 1.5px;
    svg {
      height: 24px;
    }
  }

  &:hover {
    color: var(--terminal-mode-hint-color-hover);
    fill: var(--terminal-mode-hint-color-hover);
    box-shadow: 0px 0px 0px 1px var(--terminal-mode-clickable-hint-border-color-hover);
  }
}

.clickableMobileShortcut {
  user-select: none;
  color: var(--terminal-mode-hint-color);
  fill: var(--terminal-mode-hint-color);
  font-weight: 500;
  font-size: 14px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  padding: 0;
  padding-top: 5px;
  padding-left: 5px;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  
  svg {
    height: 22px;
    width: 22px;
  }
}

.closeButton {
  width: auto;
  padding-top: 3px;
  padding-left: 14px;
  padding-right: 14px;
}

.verticalRule {
  display: flex;
  height: 48px;
  border-left: 1px solid var(--vertical-rule-color);
  margin-left: 15px;
  margin-right: 15px;
}

.shortcutText {
  // margin-top: 2px;
  margin-right: 9px;
}

.shortcutIcons {
  display: flex;
}

@include media-lg {
  .keyboardHint {
    display: none;
  }

  .shortcutRule {
    display: none;
  }
}

@include media-xs {
  .shortcutIcons {
    display: none;
  }
}
