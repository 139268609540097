@import "styles/mixins";

.header {
  width: 100%;
  margin: 0 auto;
  max-width: 1328px;
  padding: 0 64px;

  background-color: var(--background-color);
  display: flex;
  height: var(--auth-header-height);
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  overflow: hidden;
}

.menu {
  display: flex;
  align-items: center;
  white-space: nowrap;

  & > * + * {
    margin-left: 24px;
  }
}

.logo {
  max-width: 160px;
}

@include media-md {
  .header {
    padding: 0 28px;
  }
}
