.container {
  position: relative;
  padding: 50% 0 0;
  border: 1px solid transparent;
}

.container:active,
.container:focus,
.selected {
  border: 1px solid var(--blue-color);
}

.embedIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
