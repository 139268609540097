.container {
  --card-padding-horizontal: 0 36px 0 36px;

  display: flex;
  flex-direction: column;
  height: 100%;

  a {
    // define default links style
    color: var(--primary-color);
    font-weight: 500;
    cursor: pointer;
  }
}

.page {
  position: absolute;
  flex: 1;
  padding: 10px 0 88px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--background-color);
  will-change: transform;
  box-shadow: var(--help-drawer-shadow);
}

.helpButton {
  margin-left: 10px;
  margin-bottom: 30px;
  padding-left: 14px;
}

.title {
  padding: var(--card-padding-horizontal);
  font-size: 30px;
  margin: 8px 0 10px;
  line-height: 36px;
}

.helpSection {
  margin-left: 36px;
  margin-right: 36px;
}

.breadcrumbs {
  padding: var(--card-padding-horizontal);
  font-weight: 500;
  margin-bottom: 10px;
}
