@import "styles/mixins";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--card-header-default-height);

  &.sidePanel {
    min-height: 183px;
  }
}

.notMainEditor {
  padding-top: 0;
}