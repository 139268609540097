.arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;

  svg {
    position: absolute;
    top: -8px;
    left: -10px;

    :global {
      .fill {
        fill: var(--popper-bg-clr);
      }

      .stroke {
        stroke: var(--popper-br-clr);
      }
    }
  }
}

[data-popper-placement^="top"] > .arrow {
  bottom: -16px;

  svg {
    transform: rotate(180deg);
  }
}

[data-popper-placement^="bottom"] > .arrow {
  top: -4px;
}

[data-popper-placement^="left"] > .arrow {
  right: -9px;

  svg {
    margin-top: 6px;
    transform: rotate(90deg);
  }
}

[data-popper-placement^="right"] > .arrow {
  left: -11px;

  svg {
    margin-top: 6px;
    transform: rotate(-90deg);
  }
}
