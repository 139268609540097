@import "styles/mixins";



// @include media-md {
//   .sidePanelToggle {
//     visibility: hidden;
//   }
// }

.iconContainer {
  min-width: 28px;
  height: 26px;
  position: relative;
  transition: 150ms;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 250ms;
    color: inherit;
  }
}

.icon {
  opacity: 1;

}

.activeIcon {
  opacity: 0;

}

@include side-panel-opened {
  .icon {
    opacity: 0;
  }

  .activeIcon {
    opacity: 1;
  }
}


.sidePanelToggle {
  display: flex;
}

[no-side-panel-page] {
  .sidePanelToggle{
    display: none;
  }
}