@import "styles/mixins.scss";

:root {
  --due-date-calendar-popper-bg: #ffffff;
  --due-date-calendar-action-hover-bg: #f0f4f7;
  --due-date-calendar-popper-border: #dfe2e5;
}

.popperContainer {
  width: 290px;
  height: auto;

  transition: visibility 300ms, opacity 300ms !important;
}

.separator {
  margin: 0;
  border: 1px solid var(--due-date-calendar-popper-border);
}

@include dark {
  .react-calendar__tile {
    border: 4px solid var(--due-date-calendar-popper-bg) !important;
  }
}
