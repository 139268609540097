.container {
  -webkit-app-region: drag;
  min-height: 100vh;

  input, a, button, {
    -webkit-app-region: no-drag;
  }
}

.content {
  padding: 0 32px;
}
