.container {
  display: flex;
  align-self: center;
  min-width: 20px;
  min-height: 20px;
}

.calendarButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  svg {
    opacity: 0.3;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }

  &.visible {
    svg {
      display: block !important;
      opacity: 1;
    }
  }
}

.toDoDueDate {
  cursor: pointer;
  font-size: 0.6em;
  min-width: 42px;
  text-align: center;
  border-radius: 2px;
  color: var(--active-link-color);
  user-select: none;

  &:hover {
    background: #eaeaea;
  }

  &.sharing {
    cursor: default;
  }
  &.sharing:hover {
    background: transparent;
  }

  &.pastDue {
    color: var(--red-color);
  }
}
