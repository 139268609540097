.container {
  position: fixed;
  top: 7px;
  right: 11px;
  z-index: 10000;

  width: 5px;
  height: 5px;
  border-radius: 50%;

  transition: background-color 500ms;

  &.pending {
    background-color: rgba(var(--thunk-orange-500), 1);
  }

  &.failed {
    background-color: rgba(var(--thunk-red-500), 1);;
  }

  &.succeed {
    background-color: rgba(var(--thunk-green-500), 1);;
  }
}
