.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--card-padding-horizontal);
  pointer-events: none;
  padding-top: 6px;
  color: var(--placeholder-text-color);
}
