.divider {
  height: 10px;
}

.nav {
  position: relative;
}

.pointer {
  width: 3px;
  left: 0;
  background-color: var(--text-color);
}
