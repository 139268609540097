@import "styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  text-align: center;
}

.moodSwitch {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding: 0 20px;
  flex-wrap: wrap;

  > * {
    margin-bottom: 5px;
  }
}

.buttonIcon {
  font-size: 22px;
}

@include media-sm {
  .buttonText {
    display: none;
    margin-left: 6px;
  }
}
