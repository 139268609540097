@import "styles/mixins";

.root {
  --file-background: rgba(var(--thunk-grey-200), 1);
}

@include dark {
  .root {
    --file-background: #0e131a;
  }
}

.root {
  width: 100%;
  background: var(--file-background);
  padding: 10px 15px;
  border-radius: 12px;
  border: 1px solid transparent;
}

.root:hover {
  cursor: pointer;
  border: 1px solid var(--blue-color);
}

.root:active,
.root:focus,
.selected {
  border: 1px solid var(--blue-color);
}

.content {
  display: flex;
  align-items: center;
}

.fileName {
  flex: 1;
  margin-left: 5px;
  font-size: 0.9rem;
  line-height: 1.1rem;
  color: var(--text-color);
}

.meta {
  font-size: 0.8rem;
  color: rgba(var(--thunk-grey-600), 1);
  text-align: right;

  & > div {
    line-height: 1rem;
  }
}
