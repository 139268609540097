.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 200ms height;
}

.page {
  position: absolute;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  will-change: transform;
}
