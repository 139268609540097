@import "styles/mixins";

.card {
  --card-pt: 88px;
  --card-pr: 158px;
  --card-pb: 58px;
  --card-pl: 158px;

  --card-title-font-size: 30px;
  --card-title-line-height: 40px;
  --card-title-margin-bottom: 10px;

  --card-padding: var(--card-pt) var(--card-pr) var(--card-pb) var(--card-pl);
  --card-padding-horizontal: 0 var(--card-pr) 0 var(--card-pl);

  [data-card-type="reference"] {
    --card-pt: 0px;
    --card-pr: 0px;
    --card-pb: 0px;
    --card-pl: 0px;
    --card-padding-horizontal: 0 0 0 0;
  }

  --card-content-bottom-margin: 10vh;
  --card-min-height: calc(100vh - 70px - 60px);

}

[data-card-type="side-panel-card"],
[data-card-type="graph-card"] {
  .card {
    --card-pt: 60px;
    --card-padding: var(--card-pt) var(--card-pr) var(--card-pb) var(--card-pl);
    --card-padding-horizontal: 0 var(--card-pr) 0 var(--card-pl);

    --card-content-bottom-margin: 5vh;
    --card-min-height: 300px;
  }
}

[data-card-lg] {
  --card-pr: 90px;
  --card-pl: 90px;
}

[data-card-md] {
  --card-pr: 60px;
  --card-pl: 60px;
}

[data-card-sm] {
  --card-title-font-size: 26px;
  --card-title-line-height: 33px;
  --card-title-margin-bottom: 2px;

  --card-pr: 50px;
  --card-pl: 50px;
}

[data-card-xs] {
  --card-pr: 30px;
  --card-pl: 30px;
}

[data-card-xxs] {
  --card-title-font-size: 24px;
  --card-title-line-height: 32px;
  --card-title-margin-bottom: 2px;
  --card-pr: 20px;
  --card-pl: 20px;
}

.card {
  position: relative;
  margin: 0 0 16px;
  flex: 1;
  min-height: var(--card-min-height);
  width: 100%;
  max-width: 990px;
  margin: auto;

  &.noPadding {

    .content {
      padding: var(--card-pt) 0 0 0;
      margin-bottom: var(--card-content-bottom-margin);
    }

    .title {
      padding: var(--card-padding-horizontal);
    }
  }

  &.fullScreen {
    border: none;
    box-shadow: none;
  }
}

@include media-sm {
  .card {
    border-radius: 0px;
  }
}

@include media-xs {
  .card {
    border-radius: 0px;
    border: none;
  }
}

.toolbar {
  // position: absolute;
  // top: 200px;
  // right: 10px;
  // color: var(--light-text-color);
  display: flex;
  align-items: center;
  justify-content: end;

  & > * + * {
    margin-left: 10px;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  flex: 1;

  &:last-child {
    padding-bottom: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--card-padding);
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: var(--card-title-font-size);
  line-height: var(--card-title-line-height);
  margin-bottom: var(--card-title-margin-bottom);
  margin-top: 0;
  width: 100%;
}

.footer {
  background: var(white);
  padding: var(--card-footer-padding);
  border-radius: 0 0 20px 20px;
}

.titleHint {
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  transition: 150ms opacity;

  &.titleHintHidden {
    opacity: 0;
  }
}

.titleTextarea {
  width: 100%;
  display: block;
  border: none;
  overflow: auto;
  outline: none;
  resize: none;
  padding: 0;
  background-color: transparent;
  color: var(--text-color);
  font-size: var(--card-title-font-size);
  line-height: var(--card-title-line-height);
  font-weight: 700;

  &::placeholder {
    color: var(--light-text-color);
    opacity: 0.5;
  }
}

.tags {
  padding: var(--card-padding-horizontal);
  margin-bottom: 20px;
}
