@import "styles/mixins";

@mixin sections {
  .mainContent > * {
    @content;
  }
}

@mixin card-section {
  .mainContent > :nth-child(1) {
    @content;
  }
}

@mixin side-panel-section {
  .mainContent > :nth-child(2) {
    @content;
  }
}

.pageContainer {
  --sidebar-width: 0px;
  --side-panel-width: 0px;
  --mobileHeader-height: 0px;
  --base-margin-horizontal: 10px;
  --base-margin-vertical: 10px;
  --base-card-padding-horizontal: 200px;
  --base-card-padding-vertical: 50px;

  background-color: var(--page-background-color);
  position: relative;
}

.pageLayout {
  margin-left: var(--sidebar-width);
  transition: 250ms margin-left, 250ms transform;
  padding: 0 var(--base-margin-horizontal);
  display: flex;
  min-height: 100vh;

  &.noDefaultStyles {
    padding: 0;
    margin-top: 0;

    @include sections {
      max-width: none;
    }
  }

  &.fullWidth {
    @include sections {
      max-width: none;
    }
  }
}

@include sidebar-opened {
  .pageContainer {
    --sidebar-width: 255px;
  }
}

@include side-panel-opened {
  .pageContainer {
    --side-panel-width: 540px;
  }
}

.mainContentMargin {
  flex: 1;
}

.mainContent {
  position: relative;
  flex: 2;
  display: flex;
  justify-content: flex-start;
  min-width: 0; // when you have a VERY long heading and you scroll until the title transitions to the header, it was causing the main note to expend to be too wide. Adding this line stops that. I'm not sure if it causes any other problems.
}

@include sections {
  flex: 1;
  width: var(--content-max-width);
  min-width: 1px; // fix to recalculate width after resizing
  max-width: var(--content-max-width);
  word-break: break-word;

  &:empty {
    flex: 0;
  }
}

// for side-panel-section
.mainContent.animationDisabled > :nth-child(2) {
  transition: none;
}

@include side-panel-section {
  transition-property: flex;
  transition-duration: 300ms;
  transition-delay: 0ms;
}

@include side-panel-collapsed {
  @include side-panel-section {
    flex: 0;
    transition-duration: 600ms;
    transition-delay: 100ms;
  }
}

.mainContentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mobileHeader {
  display: none;
}

// custom media query based on editor cards widths
//@media only screen and (max-width: 1800px) {
  .mainContentMargin {
    flex: 0;
  }

  .mainContent {
    flex: 1;
  }

  @include sections {
    width: auto;
    flex-basis: 100%;
  }

  @include card-section {
    flex-basis: 50%;
  }
//}

@include media-xl {
  .pageContainer {
    --base-margin-horizontal: 10px;
    --base-margin-vertical: 10px;
  }
}

@include media-sm {
  .pageContainer {
    --sidebar-width: 0px;
    --mobileHeader-height: 64px;
    --content-max-width: 100%;
    --base-margin-vertical: 10px;
    --base-margin-horizontal: 0px;

    overflow: hidden;

    .pageLayout {
      margin-left: 0;
    }
  }

  body[data-side-panel-opened],
  body[data-sidebar-opened] {
    .pageLayout {
      // apply transform only when sidepanel is collapsed, focus mode overlay requires transform: none
      transform: translate3d(
        calc(var(--sidebar-width) - var(--side-panel-width)),
        0,
        0
      );
    }
  }

  @include side-panel-section {
    display: none;
  }

  .mobileHeader {
    display: block;
  }
}

@include media-xs {
  @include side-panel-opened {
    .pageContainer {
      --side-panel-width: 100vw;
    }
  }
}

@include media-xs {
  @include sidebar-opened {
    .pageLayout {
      padding: var(--base-margin-vertical) var(--base-margin-horizontal);
    }
  }

  .pageContainer {
    --base-margin-horizontal: 0px;
  }
}
