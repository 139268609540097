@import "styles/mixins";

.root {
  --file-background: rgba(var(--thunk-grey-200), 1);
}

@include dark {
  .root {
    --file-background: #0e131a;
  }
}

.root {
  width: 100%;
  background: var(--file-background);
  padding: 10px 15px;
  border-radius: 12px;
  border: 1px solid transparent;
}

.root:not(.readOnly):active,
.root:not(.readOnly):focus,
.selected {
  border: 1px solid var(--blue-color);
}

.readOnly {
  text-align: center;
  color: var(--text-color);
  font-weight: 500;
  opacity: 0.5;
  font-style: italic;
}

.submit {
  margin-left: 10px;
}

.inputForm {
  display: flex;
  flex-direction: row;
  width: 100%;

  & > div {
    flex: 1;
    margin: 0;
  }
}
