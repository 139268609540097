@import "styles/mixins";

$colors: (
  "colorYellow": "yellow",
  "colorGreen": "green",
  "colorLightBlue": "light-blue",
  "colorBlue": "blue",
  "colorPurple": "purple",
  "colorPink": "pink",
  "colorRed": "red",
  "colorOrange": "orange",
);

.container {
  display: flex;
  border-radius: 7px;
  overflow: hidden;
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  user-select: none;
  cursor: pointer;
  padding: 0 8px;

  &.inline {
    display: inline-flex;
    font-size: 14px;
  }

  &:not(.disabled) {
    &:hover {
      outline-width: 2px;
    }
  }

  &.disabled {
    cursor: default;
    > * {
      cursor: default;
    }
  }

  &.variantOutlined {

    color: rgba(var(--thunk-grey-800), 1);
    background-color: rgba(var(--thunk-grey-200), 1);
    border: 1px solid rgba(var(--thunk-grey-300), 1);

    &:hover {
      color: rgba(var(--thunk-grey-900), 1);
      background-color: rgba(var(--thunk-grey-300), 1);
      border: 1px solid rgba(var(--thunk-grey-500), 1);
    }

    @each $key, $color in $colors {
      &.#{$key} {
        color: rgba(var(--thunk-#{$color}-800), 1);
        background-color: rgba(var(--thunk-#{$color}-200), 1);
        border: 1px solid rgba(var(--thunk-#{$color}-300), 1);

        &:hover {
          color: rgba(var(--thunk-#{$color}-900), 1);
          background-color: rgba(var(--thunk-#{$color}-300), 1);
          border: 1px solid rgba(var(--thunk-#{$color}-400), 1);
        }
      }
    }
  }

  &.variantFilled {

    color: rgba(var(--thunk-white), 1);
    background-color: rgba(var(--thunk-grey-800), 1);
    border: 1px solid rgba(var(--thunk-grey-900), 1);

    &:hover {
      color: rgba(var(--thunk-white), 1);
      background-color: black;
      border: 1px solid black;
    }

    @each $key, $color in $colors {
      &.#{$key} {
        color: rgba(var(--thunk-white), 1);
        background-color: rgba(var(--thunk-#{$color}-500), 1);
        border: 1px solid rgba(var(--thunk-#{$color}-600), 1);

        &:hover {
          color: rgba(var(--thunk-white), 1);
          background-color: rgba(var(--thunk-#{$color}-600), 1);
          border: 1px solid rgba(var(--thunk-#{$color}-700), 1);
        }
      }
    }
  }

  &.sizeCircle {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .check {
      position: absolute;
      background-color: transparent;
      top: 1px;
      left: 5px;
      padding: 0;
    }
  }
}

@include dark {
  .container {
    &.variantOutlined {
      line-height: 24px;

      color: rgba(var(--thunk-grey-400), 1);
      background-color: rgba(var(--thunk-grey-800), 1);
      border: 1px solid rgba(var(--thunk-grey-700), 1);

      &:hover {
        color: rgba(var(--thunk-grey-300), 1);
        background-color: rgba(var(--thunk-grey-700), 1);
        border: 1px solid rgba(var(--thunk-grey-700), 1);
      }

      @each $key, $color in $colors {
        &.#{$key} {
          color: rgba(var(--thunk-#{$color}-400), 1);
          background-color: rgba(var(--thunk-#{$color}-300-dark), 1);
          border: 1px solid rgba(var(--thunk-#{$color}-400-dark), 1);

          &:hover {
            color: rgba(var(--thunk-#{$color}-300), 1);
            background-color: rgba(var(--thunk-#{$color}-400-dark), 1);
            border: 1px solid rgba(var(--thunk-#{$color}-500-dark), 1);
          }
        }
      }

      &.sizeCircle {
        @each $key, $color in $colors {
          &.#{$key} {
            box-shadow: 0 0 0 1px rgba(var(--thunk-#{$color}-700), 1);

            &:hover {
              box-shadow: 0 0 0 2px rgba(var(--thunk-#{$color}-700), 1);
            }
          }
        }

        &.colorBlue {
          box-shadow: 0 0 0 1px rgba(var(--thunk-blue-500), 1);

          &:hover {
            box-shadow: 0 0 0 2px rgba(var(--thunk-blue-500), 1);
          }
        }

        &.colorPurple {
          box-shadow: 0 0 0 1px rgba(var(--thunk-purple-500), 1);

          &:hover {
            box-shadow: 0 0 0 2px rgba(var(--thunk-purple-500), 1);
          }
        }
      }
    }

    &.variantFilled {
      line-height: 24px;

      color: rgba(var(--thunk-grey-800), 1);
      background-color: rgba(var(--thunk-grey-300), 1);
      border: 1px solid black;

      &:hover {
        color: rgba(var(--thunk-grey-900), 1);
        background-color: rgba(var(--thunk-grey-100), 1);
        border: 1px solid black;
      }

      @each $key, $color in $colors {
        &.#{$key} {
          color: rgba(var(--thunk-white), 1);
          background-color: rgba(var(--thunk-#{$color}-600-dark), 1);
          border: 1px solid rgba(var(--thunk-#{$color}-700-dark), 1);

          &:hover {
            color: rgba(var(--thunk-white), 1);
            background-color: rgba(var(--thunk-#{$color}-700-dark), 1);
            border: 1px solid rgba(var(--thunk-#{$color}-800-dark), 1);
          }
        }
      }
    }
  }
}

.emoji {
  margin-right: 6px;
  font-size: 16px;
}

.label {
  background-color: inherit;
  color: inherit;
}

.check {
  background-color: inherit;
  color: inherit;
  padding: 0 0 0 10px;
}

.remove {
  background-color: inherit;
  color: inherit;
  padding: 4px 0px 0 5px;
  margin-right: -2px;
}
