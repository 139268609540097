@import "styles/mixins";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--card-header-default-height);

  transition-property: background-color, border-color, box-shadow;
  transition-duration: 200ms;

  &.sidePanel {
    min-height: 183px;
  }

  &.transparent {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
}

.notMainEditor {
  padding-top: 0;
}