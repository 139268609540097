@import "styles/mixins";

.header {
  --at-menu-header-color: var(--text-color);
  --at-menu-header-border-color: rgba(var(--thunk-grey-300), 1);
}

@include dark {
  .header {
    --at-menu-header-color: #ffffff;
    --at-menu-header-border-color: rgba(var(--thunk-grey-1000), 1);
  }
}

.header {
  color: var(--at-menu-header-color);
  font-size: 14px;
  font-weight: 600;
  padding: 0px 12px 2px 12px;
}

.header:first-of-type {
  padding-top: 8px;
}

.header:not(:first-of-type) {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid var(--at-menu-header-border-color);
}
