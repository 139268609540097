.container {
  height: 30px;
}

.notEditable {
  opacity: 1 !important;

  :global {
    .react-switch-bg,
    .react-switch-handle {
      cursor: pointer !important;
    }
  }
}
