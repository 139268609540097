@import "styles/mixins";

.forkModalContent {
  border: rgba(var(--thunk-blue-1000), 1);
  background: linear-gradient(180deg, #006ce7 0%, #0147d9 100%);
  max-width: 400px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px black, inset 0px -2px 0px rgba(var(--thunk-blue-800), 1);
}

@include dark {
  .forkModalContent {
    background: linear-gradient(180deg, rgba(var(--thunk-blue-600-dark), 1) 0%, rgba(var(--thunk-blue-500-dark), 1) 100%);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px black, inset 0px -2px 0px rgba(var(--thunk-blue-400-dark), 1);
  }

  .forkModalTitle {
    color: rgba(var(--thunk-blue-300), 1);
  }
}

.forkModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 14px;
}

.actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  // margin-bottom: 20px;

  > * {
    width: 100%;
    margin: 8px 0 !important;
  }
}

.forkModalTitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  color: rgba(var(--thunk-white), 1);
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 14px;
}

.forkPageImage {
  // width: 50px;
  height: 180px;
}