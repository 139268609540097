.placeholder {
  position: absolute;
  cursor: pointer;
  top: 7px;
  left: 1px;
  background: transparent;
  border-radius: 8px;
  vertical-align: middle;
  text-align: start;
  pointer-events: none;
  user-select: none;
  width: calc(100% - 46px);
  overflow: hidden;
  padding: var(--card-padding-horizontal);
}
