@import "styles/mixins";

.menuPopper {
  position: absolute;
  z-index: var(--menu-popper-z-index);
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.selectContainer {
  background: var(--card-toolbar-menu-background-color);
  border-radius: 10px;
  min-width: 260px;
  font-weight: 500;
  overflow: hidden;
  box-shadow: 0 2px 4px var(--card-toolbar-menu-shadow-color), 0px -2px 0px 0px var(--card-toolbar-menu-inset-color) inset, 0px 0px 0px 1px var(--card-toolbar-menu-border-color);
}

.selectItem {
  background: transparent;
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 14px;
  padding-left: 4px;
  padding-right: 4px;
  color: var(--text-color);

  &:first-child {
    margin-top: 5px;
  }

  .selectItemTitle {
    padding-left: 2px;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--card-toolbar-menu-item-hover-background);
  }
}

.wordCount {
  position: relative;
  width: auto;
  z-index: 100;
  height: 40px;
  border-radius: 20px;
  line-height: 24px;
  font-size: 14px;
  outline-offset: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 0px;
  border-radius: 40px;
  min-width: 40px;
  background-color: var(--button-card-header-bg-color);
  color: var(--button-card-header-text-color);
  border: 1px solid var(--button-card-header-border-color);
  box-shadow: 0px 0px 0px 1px var(--button-card-header-outline-color);
  outline: transparent solid 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  appearance: none;
  user-select: none;
  vertical-align: middle;
}


[data-header-md] {
  .otherButton {
    display: none;
  }

  .moreButton {
    margin-left: 0px;
  }

  .wordCount {
    margin-right: 5px;
  }
}

.deleteItem {  
  border-top: 1px solid var(--card-toolbar-menu-divider-line-color);
  padding-top: 3px;
  padding-bottom: 3px;

  &:hover {
    color: white;
    background-color: var(--card-toolbar-menu-delete-color) !important;
    box-shadow: 0px -2px 0px 0px var(--card-toolbar-menu-delete-inset-color) inset;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

