.modalContainer {
  background: rgba(var(--thunk-red-700), 1);
  color: rgba(var(--thunk-white), 1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px black, inset 0px -2px 0px rgba(var(--thunk-red-700), 1);
}

.title {
  color: rgba(var(--thunk-white), 1);
}

.messageContainer {
  margin-bottom: 15px;
}

.cancelButton {
  margin-right: 10px;
  background-color: white;
  border: 1px solid rgba(var(--thunk-red-800), 1);
  color: rgba(var(--thunk-grey-800), 1);

  &:hover {
    background-color: rgba(var(--thunk-grey-200), 1);
    border: 1px solid rgba(var(--thunk-red-900), 1);
  }

  &:active {
    background-color: white;
    box-shadow: inset 0px 4px 4px rgba(var(--thunk-grey-400), 1);
    border: 1px solid rgba(var(--thunk-red-800), 1);
  }
}