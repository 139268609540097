@import "styles/mixins";

.container {
  --padding-right: 20px;

  position: fixed;
  flex: 1;
  max-height: calc(100vh - var(--app-global-padding-top) - 2 * var(--base-margin-vertical));
  margin-left: var(--base-margin-horizontal);
  margin-bottom: var(--base-margin-vertical);
  padding-bottom: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  transition: opacity 200ms, visibility 200ms;
  transition-delay: 200ms;
  opacity: 1;
  visibility: visible;
  border-radius: 30px;
}

@include side-panel-collapsed {
  .container {
    transition-delay: 0ms;
    opacity: 0;
    visibility: hidden;
  }
}

.section {
  margin-bottom: 5px;
  width: 100%;
}

.sectionHeader {
  width: 100%;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  white-space: nowrap;
}

.tabsContainer {
  display: flex;
  padding: 9px;
  height: 60px;
  background-color: rgba(var(--thunk-grey-100), 0.8);
  border-radius: 30px;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--card-border-color);
  flex-direction: row-reverse;
  z-index: var(--side-panel-tabs-container-z-index);

  button {
    margin-left: 8px;
  }

  @include media-sm {
    border-radius: 0;
  }
}

@include dark {
  .tabsContainer {
    background-color: rgba(var(--thunk-grey-900), 0.8);
    border: 1px solid rgba(var(--thunk-grey-800), 1);
  }
}

.activeTab {
  button {
    background-color: var(--sidepanel-tabs-active-bg) !important;
    color: var(--sidepanel-tabs-active-color) !important;

    &:last-child {
      display: flex;
    }
  }

}

@include media-sm {
  .sectionHeader {
    padding: 0 10px;
  }
}
