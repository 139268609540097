@import "styles/mixins";

.scrollableWrapper {
  overflow-y: auto;
}

.container {
  flex: 1;
  display: flex;
  padding-bottom: 12px;
}

.profileName {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 10px;

  form {
    width: 48%;
  }

  @include media-sm {
    flex-direction: column;

    form {
      width: 100%;
    }
  }
}

.accountPageItem {
  margin-top: 30px;
}
