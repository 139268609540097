@import "styles/mixins";

.header {
  --at-menu-header-color: var(--text-color);
}

@include dark {
  .header {
    --at-menu-header-color: #ffffff;
  }
}

.header {
  color: var(--at-menu-header-color);
  font-size: 14px;
  font-weight: 600;
  margin: 10px 10px 3px 17px;
}
