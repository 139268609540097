@import "styles/mixins";

.container {
  display: grid;
  grid-gap: 18px;
  row-gap: 18px;
  grid-template-columns: repeat(3, 1fr);
}

[data-card-sm] {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

[data-card-xxs] {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@include media-sm {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include media-xs {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}
