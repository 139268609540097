.section {
  margin-top: 22px;
}

.title {
  margin: 30px 0;
}

.message {
  font-size: 20px;
  font-weight: 500;
}
