@import "styles/mixins";

.searchInputContainer {
  position: relative;
  width: 188px;

  .searchIcon {
    position: absolute;
    top: 2px;
    left: 0;
    pointer-events: none;
  }

  .searchInput {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 8px 0 7px 32px;

    font-size: 15px;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--text-color);

    &::placeholder {
      color: rgba(var(--purple-color-rgb), 0.5);
    }
  }
}
