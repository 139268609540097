@import "styles/mixins";

.container {
  display: flex;
  flex-direction: column;

  .placeholder {
    font-size: 14px;
    height: 52px;
    // padding-top: 14px;
    text-align: center;
    flex: 1;
    align-self: center;
    justify-self: center;
  }
}
