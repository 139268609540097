.name {
  margin-top: -8px;
  font-size: 14px;
}

.price {
  font-size: 15px;
  margin-bottom: 5px;
}

.emphasis {
  color: rgba(var(--thunk-grey-800), 1);
  font-weight: 700;
}

.manageButton {
  margin-top: 10px;
}

.details {
  font-style: italic;
  font-size: 15px;
  margin-top: 6px;
}

.actions {
  display: flex;
  align-items: center;
}

.callout {
  padding: 10px 25px;
  border-radius: 12px;
  background-color: black;
  color: white;
}

.wiggle {
  animation: wiggle 2s linear 2;
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
