.table {
  font-size: 13px;
  line-height: 16px;
}

.tr {
  > * {
    text-align: left;
    padding: 8px 8px;
    min-width: 106px;
  }
}

.email {
  min-width: 200px;
}

.tr:nth-child(2n) {
  background-color: var(--background-color);
}

.tr:nth-child(2n + 1) {
  background-color: var(--references-background-color);
}
