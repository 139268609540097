@import "styles/mixins";

.menuTooltip {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: var(--range-popper-z-index);
  opacity: 0;
  visibility: hidden;
  background: var(--thunk-drowdown-menu-bg-color);
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 10px;
  box-shadow: 0 2px 4px var(--component-menu-shadow-color), 0px -2px 0px 0px var(--component-menu-inset-color) inset, 0px 0px 0px 1px var(--component-menu-border-color);

  transition: opacity 0.2s;
  min-width: 350px;
  overflow: hidden;
}

.visible {
  visibility: visible;
  opacity: 1;
}
