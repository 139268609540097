.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 0 32px;
}

.footer {
  padding: 20px;
  text-align: center;
}
