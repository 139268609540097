@import "styles/mixins";

.pageHeaderActions {
  display: flex;
  align-items: center;

  @include media-xs {
    flex-direction: column;
    align-items: flex-start;
  }
}

.cardContentWrapper {
  overflow-y: hidden;
  overflow-x: hidden;
}
