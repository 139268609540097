@import "styles/mixins";

.container {
  position: relative;
  padding-top: 125%;
  height: 0;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--button-border-color-top) var(--button-border-color-leftright) var(--button-border-color-bottom);
  box-shadow: 0 2px 4px var(--button-shadow-color);
  border-radius: 20px;
  background-color: var(--background-color);

  &:hover {
    background-color: var(--page-background-color);
    border-color: var(--button-border-color-bottom);
    box-shadow: 0 4px 8px var(--button-shadow-color);
  }
}

.titleLink {
  color: var(--text-color);
  margin: 22px 20px 0;
  font-size: 17px;
  line-height: 21px;
  font-weight: 500;
  text-align: left;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
    z-index: 1;
  }
}

.thumbnail {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: 100%;
  max-width: 100%;
}

.thumbnailImage {
  width: 100%;
  padding: 0 10px 10px;

  &:hover {
    cursor: pointer;
  }
}

.thumbnailImagePlaceholder {
  width: 100%;
  padding: 13px 20px 20px;

  opacity: 0.6;
  animation: highlight-skeleton 600ms ease-out infinite alternate;
}

@keyframes highlight-skeleton {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
