@import "styles/mixins";

.sidePanelCard {
    background-color: var(--background-color);
    border-radius: 30px;
    margin-top: 10px;
    border: 1px solid var(--card-border-color);

    @include media-sm {
        border-radius: 0;
      }
}
