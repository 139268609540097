@import "styles/mixins";

:root {
  --wrapper-drag-shadow: drop-shadow(4px 5px 10px rgba(27, 38, 51, 0.05));
  --wrapper-drag-border-color: rgba(0, 0, 0, 0.05);
  // --wrapper-vertical-padding: 6px;
}

@include dark {
  --wrapper-drag-shadow: drop-shadow(4px 5px 10px rgba(0, 0, 0, 0.2));
  --wrapper-drag-border-color: #10161e;
}

.dragOverlay {
  padding: 0 calc(29px + var(--card-pl));
  margin-left: var(--baseDepth, 0px);

  background-color: rgba(var(--background-color-rgb), 0.5);
  filter: var(--wrapper-drag-shadow);
  border-radius: 10px;
  border: 1px solid var(--wrapper-drag-border-color);
  width: calc(100% + var(--wrapper-handle-width));
  transform: translate3d(-30px, 0, 0);

  user-select: none;
  -webkit-user-select: none;
}

.dragOverlayWrapper {
  padding: var(--wrapper-vertical-padding) 0;
  margin-left: var(--spacing, 0px);
}

.handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  user-select: none;
  color: var(--primary-color);
  display: flex;

  span {
    font-size: 30px;
    font-weight: 600;
    padding-top: 2px;
    padding-left: 3px;
    line-height: var(--component-line-height);
    margin-top: var(--component-margin-top);
    margin-bottom: var(--component-margin-bottom);
  }
}

.foldingArrow {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: calc(0px - var(--card-pl));
  width: var(--card-pl);
  background: none;
  border: none;
  user-select: none;

  font-weight: 600;
  padding: 0px 12px 0 3px;
  text-align: right;
  line-height: var(--component-line-height);
  margin-top: var(--component-margin-top);
  margin-bottom: var(--component-margin-bottom);

  z-index: 1;

  svg {
    transition: 200ms transform;
    transform: rotate(90deg);
  }
}

// begin attempt to adjust folding arrow on H1—so far this is not working
.slate-component-h1 {
  .item {
    .foldingArrow {
      padding: 8px 12px 0 3px !important;
    }
  }
}
// end attempt to adjust folding arrow on H1


.folded {
  .foldingArrow {
    svg {
      transform: rotate(0deg);
    }
  }
}

// mobile
@include media-xxs {
  .handle {
    right: 0px;
    left: auto;
    border-radius: 0;
    width: 27px;
  }

  .dragOverlay {
    width: 100%;
    padding: 0 var(--card-pl);
    transform: translate3d(0, 0, 0);
  }
}
