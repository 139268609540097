.sizeSymbol, .sizeSmall {
  & + .sizeSymbol, & + .sizeSmall {
    margin-left: 5px;
  }
}

.sizeSymbol {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  line-height: 24px;
  font-size: 14px;
}

.sizeSmall {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}

.sizeCircular {
  height: 40px;
  width: 40px;
}

.sizeIcon {
  padding: 4px 8px;
}

.sizeSymbol {
  padding: 0px 5px;
}

.sizeSmall {
  padding: 0 14px 1px 14px;
  height: 30px;
  line-height: 30px;
}

.sizeLarge {
  height: 40px;
  padding: 0 22px;
  line-height: 36px;
  border-radius: 20px;
}

.sizeRegular {
  padding: 0 20px;
  height: 36px;
  line-height: 36px;
  border-radius: 8px;
}

.sizeHeaderImageSize {
  height: 70px;
  width: 124px !important;
  border-radius: 10px;
}