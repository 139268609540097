@import "styles/mixins";

.signUpForm {
  width: 100%;
  max-width: 500px;
}

.signUpFormHeadline {
  font-size: 24px;
  font-weight: bold;
  line-height: 140%;
  margin-bottom: 1.5rem;
  text-align: center;
}

.signUpFormImageContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.linkBtn {
  color: var(--blue-color) !important;
}

.easterEggLink {
  position: fixed;
    bottom: 25px;
    left: 0;
    width: 100%;
    text-align: right;
    padding-right: 25px;
}

@include media-xs {
  .easterEggLink {
    position: fixed;
    bottom: 25px;
    left: 0;
    width: 100%;
    text-align: center;
    padding-right: 0px;
  }
}
