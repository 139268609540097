.toolbarMenuContainer {
  border-radius: 10px;
  min-height: 34px;
  border: none;
  display: flex;
  min-width: 157px;
  align-items: center;
  user-select: none;
  background-color: var(--formatting-menu-background-color);
}
