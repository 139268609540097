.formGroupLabel {
  min-height: 33px;
  display: flex;

  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;

  color: var(--text-color);

}

.formGroupLabelWithError {
  color: var(--validation-color);
}

.labelEditButtons {
}