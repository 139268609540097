.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  border-width: 3px;
  border-radius: 5px;
  border-color: transparent;
  border-style: dashed;
  color: var(--text-color);
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzoneText {
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 5px;
  }
  & > p {
    position: relative;
    top: 2px;
  }
}

.active {
  border-color: var(--blue-color);
}

.accepted {
  border-color: var(--green-color);
}

.rejected {
  border-color: var(--red-color);
}
