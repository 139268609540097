@import "styles/mixins";

// vars
:root {
  --wrapper-drag-shadow: drop-shadow(4px 5px 10px rgba(27, 38, 51, 0.05));
  --wrapper-drag-border-color: #e7eaef;
  --wrapper-highlight-color: rgba(var(--thunk-light-blue-200), 1);
  --wrapper-folded-color: rgba(var(--thunk-grey-200), 1);
  --wrapper-folded-border-color: rgba(var(--thunk-grey-300), 1);
  --wrapper-folded-left-right-border-color: rgba(var(--thunk-grey-500), 1);
  --wrapper-handle-width: 48px;
  --wrapper-vertical-padding: 5px;
  --list-line-color: rgba(var(--text-color-rgb), 0.1);
  --block-highlight-color: #ffebb2;

  --card-border-color: #e7eaef;
  --card-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
  --wrapper-highlight-border-color: rgba(var(--thunk-light-blue-300), 1);
  --wrapper-dragging-border-color: rgba(var(--thunk-blue-300), 1);
  --handle-icon-color: rgba(var(--thunk-light-blue-600), 1);

  --wrapper-terminal-selected-background: rgba(var(--thunk-light-blue-200), 1);
  --wrapper-terminal-selected-border-color: rgba(var(--thunk-light-blue-400), 1);
  --wrapper-terminal-active-background: rgba(var(--thunk-light-blue-300), 1);
  --wrapper-terminal-active-border-color: rgba(var(--thunk-light-blue-500), 1);
}

@include dark {
  --wrapper-drag-shadow: drop-shadow(4px 5px 10px rgba(0, 0, 0, 0.2));
  --wrapper-drag-border-color: #10161e;
  --wrapper-highlight-color: rgba(var(--thunk-blue-200-dark), 1);
  --wrapper-highlight-border-color: rgba(var(--thunk-blue-300-dark), 1);
  --wrapper-folded-color: rgba(var(--thunk-grey-900), 1);
  --wrapper-folded-border-color: rgba(var(--thunk-grey-800), 1);
  --wrapper-folded-left-right-border-color: rgba(var(--thunk-grey-700), 1);
  --list-line-color: rgba(var(--text-color-rgb), 0.06);
  --block-highlight-color: #1d3761;

  --card-border-color: #10161e;
  --card-box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);
  --wrapper-dragging-border-color: rgba(var(--thunk-blue-300-dark), 1);
  --handle-icon-color: rgba(var(--thunk-blue-700-dark), 1);

  --wrapper-terminal-selected-background: rgba(var(--thunk-blue-200-dark), 1);
  --wrapper-terminal-selected-border-color: rgba(var(--thunk-blue-300-dark), 1);
  --wrapper-terminal-active-background: rgba(var(--thunk-blue-300-dark), 1);
  --wrapper-terminal-active-border-color: rgba(var(--thunk-blue-400-dark), 1);
}

// components styles
:root {
  counter-reset: terminal-counter;
}

.elementContainer {
  position: relative;
  list-style: none;
  border: 1px solid rgba(white, 0); // maybe there's a better way, but added this so that things don't move when we add and remove borders

  // &[data-slate-node-type="code_block"] {
  //   background-color: var(--slate-codeblock-background-color);
  //   border-radius: 20px;

  //   &.dragging {
  //     background-color: var(--background-color);
  //   }
  // }

  transition-property: transform;
  transition-duration: 200ms;

  &.hidden {
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: none !important;
  }

  &.nestingElement {
    &.dragging {
      transition: 200ms margin-left;
    }
  }

  &.terminalActive {
    &.terminalSelected {
      .item {
        background-color: var(--wrapper-terminal-selected-background);
        border: 1px solid var(--wrapper-terminal-selected-border-color);
      }

      &::before {
        color: var(--handle-icon-color);
      }
    }

    &.selected {
      .item {
        background-color: var(--wrapper-terminal-active-background);
        border: 1px solid var(--wrapper-terminal-active-border-color);
      }

      &::before {
        color: var(--handle-icon-color);
      }
    }
  }

  &.terminal {

    &::before {
      counter-increment: terminal-counter;
      content: counter(terminal-counter) "";

      position: absolute;
      top: 5px;
      left: 40px;
      display: flex;
      transform: translate3d(-100%, 0, 0);
      z-index: 2;
      font-size: 14px;
      font-weight: 700;
      color: rgba(var(--thunk-grey-500), 1);
      font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
        monospace;
    }

    &.selected {
      transform: scale(1.03, 1.03);
      z-index: 1;
    }

    .item {
      transition-duration: 200ms;
      cursor: grab;

      border: 1px solid var(--card-border-color);
      background: var(--background-color);
      box-shadow: 0px 3px 6px var(--card-box-shadow);
      border-radius: 10px;

      &.dragging {
        background: transparent;
        border-style: dashed;
      }

      > * {
        pointer-events: none;
      }
    }

    &.selected {
      //transform: scale(1.05, 1.05);
    }
  }
}

body[data-slate-dragging] {
  .elementContainer::before {
    display: none;
  }
}

@keyframes fadein {
  0% {
    background: initial;
  }
  100% {
    background: var(--block-highlight-color);
  }
}

.highlight {
  background: var(--block-highlight-color);
  animation: fadein 0.3s ease-in;
}

[data-card-type="reference"] {
  .elementContainer {
    > * {
      --spacing: 10;
    }
  }
}

.item {
  position: relative;
  transform-origin: 0 0;
  touch-action: manipulation;

  padding: 0 var(--card-pl);
  padding-top: var(--wrapper-vertical-padding);
  padding-bottom: var(--wrapper-vertical-padding);
  transition: background-color 150ms ease;
  transition-property: margin, box-shadow, background-color;

  border: 1px solid transparent;

  &.noPadding {
    padding: 0;
  }

  &.dragging {
    z-index: 1;
    background-color: var(--wrapper-highlight-color);
    border: 1px solid var(--wrapper-dragging-border-color) !important;
    margin: -1px;
    border-radius: 8px;
    height: var(--drag-overlay-height, auto);

    & > *:not(.indicator) {
      visibility: hidden;
    }
  }
}

.nestingElement {
  .item {
    //padding-left: 0;
    // padding-left: calc(33px + var(--spacing));
    padding-left: calc(var(--card-pl) + var(--spacing));
  }
}

.indicator {
  display: none;
  width: 1px;
  height: calc(100% + 26px);
  position: absolute;
  top: -13px;
  left: calc(var(--card-pl) + 9.5px);
  transform: translate3d(var(--spacing), 0, 0);
  transition: transform 150ms;
  background-color: var(--handle-icon-color);
  border-radius: 8px;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: -3.5px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--handle-icon-color);
    content: " ";
  }

  &:after {
    top: auto;
    bottom: 0;
  }
}

.nestingElement {
  .item {
    &.dragging .indicator {
      display: block;
    }
  }
}

.handle {
  position: absolute;
  top: 0;
  left: calc(0px - var(--base-card-padding-horizontal));
  width: var(--base-card-padding-horizontal);
  height: calc(100% + 2px);
  margin-top: -1px;
  border-radius: 10px 0 0 10px;
  opacity: 0;
  user-select: none;
  display: flex;

  cursor: grab;

  &:hover {
    cursor: grab;
  }

  span {
    font-size: 30px;
    font-weight: 600;
    padding-top: 2px;
    padding-left: 3px;
    line-height: var(--component-line-height);
    margin-top: var(--component-margin-top);
    margin-bottom: var(--component-margin-bottom);
    // display: none;
  }
}

.lineNumber {
  position: absolute;
  top: 2px;
  left: -4px;
  display: flex;
  transform: translate3d(-100%, 0, 0);

  .lineNumberDigit {
    //border: 1px solid red;
    margin-left: -7px;
  }
}

.nestingElement {
  .handle {
    //left: calc(-70px - var(--spacing));
  }
}

.foldingArrow {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  height: 100%;
  left: 0;
  width: var(--card-pl);
  background: none;
  border: none;
  user-select: none;

  font-weight: 600;
  padding: 0px 12px 0 3px;
  text-align: right;
  line-height: var(--component-line-height);
  // margin-top: var(--component-margin-top);
  margin-bottom: var(--component-margin-bottom);

  z-index: 1;

  svg {
    transition: 200ms transform;
    transform: rotate(90deg);
    position: absolute;
    right: 10px;
    bottom: 32%;
  }

  &:hover {
    color: var(--handle-icon-color);
  }
}

.nestingElement {
  .foldingArrow {
    left: calc(var(--card-pl) - var(--card-pl));

    svg {
      top: 11px;
    }
  }
}

:global(.highlightedChild) {
  background-color: var(--wrapper-folded-color);
  border: 1px solid var(--wrapper-folded-color);
  border-radius: 0;
  //padding-bottom: 3px;
  //margin-bottom: 3px;
}


// modifiers
.opened,
.highlighted {
  background-color: var(--wrapper-highlight-color) !important;
  border-top: 1px solid var(--wrapper-highlight-border-color) !important;
  border-right: 1px solid var(--wrapper-highlight-border-color) !important;
  border-bottom: 1px solid var(--wrapper-highlight-border-color) !important;
  border-left: 1px solid var(--wrapper-highlight-border-color) !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;

  // &[data-slate-node-type="code_block"] {
  //   background-color: var(--slate-codeblock-background-color) !important;
  // }

  .handle {
    opacity: 1;
    cursor: grab;
    // background-color: var(--wrapper-highlight-color);
    // border-top: 1px solid var(--wrapper-highlight-border-color);
    // border-bottom: 1px solid var(--wrapper-highlight-border-color);
    // border-left: 1px solid var(--wrapper-highlight-border-color);

    span {
      color: var(--handle-icon-color);
    }
  }

  .foldingArrow {
    opacity: 1;
  }
}

.folded {
  background-color: var(--wrapper-folded-color);
  border: 1px solid var(--wrapper-folded-color);
  border-radius: 10px;
  margin-bottom: 2px;

  .foldingArrow {
    opacity: 1;

    svg {
      transform: rotate(0deg);
    }
  }
}

[data-card-lg] {
  .folded {
    border-left: 1px solid var(--wrapper-folded-left-right-border-color);
    border-right: 1px solid var(--wrapper-folded-left-right-border-color);
    border-top: 1px solid var(--wrapper-folded-color);
    border-bottom: 1px solid var(--wrapper-folded-color);
    // border-right: none;
    border-radius: 0;
  }

  // .elementContainer {
  //   &[data-slate-node-type="code_block"] {
  //     border-radius: 0;
  //   }
  // }

  .elementContainer {
    &.terminal {
      .item {
        border-radius: 0px;
        border-left: 0;
        border-right: 0;
      }
    }
  }

  :global(.highlightedChild) {
    border-radius: 0;
    border-left: 1px solid var(--wrapper-folded-left-right-border-color);
    border-right: 1px solid var(--wrapper-folded-left-right-border-color);
  }

  .highlighted {
    border-radius: 0 !important;
  }

  .handle {
    span {
      margin-left: calc(var(--card-pr) - 40px);
    }
  }

  .item {
    &.dragging {
      border-radius: 0;
    }
  }
}

[data-card-md] {
  .handle {
    span {
      margin-left: calc(var(--card-pr) - 40px);
    }
  }
  .elementContainer {
    &.terminal {
      &::before {
        display: none;
      }
    }
  }
}

[data-card-sm] {
  .handle {
    span {
      margin-left: calc(var(--card-pr) - 40px);
    }
  }
}

[data-card-xs] {
  .handle {
    span {
      margin-left: calc(var(--card-pr) - 30px);
    }
  }
}

[data-card-xxs] {
  .handle {
    span {
      margin-left: calc(var(--card-pr) - 24px);
    }
  }
}

.disableSelection,
.disableSelection * {
  user-select: none;
  -webkit-user-select: none;
}

.disableInteraction {
  pointer-events: none;
}

@mixin selected {
  .handle {
    opacity: 1;
  }

  .foldingArrow {
    opacity: 1;
  }
}

// body dependent styles
body[data-slate-dragging] {
  .item {
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
      scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
    z-index: 0;
  }

  .handle {
    display: none;
  }
}

body:not([data-mobile-device]):not([data-tablet-device]):not([data-slate-dragging]) {
  &[input-mode="mouse"] {
    .elementContainer:hover {
      @include selected;
    }
  }
}

body[data-mobile-device]:not([data-slate-dragging]),
body[data-tablet-device]:not([data-slate-dragging]) {
  .foldingArrow {
    opacity: 0;
    svg {
      height: 9px;
      margin-left: 3px;
      margin-bottom: 1px;
    }
  }

  .folded {
    .foldingArrow {
      opacity: 1;
      padding-right: 4px;
    }
  }
}

.listLine {
  user-select: none;
  position: absolute;
  width: 20px;
  height: calc(var(--height) - 6px);
  top: 42px;
  left: calc(-1px + var(--card-pl) + var(--spacing));
  z-index: var(--list-line-z-index);
  cursor: pointer;

  &::before {
    width: 2px;
    height: 100%;
    border-radius: 2px;
    background-color: var(--list-line-color);
    display: block;
    content: "";
    margin-left: 10px;
    transition: 200ms box-shadow, 200ms background-color;
  }

  &:hover::before {
    width: 4px;
    border-radius: 4px;
    margin-left: 9px;
    background-color: var(--handle-icon-color);
    box-shadow: 0 0 1px var(--handle-icon-color);
  }
}


// mobile

.handle {
  z-index: 1;
  right: 0px;
  // margin-right: calc(0px - var(--card-pr));
  margin-top: 1px;
  left: auto;
  opacity: 0;
  color: #667e995e;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: none !important;
  width: var(--card-pr);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  span {
    margin-left: calc(var(--card-pr) - 30px);
  }
}

body:not([data-slate-dragging]) {
  .elementContainer.selected {
    .handle {
      opacity: 1;
    }
  }

  .handle {
    &:hover {
      opacity: 1;
    }
  }
}

.terminalCheckbox {
  position: absolute;
  top: 50%;
  right: 3px;
  z-index: 1;

  svg {
    polyline {
      display: none;
    }
  }
}


@include media-lg {
  .elementContainer {
    &.terminal {
      &.selected {
        transform: scale(1.01, 1.01);
        z-index: 1;
      }
    }
  }
}

@include media-md {
  .elementContainer {
    &.terminal {
      &.selected {
        transform: scale(1, 1);
        z-index: 1;
      }
    }
  }
}

// @include media-xs {
//   .handle {
//     right: 2px;
//   }
// }

// @include media-xxs {
//   .handle {
//     right: -2px;
//   }
// }