@import "styles/mixins";

.menuItem {
  --hash-menu-item-focused-background: var(--thunk-select-color);
  --hash-menu-item-hovered-background: var(--thunk-dropdown-menu-item-hover-color);
  --hash-menu-item-color: var(--menu-bg-clr);
  --hash-menu-item-hovered-color: #ffffff;
  --hash-menu-item-highlight-color: #ffe499;
}

@include dark {
  .menuItem {
    --hash-menu-item-color: #ffffff;
  }
}

.menuItem {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 3px 10px 3px 10px;
  background: transparent;
  color: var(--hash-menu-item-color);
  font-weight: 500;
  fill: var(--hash-menu-item-color);
  cursor: pointer;

  .text {
    font-size: 14px;
    line-height: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .hint {
    margin-left: auto;
    opacity: 0.3;
    max-width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.focused {
    background-color: var(--hash-menu-item-focused-background);
    color: var(--hash-menu-item-focused-color);
    fill: var(--hash-menu-item-focused-color);
    stroke: var(--hash-menu-item-focused-color);
  }

  svg {
    min-width: 19px;
    margin-top: -2px;
    margin-right: 4px;
  }
}

body[input-mode="mouse"] {
  .menuItem {
    &:hover {
      background-color: var(--hash-menu-item-hovered-background);
      color: var(--hash-menu-item-hovered-color);
      fill: var(--hash-menu-item-hovered-color);
      stroke: var(--hash-menu-item-hovered-color);
    }
  }
}

.mark {
  background-color: var(--hash-menu-item-highlight-color);
  color: #000;
  border-radius: 2px;
  padding-left: 1px;
  padding-right: 1px;
}
