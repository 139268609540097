@import "styles/mixins";

.todayButton {
  background-color: white !important;
  color: black !important;
  border: 1px solid;
  border-color: #0054b2 !important;
  box-shadow: 0px -2px 0px 0px var(--button-inner-shadow-color) inset,
    0px 2px 4px #016be5;

  &:hover {
    // color: var(--primary-color) !important;
    // border-color: white !important;
    // box-shadow: none;
    color: white !important;
    background-color: #0053b2 !important;
    border-color: #003b80 !important;
    box-shadow: 0px -2px 0px 0px #004799 inset, 0px 2px 4px #0062d3;
  }
}

@include dark {
  .todayButton {
    background-color: #161d26 !important;
    color: var(--text-color) !important;
    border: 1px solid;
    border-color: #000 !important;
    box-shadow: 0px -2px 0px 0px var(--button-inner-shadow-color) inset,
      0px 2px 4px rgba(0, 0, 0, 0.2);

    &:hover {
      // color: var(--primary-color) !important;
      // border-color: white !important;
      // box-shadow: none;
      color: white !important;
      background-color: #0053b2 !important;
      border-color: #000 !important;
      box-shadow: 0px -2px 0px 0px #004799 inset, 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
}
