.avatar {
  border-radius: 50%;

  width: 40px;
  height: 40px;

  &.profile {
    width: 150px;
    height: 150px;
  }

  &.header {
    width: 24px;
    height: 24px;

    margin-right: 3px;
  }
}

.avatarPlaceholder.active {
  display: none;
}

:global(.sidebar__user:hover),
:global(.sidebar__user.active) {
  .avatarPlaceholder {
    display: none;
  }
  .avatarPlaceholder.active {
    display: block;
  }
}
