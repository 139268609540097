@import "styles/mixins";

:root {
  --pinned-item-hover-bg: #ddecfd;
  --pinned-item-color: #1b2633;
  --clear-all-border-color: rgba(000, 000, 000, 0.1);
  --sidepanel-tabs-active-bg: rgba(var(--thunk-grey-400), 1);
  --sidepanel-tabs-active-border: #1b2633;
  --sidepanel-tabs-active-color: #1b2633;
}

@include dark {
  --pinned-item-hover-bg: #10171f;
  --pinned-item-color: #c2cbd6;
  --clear-all-border-color: rgba(255, 255, 255, 0.1);
  --sidepanel-tabs-active-bg: #1b2633;
  --sidepanel-tabs-active-color: var(--text-color);
  --sidepanel-tabs-active-border: var(--text-color);
}

.popperContainer {
  min-width: 290px;
  padding: 8px 0px;
  overflow: hidden;
}

.carat {
  svg {
    transform: rotate(90deg);
  }

  &.hovered,
  &:hover {
    background: var(--blue-color) !important;
    color: white !important;
  }
}

.linksPopperList {
  width: 100%;
  user-select: none;
}

.linksPopperListItem {
  display: flex;
  color: var(--pinned-item-color);
  padding: 1px 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background: var(--thunk-dropdown-menu-item-hover-color);
  }
}

.bulkPin {
  &:hover {
    cursor: pointer;
  }
}

.clearAll {
  display: flex;
  font-size: 14px;
  padding: 1px 10px 0px 10px;

  &:hover {
    cursor: pointer;
    background: var(--thunk-dropdown-menu-item-hover-color);
  }
}

hr {
  border: none;
  border-bottom: 1px solid var(--thunk-dropdown-menu-divider-line-color);
  margin-block-start: 5px;
  margin-block-end: 5px;
}

.pinnedIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
}

.linkText {
  flex: 1;
  margin-left: 3px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 340px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;

  label {
    cursor: pointer;
  }

  svg {
    position: relative;
    top: 2px;
    margin-right: 3px;
  }
}
