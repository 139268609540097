.selectContainer {
  background: var(--time-select-menu-background-color);
  border: 1px solid var(--time-select-menu-border-color);
  border-radius: 16px;
  box-shadow: 0 6px 22px var(--time-select-menu-shadow-color);
  min-width: 340px;
  padding: 8px;
  font-weight: 500;
}

.emojiMartEmoji {
  position:relative;
  display: flex;
  justify-content:flex-start;
  flex-wrap: wrap;
  border: 1px solid var(--select-menu-border-color);
  filter: var(--select-menu-shadow);
  padding: 10px 20px 10px 20px;
  background: var(--select-menu-background-color);
  border-radius: var(--select-menu-border-radius);
  width: 400px;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  .emojiMartEmojiWarpper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    h1 {
      font-size: 16px;
      width: 100%;
    }
    button {
      span  {
        cursor: pointer!important;
      }
    }
  }
  .emojiMartCategoryRow {
    width:100%;
  }

  .emojiMartCategoryName{
    font-size: 10px;
    font-weight: 500;
    margin: 8px 0px;
    text-transform: uppercase;
    color: var(--text-color);
    letter-spacing: 0.7px;
  }
  .emojiMartSingleEmojiWarpper{
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    gap: 5px;
  }
  .emojiItem{
    padding: 0px;
    border-radius: 3px;
    width: 30px;
    cursor: pointer;
    &.active{
      span{
        span {
          background: var(--select-menu-item-hovered-background-color);
          border: 1px solid var(--select-menu-item-hovered-border-color);
        }
      }
    }
    span {
      span{
        padding:0px 4px;
        border-radius: 6px;
        border: 1px solid var(--select-menu-background-color);
      }
    }
  }
}
