@import "styles/mixins";

.button {
  --button-shadow-color: rgba(27, 38, 51, 0.07);
}

// .button + .button {
//   margin-left: 8px;
// }

.button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  outline: transparent solid 2px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  appearance: none;
  user-select: none;
  position: relative;
  vertical-align: middle;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-image-slice: 1;

  &.noMargin {
    .buttonIcon {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:focus-visible {
    background-color: rgba(var(--thunk-blue-600), 1);
    color: white !important;
  }

  &.disabled {
    opacity: 0.8;
    cursor: default;
  }

  &.fullWidth {
    width: 100%;
  }
}

html:root {
  // increase priority
  .button.selected {
    background-color: rgba(var(--thunk-blue-600), 1);
    color: white;

    &:hover {    
      background-color: rgba(var(--thunk-blue-700), 1);
      border-top: 1px solid rgba(var(--thunk-blue-500), 1);
      border-bottom: 1px solid rgba(var(--thunk-blue-500), 1);
    }
  }
}

.buttonIcon {
  margin-top: 1px;
  margin-right: 5px;
  margin-left: -5px;
  margin-bottom: 2px;
}

.loader {
  margin-top: 12px; // not sure why but this seems to have to be a higher value than I would expect. There was a font-size on this, but that was causing a problem with text alignment on other buttons. 
  margin-right: 5px;
  margin-left: -5px;
}

.buttonGroup {
  display: flex;

  .button {
    color: var(--text-color);

    &:hover {
      // background-color: var(--primary-color);
      // color: white;
      // box-shadow: 0px -2px 0px 0px #005fcc inset,
      //   0px 2px 4px 0px rgba(1, 119, 255, 0.2);
      // border-color: #0053b2;
    }

    // &:not(:last-child) {
    //   margin-right: -2px;
    // }

    &:focus-visible {
      z-index: 1;
    }
  }
}

@include dark {
  .button {
    &:focus-visible {
      background-color: #003b80 !important;
      color: white;
      border-color: #0053b2 !important;
      box-shadow: 0px -2px 0px 0px #002a5c inset,
        0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    }
  }
  .buttonGroup {
    .button {
      background-color: #0e131a;
      color: #c2cbd6;

      &.selected {
        background-color: #003b80;
        color: white;
        box-shadow: 0px -2px 0px 0px #002a5c inset,
          0px 2px 4px 0px rgba(0, 0, 0, 0.2);
        border-color: #001833;

        &:hover {
          background-color: #003b80;
        }
      }
    }
  }
}
