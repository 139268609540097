@import "styles/mixins";

body {
  background-color: var(--page-background-color);
  color: var(--text-color);
  margin: 0;
  margin-top: var(--app-global-padding-top);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  line-height: 26px;
  min-width: 320px;

  // disable scrolling the whole page
  // height: 100%;
  overflow-y: auto;

  font-family: AtlasGrotesk, -apple-system, BlinkMacSystemFont, "Roboto",
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  width: 100%;
}

body[data-slate-dragging] {
  cursor: grabbing;
  height: 100%;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
}

[data-windows-device] {
  &.with-scrollbar,
  .with-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-bg) var(--scrollbar-bg);

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-bg);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb-bg);
      border-radius: 6px;
      border: 2px solid var(--scrollbar-bg);
    }
  }
}

body[data-not-public] {
  background-color: var(--background-color);
}

@include media-sm {
  body[data-sidebar-opened],
  body[data-side-panel-opened] {
    position: fixed;
    height: 100vh;
    overflow: hidden;
    touch-action: none;
  }
}

.firebase-emulator-warning {
  display: none;
}

h1 {
  font-size: 40px;
  line-height: 1.167;
  margin: 0 0 10px;
}

h2 {
  font-size: 36px;
  line-height: 1.2;
  margin: 0 0 7px;
}

h3 {
  font-size: 24px;
  line-height: 1.167;
  margin: 0 0 5px;
}

h4 {
  font-size: 20px;
  line-height: 1.235;
  margin: 0 0 3px;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

a {
  text-decoration: none;
  color: var(--light-blue-text-color);
}

a:focus {
  // text-decoration: underline;
}
