.slideContainer {
  position: relative;

  .expandButton {
    opacity: 0;
    position: absolute;
    left: calc(50% - 72px);
    top: calc(50% - 16px);
    width: 144px;
    height: 32px;
    display: flex;
    background-color: var(--primary-color);
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: #fff;
    border-radius: 10px;
    z-index: 10;
    transform: scale(0.95);
    transition: 230ms opacity, 280ms transform;

    .expandButtonIcon {
      margin-right: 7px;
      stroke-width: 0.6px;
    }
  }

  .imageContainer {
    transition: 230ms filter;
  }

  &:hover {
    .expandButton {
      opacity: 1;
      transform: scale(1);
    }

    .imageContainer {
      filter: brightness(70%);
    }
  }
}
