@import "styles/mixins";

.pageItem,
.allNotesItem {
  --moveto-menu-item-hovered-background: rgba(var(--thunk-grey-500), 0.1);
  --moveto-menu-item-focused-background: rgba(var(--thunk-blue-500), 0.1);
  --moveto-menu-item-hovered-background: rgba(var(--thunk-blue-500), 0.2);
}

@include dark {
  .pageItem,
  .allNotesItem {
    --moveto-menu-item-hovered-background: rgba(var(--thunk-black), 0.5);
    --moveto-menu-item-focused-background: rgba(var(--thunk-black), 0.5);
    --moveto-menu-item-hovered-background: rgba(var(--thunk-black), 1);
  }
}

.pageResults {
  max-height: 275px;
  max-width: 285px;
  overflow-y: auto;
  overflow-x: hidden;
  touch-action: pan-y;
}

.allNotesItem {
  &:hover {
    cursor: pointer;
    background: var(--moveto-menu-item-hovered-background);
  }
}

.pageItem {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    background: var(--moveto-menu-item-hovered-background);
  }
}

.activeItem {
  background: var(--moveto-menu-item-focused-background);
}

.pageItemPath {
  font-size: 0.7rem;
  line-height: 0.9rem;
  color: rgba(var(--thunk-grey-600), 1);
}

.pageItemTitle {
  color: var(--thunk-text);
}

.mark {
  background-color: #ffe499;
  color: #000;
  border-radius: 2px;
  padding-left: 1px;
  padding-right: 1px;
}
