@import "styles/mixins";

:root {
  //button colors for electron header
  --button-electron-header-bg-color: rgba(0, 0, 0, 0.05);
  --button-electron-header-bg-hover-color: rgba(0, 0, 0, 0.1);
  --button-electron-header-inner-shadow-color-active: rgba(0, 0, 0, 0.1);

  //// CIRCULAR BUTTON COLORS ////
  // circular button colors
  --button-circular-bg-color: rgba(var(--thunk-grey-200), 1);
  --button-circular-border-color: rgba(var(--thunk-grey-300), 1);

  // circular button colors: hover state
  --button-circular-bg-color-hover: rgba(var(--thunk-grey-300), 1);
  --button-circular-border-color-hover: rgba(var(--thunk-grey-400), 1);

  // circular button colors: active state
  --button-circular-bg-color-active: rgba(var(--thunk-grey-200), 1);
  --button-circular-inner-shadow-color-active: rgba(var(--thunk-grey-300), 1);
  //// END CIRCULAR BUTTON COLORS ////


  //// CARD HEADER BUTTON COLORS ////
  // Button colors for header
  --button-card-header-bg-color: rgba(var(--thunk-grey-300), 0.7);
  --button-card-header-text-color: black;
  --button-card-header-border-color: rgba(255, 255, 255, 0.3);
  --button-card-header-outline-color: rgba(0, 0, 0, 0.1);

  // Button colors for header: hover state
  --button-card-header-bg-color-hover: rgba(var(--thunk-grey-300), 0.9);
  --button-card-header-text-color-hover: black;
  --button-card-header-border-color-hover: rgba(255, 255, 255, 0.6);
  --button-card-header-outline-color-hover: rgba(0, 0, 0, 0.2);

  // Button colors for header: active state
  --button-card-header-bg-color-active: rgba(var(--thunk-grey-300), 0.7);
  --button-card-header-inner-shadow-color-active: rgba(var(--thunk-grey-1000), 0.15);
  --button-card-header-outline-color-active: rgba(0, 0, 0, 0.2);
  //// END HEADER BUTTON COLORS ////
 

  //// CHANGE HEADER IMAGE BUTTON COLORS ////
  --button-header-image-border-color: white;
  --button-header-image-border-color-hover: rgba(var(--thunk-yellow-500), 1);
  --button-header-image-border-color-active: rgba(var(--thunk-light-blue-500), 1);
  //// END CHANGE HEADER IMAGE BUTTON COLORS ////
}

@include dark {
  //button colors for electron header
  --button-electron-header-bg-color: rgba(var(--thunk-grey-700), 0.6);
  --button-electron-header-bg-hover-color: rgba(var(--thunk-grey-700), 0.8);
  --button-electron-header-inner-shadow-color-active: rgba(0, 0, 0, 0.3);

  //// CIRCULAR BUTTON COLORS ////
  // circular button colors
  --button-circular-bg-color: rgba(var(--thunk-grey-900), 1);
  --button-circular-border-color: rgba(var(--thunk-grey-1000), 1);

  // circular button colors: hover state
  --button-circular-bg-color-hover: rgba(var(--thunk-grey-1000), 1);
  --button-circular-border-color-hover: black;

  // circular button colors: active state
  --button-circular-bg-color-active: rgba(var(--thunk-grey-900), 1);
  --button-circular-inner-shadow-color-active: rgba(var(--thunk-grey-1000), 1);
  //// END CIRCULAR BUTTON COLORS ////


  // Button colors for header
  --button-card-header-bg-color: rgba(var(--thunk-grey-1000), 0.7);
  --button-card-header-text-color: rgba(var(--thunk-grey-400), 1);
  --button-card-header-border-color: rgba(255, 255, 255, 0.05);
  --button-card-header-outline-color: black;

  // Button colors for header: hover state
  --button-card-header-bg-color-hover: rgba(var(--thunk-grey-900), 0.7);
  --button-card-header-text-color-hover: rgba(var(--thunk-grey-300), 1);
  --button-card-header-border-color-hover: rgba(255, 255, 255, 0.1);
  --button-card-header-outline-color-hover: black;

    // Button colors for header: active state
    --button-card-header-bg-color-active: rgba(var(--thunk-grey-900), 0.5);
    --button-card-header-inner-shadow-color-active: rgba(0, 0, 0, 0.3);
    --button-card-header-outline-color-active: black;
 

    //// CHANGE HEADER IMAGE BUTTON COLORS ////
    --button-header-image-border-color: rgba(var(--thunk-grey-800), 1);
    --button-header-image-border-color-hover: rgba(var(--thunk-yellow-800-dark), 1);
    --button-header-image-border-color-active: rgba(var(--thunk-light-blue-800-dark), 1);
    //// END CHANGE HEADER IMAGE BUTTON COLORS ////
}


// primary
.variantPrimary {
  background-color: var(--primary-color);
  color: white;

  box-shadow: 0px -2px 0px 0px rgba(var(--thunk-blue-600), 1) inset,
    0px 2px 4px 0px rgba(var(--thunk-blue-600), 0.2);

  border: 1px solid rgba(var(--thunk-blue-800), 1);

  &:hover {
    background-color: rgba(var(--thunk-blue-600), 1);
    color: white;
    box-shadow: 0px -2px 0px 0px rgba(var(--thunk-blue-700), 1) inset,
      0px 3px 6px 0px rgba(var(--thunk-blue-600), 0.3);
      border: 1px solid rgba(var(--thunk-blue-900), 1);
  }

  &:focus {
    text-decoration: none;
    background-color: var(--primary-color);
    box-shadow: 0px 2px 4px 0px rgba(var(--thunk-blue-600), 1) inset,
  }
}

@include dark {
  .variantPrimary {
    background-color: rgba(var(--thunk-blue-500-dark), 1);
    box-shadow: 0px -2px 0px 0px rgba(var(--thunk-blue-400-dark), 1) inset,
      0px 2px 4px 0px rgba(0,0,0, 0.3);
    border-color: black;

    &:hover {
      background-color: rgba(var(--thunk-blue-400-dark), 1);
      box-shadow: 0px -2px 0px 0px rgba(var(--thunk-blue-300-dark), 1) inset,
        0px 2px 4px 0px rgba(0,0,0, 1);
      border-color: black;
    }
  }
}

// secondary
.variantSecondary {
  background-color: #ffffff;
  color: #1b2633;

  box-shadow: 0px -2px 0px 0px var(--button-inner-shadow-color) inset,
    0px 2px 4px 0px var(--button-shadow-color);

  border: 1px solid;
  border-color: #dae0e5 #cdd0d3 #b7babd;

  &:hover {
    background-color: var(--primary-color);
    color: white;
    box-shadow: 0px -2px 0px 0px #005fcc inset,
      0px 2px 4px 0px rgba(1, 119, 255, 0.2);
    border-color: #0053b2;
  }
}

// circular
.variantCircularElectronHeader {
  background-color: var(--button-electron-header-bg-color);
  color: var(--text-color);
  border-radius: 40px;
  min-width: 40px;

  box-shadow: none;

  &:hover {
    // border: 1px solid rgba(var(--thunk-grey-400), 1);
    background-color: var(--button-electron-header-bg-hover-color);
    // box-shadow: 0px -2px 0px 0px rgba(var(--thunk-grey-200), 1) inset,
    //   0px 2px 4px 0px var(--button-shadow-color);
  }

  &:active {
    // border: 1px solid rgba(var(--thunk-grey-400), 1);
    box-shadow: 0px 2px 4px 0px var(--button-electron-header-inner-shadow-color-active) inset,
  }
}

// button for popper to change header image
.variantHeaderImage {
  border: 2px solid var(--button-header-image-border-color);
  border-radius: 10px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;

  &:hover {
    border: 2px solid var(--button-header-image-border-color-hover);
  }

  &:active {
    border: 2px solid var(--button-header-image-border-color-active);
  }
}

// circular card header
.variantCircularCardHeader {
  background-color: var(--button-card-header-bg-color);
  color: var(--button-card-header-text-color);
  border: 1px solid var(--button-card-header-border-color);
  box-shadow: 0px 0px 0px 1px var(--button-card-header-outline-color);
  border-radius: 40px;
  min-width: 40px;

  &:hover {
    background-color: var(--button-card-header-bg-color-hover);
    color: var(--button-card-header-text-color-hover);
    border: 1px solid var(--button-card-header-border-color-hover);
    box-shadow: 0px 0px 0px 1px var(--button-card-header-outline-color-hover);
  }

  &:active {
    background-color: var(--button-card-header-bg-color-active);
    box-shadow: 0px 2px 4px 0px var(--button-card-header-inner-shadow-color-active) inset, 0px 0px 0px 1px var(--button-card-header-outline-color-active);
    border: 1px solid rgba(0, 0, 0, 0);
    text-decoration: none;
  }
}

.variantCircular {
  color: var(--text-color);
  border-radius: 40px;
  background-color: var(--button-circular-bg-color);
  border: 1px solid var(--button-circular-border-color);

  box-shadow: none;

  &:hover {
    background-color: var(--button-circular-bg-color-hover);
    border: 1px solid var(--button-circular-border-color-hover);
  }

  &:active {
    background-color: var(--button-circular-bg-color-active);
    box-shadow: 0px 2px 4px 0px var(--button-circular-inner-shadow-color-active) inset;
  }
}

@include dark {
  .variantSecondary {
    background-color: rgba(var(--thunk-grey-800), 1);
    color: var(--text-color);

    border-color: #000;
    box-shadow: 0px -2px 0px 0px rgba(var(--thunk-grey-900), 1) inset,
      0px 2px 4px 0px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: #003b80;
      color: white;
      box-shadow: 0px -2px 0px 0px #002a5c inset,
        0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border-color: #001833;
    }
  }
}

// error
.variantError {
  background: rgba(var(--thunk-red-600), 1);
  border-radius: 40px;
  border: 1px solid rgba(var(--thunk-red-800), 1);
  // box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.2) inset,
  //   0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  color: white;

  &:hover {
    background: rgba(var(--thunk-red-700), 1);
  }

  &:active {
    background: rgba(var(--thunk-red-600), 1);
    box-shadow: 0px 4px 4px 0px rgba(var(--thunk-red-700), 1) inset;
  }
}

// pink
.variantPink {
  background: var(--pink-color);
  color: white;

  &:hover {
    background: #ef3f87;
  }
}

// ghost
.variantGhost {
  background-color: transparent;

  &:hover {
    color: var(--primary-color);
  }
}

.disabled:not(.variantGhost) {
  background-color: rgba(var(--thunk-grey-300), 1);
  box-shadow: none;
  border: 1px solid transparent;
  pointer-events: none;
  color: rgba(var(--thunk-white), 1);
}

@include dark {
  .variantGhost {
    // color: var(--text-color);

    &:hover {
      color: var(--primary-color);
    }
  }

  .disabled:not(.variantGhost) {
    background-color: rgba(var(--thunk-grey-700), 1);
    border: 1px solid transparent;
    color: rgba(var(--thunk-grey-900), 1);
  }
}
