.deleteItem {  
  border-top: 1px solid var(--card-toolbar-menu-divider-line-color);
  padding-top: 3px;
  padding-bottom: 3px;

  &:hover {
    color: white;
    background-color: var(--card-toolbar-menu-delete-color) !important;
    box-shadow: 0px -2px 0px 0px var(--card-toolbar-menu-delete-inset-color) inset;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.pinnedLabel {
  white-space: nowrap;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 16px;
}

.openSelectItem {
  margin-bottom: 5px;
}