@import "styles/mixins";

:root {
  --pie-checkbox-border-hover-color: rgba(var(--thunk-blue-500), 1);
  --pie-checkbox-shadow-color: rgba(var(--thunk-blue-300), 1);
}

@include dark {
  --pie-checkbox-border-hover-color: rgba(var(--thunk-blue-900-dark), 1);
  --pie-checkbox-shadow-color: rgba(var(--thunk-blue-400-dark), 1);
}

.wrapper {
  --default-border: var(--text-color);
  --default-background: var(--background-color);
  --default-mark: transparent;
  --default-outline: transparent;
  --effect-background: var(--primary-color);
  --effect-mark: white;
  --effect-outline: var(--primary-color);
  --disabled-border: gray;
  --disabled-effect-background: gray;

  position: relative;
  -webkit-tap-highlight-color: transparent;
  padding-left: 24px;
  //display: inline-block;
  //width: 22px;
  //height: 20px;

  &.withLabel {
    width: 100%;
  }
}

th,
tr {
  .wrapper {
    vertical-align: middle;
  }
}

.input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  top: 2px;
  left: 2px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.svg {
  position: absolute;
  top: calc(50% - 10px);
  width: 18px;
  height: 18px;
  margin-left: -24px;


  &:hover {
    cursor: pointer;


  .border {
    stroke: var(--pie-checkbox-border-hover-color);
  }

  .shadow {
    transform: scale(1.3);
  }

  }

  &:focus {
    outline-width: 0;

    .border {
      stroke: var(--pie-checkbox-border-hover-color);
    }
  
    .shadow {
      transform: scale(1.3);
    }
  }

  &:active {

    .border {
      transform-origin: center;
      stroke-width: 1.75px;
      transform: scale(0.9);
    }

    .mark {
      transform: scale(0.75);
    }

    .shadow {
      transform-origin: center;
      transform: scale(0.85);
    }
  }

}

/* Initial */

.svg {
  .border {
    stroke: var(--default-border);
    fill: var(--default-background);

    stroke-width: 1.5px;

    transition-property: stroke, fill;
    transition-duration: 0.13s;
    transition-timing-function: ease;
  }

  .mark {
    stroke: var(--default-mark);
    stroke-width: 2px;

    transform-origin: center;
    transform: scale(0.9);
    transition: stroke-dashoffset 0.3s ease;
    stroke-dasharray: 24px;
    stroke-dashoffset: 24px;
  }

  .shadow {
    fill: var(--pie-checkbox-shadow-color);
    transform: scale(1);
    transform-origin: center center;
    transition: all 0.2s ease;
  }

  .progress {
    stroke: var(--default-background);
    stroke-width: calc(2px * var(--radius));
    stroke-dasharray: 0 calc(3.14 * 2 * var(--radius));
    transition: all 0.2s ease;
  }
}

/* Checked */

.input:checked + .svg {
  .border {
    stroke: var(--effect-background);
    transition-duration: 0.1s;
    fill: var(--primary-color);
  }

  .mark {
    stroke: var(--effect-mark);
    stroke-dashoffset: 0px;
  }

  .progress {
    stroke: var(--effect-background);
    fill: var(--primary-color);
  }
}

/* Pie */

.input.pie + .svg {
  .border {
    stroke: var(--effect-background);
  }

  .progress {
    stroke: var(--effect-background);
    stroke-dasharray: calc(var(--progress) * 3.14 * 2px * var(--radius))
      calc(3.14 * 2px * var(--radius));
  }
}

/* Focused */

.input:focus-visible + .svg {
  .shadow {
    transform: scale(1.9);
    opacity: 0.3;
  }
}

/* Disabled */

.input:disabled + .svg {
  &:hover {
    cursor: default;
  }

  .border {
    stroke: var(--disabled-border);
  }
}

.input:checked:disabled + .svg {
  .border {
    fill: var(--disabled-effect-background);
  }
}
