@import "styles/mixins";

.tab {
  margin: 6px 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: var(--purple-color);
  font-weight: 500;
  font-size: 14px;

  &.active {
    transition: color 250ms;

    .icon {
      opacity: 0;
    }

    .activeIcon {
      opacity: 1;
    }
  }
}

.iconContainer {
  min-width: 28px;
  height: 28px;
  position: relative;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 250ms;
    color: inherit;
  }
}

.icon {
  opacity: 1;
}

.activeIcon {
  opacity: 0;
}

.text {
  margin-left: 8px;
  margin-top: 3px;
  white-space: nowrap;
}

@include media-xs {
  .text {
    display: none;
  }
}
