@import "styles/mixins";

.container {
  font-size: 14px;
  font-weight: 400;
  color: rgba(var(--thunk-text), 0.5);
}

.count {
  color: rgba(var(--thunk-text), 1);
}

@include media-sm {
  .container {
    display: none;
  }
}
