.toolbarButton {
  color: var(--formatting-menu-icon-color);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  vertical-align: middle;
  width: 28px;
  height: 24px;

  & > svg {
    width: 22px!important;
    height: 22px!important;
  }
}

.toolbarButtonActive {
  color: var(--formatting-menu-icon-color-active);
}

.tooltip {
  font-weight: 400;
}
