[data-tasks-list] {
  .checked {
    text-decoration: line-through;
  }
}

.checked {
  .listItemText {
    filter: opacity(50%);
  }
}

.listItem_todoList,
.listItem_checkList {
  align-items: flex-start;
  transition: filter 300ms;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: 1px;

  .listItemText {
    margin-left: 5px;
  }
}

.listItem_numbered,
.listItem_bulleted {
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: 5px;
}

.listItem_numbered .pointer::before {
  content: var(--pointer-content) ".";
}

.listItem_bulleted .pointer::before {
  content: var(--pointer-content);
  font-size: 10px;
  vertical-align: middle;
}

.dueDateButton {
  svg {
    display: none;
  }
}

.listItem_todoList:hover,
.listItem_checkList:hover {
  .dueDateButton {
    svg {
      display: block !important;
    }
  }
}

.listItemText {
  margin-left: 24px; // keep margin for multiple lines items
  flex: 1;
}

.pointer {
  float: left; // make it out of elements flow to prevent text cursor position before pointer
  min-width: 14px;
  // padding-right: 4px;
  cursor: inherit;
  transform: rotate(0deg);
  margin-top: 0;

  background: none;
  border: none;
  user-select: none;
}

.checkboxPointer {
  float: left;
  line-height: 26px;
  margin-right: 0;
  user-select: none;

  input {
    margin-top: 5px;
  }
}
