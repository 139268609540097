@import "styles/mixins";

.cardToolbarButton {
  cursor: pointer;
  border-radius: 30px;


  svg {
    margin-top: 0px;
  }
}

.default {
  color: var(--primary-color);
  background-color: transparent;

  &:disabled {
    color: var(--button-disabled-color);
  }
}

.filled {
  background: var(--primary-color);
  color: var(--button-color);

  &:disabled {
    color: var(--button-disabled-color);
    background: var(--button-disabled-background-color);
    border: 1px solid transparent;
  }
}
