@import "styles/mixins";

.logoLink {
  user-select: none;
  width: 160px;
  height: 59px;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 160px;
  }
}

.electronLogo {
  cursor: default;
}

.logoNigelDark {
  display: none;
}

.logoJulianDark {
  display: none;
}

.logoJulian {
  display: block;
}

.logoNigel {
  display: none;
}

.logoLink:hover {
  .logoNigel {
    display: block;
  }

  .logoJulian {
    display: none;
  }
}

[theme="dark"] {
  .logoNigel {
    display: none;
  }

  .logoJulian {
    display: none;
  }

  .logoNigelDark {
    display: none;
  }

  .logoJulianDark {
    display: block;
  }

  .logoLink:hover {
    .logoNigelDark {
      display: block;
    }
  
    .logoJulianDark {
      display: none;
    }
  }
}