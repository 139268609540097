@import "styles/mixins";

.container {
  --t-bg-color: var(--background-color);
  --t-page-bg-color: var(--page-background-color);
  --t-bg-secondary-color: rgba(var(--thunk-grey-100), 1);
  --t-border-color: rgba(var(--thunk-grey-300), 1);
  --t-header-color: white;
  --t-filter-color: rgba(var(--thunk-grey-100), 1);
  --t-header-content-height: 50px;
  --t-header-pt-local: 10px;
  --t-header-pt: calc(var(--t-header-pt-local) + var(--app-global-padding-top));
  --t-border-radius: 10px;
  --t-border-width: 1px;
  --t-border: var(--t-border-width) solid var(--t-border-color);
  --t-filter-width: 260px;
}

@include dark {
  .container {
    --t-bg-secondary-color: rgba(0,0,0, 0.2);
    --t-border-color: #000000;
    --t-header-color: #12171f;
    --t-filter-color: rgba(var(--thunk-grey-1000), 1);
  }
}

.container {
  border-radius: var(--border-radius);
  max-width: 100%;
  position: relative;
}

.containerHeader {
  z-index: 1;
  position: sticky;
  top: var(--app-global-padding-top);
  padding-top: var(--t-header-pt-local);

  background: var(--t-page-bg-color);
}

.containerHeaderContent {
  border: var(--t-border);
  border-top-left-radius: var(--t-border-radius);
  border-top-right-radius: var(--t-border-radius);
  background-color: var(--t-header-color);
  min-height: var(--t-header-content-height);
}

.main {
  display: flex;
  align-items: stretch;
  border-left: var(--t-border);
  border-right: var(--t-border);
  border-bottom: var(--t-border);
  background-color: var(--t-bg-color);
}

.aside {
  min-width: var(--t-filter-width);
  background: var(--t-filter-color);
  border-right: var(--t-border);
}

.filterHeader {
  z-index: 1;
  position: sticky;
  top: var(--t-header-pt);
  height: calc(var(--t-header-content-height) + var(--t-border-width));
  margin-top: calc(
    0px - var(--t-header-content-height) - var(--t-border-width)
  );
  line-height: var(--t-header-content-height);
  font-weight: 600;
  font-size: 14px;
  padding: 2px 15px;
  display: flex;
  justify-content: space-between;
}

.filter {
  position: sticky;
  top: calc(
    var(--t-header-content-height) + var(--t-header-pt) + var(--t-border-width) +
      var(--t-border-width)
  );
  //height: calc(100vh - var(--t-header-content-height) - 10px);
  //background: var(--t-filter-color);
  overflow: auto;
}

.table {
  width: 100%;
  margin-top: calc(0px - var(--t-header-content-height) - 3px);
  padding: 6px 0px;
}

.tableHeaderCell {
  z-index: 1;
  position: sticky;
  top: calc(var(--t-header-pt) + 3px);
  text-align: start;
  height: var(--t-header-content-height);
}

.table {
  font-size: 15px;
  line-height: 16px;
  background-color: var(--t-bg-color);
  //border: 1px solid var(--border-color);
  border-collapse: separate;
}

.tableCell {
  text-align: left;
  padding: 6px 12px;
  min-width: 106px;
  line-height: 24px;
}

.tableHeaderCell {
  cursor: pointer;
  user-select: none;
  padding: 16px 12px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
}

.tableCheckboxCell,
.tableHeaderCheckboxCell {
  padding-top: 3px;
  min-width: auto;

  input {
    cursor: pointer;
  }
}

.tableHeaderCheckboxCell {
  padding-top: 6px;
}

.tableBody {
  .tableRow:nth-child(2n) {
    .tableCell {
      background-color: var(--t-bg-secondary-color);
    }
  }
}

.asc,
.desc {
  fill: var(--text-color);
  //position: relative;

  &:after {
    position: absolute;
    content: "";
    background-color: var(--text-color);
    mask: url("./ArrowRightIcon.svg") no-repeat 50% 50%;
    margin-left: 10px;
    padding-top: 14px;
    width: 10px;
    height: 10px;
    transform: rotate(-90deg);
  }
}

.desc {
  &:after {
    transform: rotate(90deg);
  }
}

.noResults {
  font-style: italic;
}

@include media-sm {
  .containerHeader {
    display: none;
  }

  .tableHead {
    display: none;
  }

  .main {
    border: none;
  }

  .table {
    margin-top: 0;
    padding: 0;
  }

  .aside {
    display: none;
  }
}
