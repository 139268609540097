@import "styles/mixins";

.container {
  width: 100%;
  padding-top: 200px;
  padding-bottom: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-app-region: drag;
}

@include media-sm {
  .container {
    padding-top: 80px;
  }
}
