@import "styles/mixins";

.popper {
  --popper-inset-shadow-height: -2px;

  &[data-popper-placement^="top"] {
    --popper-inset-shadow-height: 0;
  }

  &.colorDefault {
    --popper-bg-clr: var(--thunk-drowdown-menu-bg-color);
    --popper-br-clr: rgba(0,0,0, 0.1);
    --popper-box-shadow: 0px var(--popper-inset-shadow-height) 0px 0px var(--thunk-inset-shadow-color)
        inset,
      0px 2px 4px 0px rgba(27, 38, 51, 0.1);
  }

  &.colorPrimary {
    --popper-bg-clr: var(--primary-color);
    --popper-br-clr: #0053b2;
    --popper-box-shadow: 0px var(--popper-inset-shadow-height) 0px 0px #005fcc
        inset,
      0px 2px 4px 0px rgba(1, 119, 255, 0.2);
  }
}

@include dark {
  .popper:not(.onlyLight) {
    &.colorDefault {
      --popper-bg-clr: var(--thunk-drowdown-menu-bg-color);
      --popper-br-clr: black;
      --popper-box-shadow: 0px var(--popper-inset-shadow-height) 0px 0px
      var(--thunk-inset-shadow-color) inset,
        0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }

    &.colorPrimary {
      --popper-bg-clr: #003b80;
      --popper-br-clr: #000000;
      --popper-box-shadow: 0px var(--popper-inset-shadow-height) 0px 0px #002a5c
          inset,
        0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.popper {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: var(--hover-popper-z-index);
  opacity: 0;
  visibility: hidden;

  transition: visibility 300ms, opacity 300ms, transform 200ms;

  background-color: var(--popper-bg-clr);
  border: 1px solid var(--popper-br-clr);
  border-radius: 12px;

  box-shadow: var(--popper-box-shadow);

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &.fromPopper {
    z-index: calc(var(--hover-popper-z-index) + 1);
  }
}

.divider {
  height: 1px;
  background-color: var(--popper-br-clr);
  border: none;
  margin: 0;
}

.popperItem {
  padding: 10px;
  display: flex;
  color: var(--text-color);
  font-weight: 500;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  svg {
    margin-right: 10px;
  }
}
