@import "styles/mixins";

:root {
  --dimmed-breadcrumb-text-color: rgba(var(--thunk-grey-500), 1);
  --breadcrumb-link-hover-color: var(--primary-color);
}

@include dark {
  --dimmed-breadcrumb-text-color: rgba(var(--thunk-grey-700), 1);
  --breadcrumb-link-hover-color: rgba(var(--thunk-blue-700-dark), 1);
}

.root {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  max-height: 26px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: var(--dimmed-breadcrumb-text-color);
}

.itemWrapper {
  display: flex;
  color: var(--dimmed-breadcrumb-text-color);
}

.item {
  max-width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--dimmed-breadcrumb-text-color);

  a {
    color: var(--dimmed-breadcrumb-text-color);

    &:hover {
      opacity: 1;
      color: var(--breadcrumb-link-hover-color);
      text-decoration: none;
    }
  }
}

.end {
  max-width: 10rem;
  color: var(--text-color);
  opacity: 1;
}

.carat {
  color: var(--text-color);
  margin: 0 8px;
  color: var(--dimmed-breadcrumb-text-color);
}

@include media-sm {
  .item, .end {
    max-width: 5rem;
  }
}