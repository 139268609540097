.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}

.image {
  margin-bottom: 2rem;
}

.title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.content {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}
