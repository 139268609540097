.textarea {
  width: 100%;
  padding: 0.5rem 1rem;

  border-radius: 6px;
  font-size: 16px;
  line-height: 26px;
  outline: none;
  resize: none;

  color: var(--text-color);
  background: var(--input-background-color);
  border: 1px solid var(--input-border-color);

  &:focus {
    border: 1px solid var(--validation-color);
    background: var(--input-focus-background-color);
  }

  &::placeholder {
    color: var(--light-grey-color);
  }
}
