.input {
  background-color: transparent;
  color: #ffffff;
  height: 34px;
  width: 100%;
  font-size: 14px;
  margin-left: 15px;
  font-weight: 500;
  line-height: 28px;
  flex: 1;

  &::placeholder {
    font-weight: 400;
    color: #ffffff;
  }
}

.inputContainer {
  background-color: var(--pink-color);
  display: flex;
  width: 300px;
  border-radius: 9px;
}

.closeIconButton {
  width: 34px;
  height: 34px;
  background-color: #ff81b0;
  border-radius: 0 9px 9px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }
}
