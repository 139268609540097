// we need to override tippy classes globally
:global {
  .filtersTooltip {
    &.tippy-box {
      background-color: var(--purple-color);
      border-radius: 23px;

      .tippy-content {
        padding: 10px 20px 10px 10px;
      }

      .tippy-arrow {
        color: var(--purple-color);
      }
    }
  }
}
