@import "palette-scss";

@function hextorgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  --thunk-white: #{hextorgb($thunk-white)};
  --thunk-black: #{hextorgb($thunk-black)};

  --thunk-grey-100: #{hextorgb($thunk-grey-100)};
  --thunk-grey-200: #{hextorgb($thunk-grey-200)};
  --thunk-grey-300: #{hextorgb($thunk-grey-300)};
  --thunk-grey-400: #{hextorgb($thunk-grey-400)};
  --thunk-grey-500: #{hextorgb($thunk-grey-500)};
  --thunk-grey-600: #{hextorgb($thunk-grey-600)};
  --thunk-grey-700: #{hextorgb($thunk-grey-700)};
  --thunk-grey-800: #{hextorgb($thunk-grey-800)};
  --thunk-grey-900: #{hextorgb($thunk-grey-900)};
  --thunk-grey-1000: #{hextorgb($thunk-grey-1000)};

  --thunk-green-200: #{hextorgb($thunk-green-200)};
  --thunk-green-300: #{hextorgb($thunk-green-300)};
  --thunk-green-400: #{hextorgb($thunk-green-400)};
  --thunk-green-500: #{hextorgb($thunk-green-500)};
  --thunk-green-600: #{hextorgb($thunk-green-600)};
  --thunk-green-700: #{hextorgb($thunk-green-700)};
  --thunk-green-800: #{hextorgb($thunk-green-800)};
  --thunk-green-900: #{hextorgb($thunk-green-900)};
  --thunk-green-1000: #{hextorgb($thunk-green-1000)};

  --thunk-light-blue-200: #{hextorgb($thunk-light-blue-200)};
  --thunk-light-blue-300: #{hextorgb($thunk-light-blue-300)};
  --thunk-light-blue-400: #{hextorgb($thunk-light-blue-400)};
  --thunk-light-blue-500: #{hextorgb($thunk-light-blue-500)};
  --thunk-light-blue-600: #{hextorgb($thunk-light-blue-600)};
  --thunk-light-blue-700: #{hextorgb($thunk-light-blue-700)};
  --thunk-light-blue-800: #{hextorgb($thunk-light-blue-800)};
  --thunk-light-blue-900: #{hextorgb($thunk-light-blue-900)};
  --thunk-light-blue-1000: #{hextorgb($thunk-light-blue-1000)};

  --thunk-blue-200: #{hextorgb($thunk-blue-200)};
  --thunk-blue-300: #{hextorgb($thunk-blue-300)};
  --thunk-blue-400: #{hextorgb($thunk-blue-400)};
  --thunk-blue-500: #{hextorgb($thunk-blue-500)};
  --thunk-blue-600: #{hextorgb($thunk-blue-600)};
  --thunk-blue-700: #{hextorgb($thunk-blue-700)};
  --thunk-blue-800: #{hextorgb($thunk-blue-800)};
  --thunk-blue-900: #{hextorgb($thunk-blue-900)};
  --thunk-blue-1000: #{hextorgb($thunk-blue-1000)};

  --thunk-purple-200: #{hextorgb($thunk-purple-200)};
  --thunk-purple-300: #{hextorgb($thunk-purple-300)};
  --thunk-purple-400: #{hextorgb($thunk-purple-400)};
  --thunk-purple-500: #{hextorgb($thunk-purple-500)};
  --thunk-purple-600: #{hextorgb($thunk-purple-600)};
  --thunk-purple-700: #{hextorgb($thunk-purple-700)};
  --thunk-purple-800: #{hextorgb($thunk-purple-800)};
  --thunk-purple-900: #{hextorgb($thunk-purple-900)};
  --thunk-purple-1000: #{hextorgb($thunk-purple-1000)};

  --thunk-pink-200: #{hextorgb($thunk-pink-200)};
  --thunk-pink-300: #{hextorgb($thunk-pink-300)};
  --thunk-pink-400: #{hextorgb($thunk-pink-400)};
  --thunk-pink-500: #{hextorgb($thunk-pink-500)};
  --thunk-pink-600: #{hextorgb($thunk-pink-600)};
  --thunk-pink-700: #{hextorgb($thunk-pink-700)};
  --thunk-pink-800: #{hextorgb($thunk-pink-800)};
  --thunk-pink-900: #{hextorgb($thunk-pink-900)};
  --thunk-pink-1000: #{hextorgb($thunk-pink-1000)};

  --thunk-red-200: #{hextorgb($thunk-red-200)};
  --thunk-red-300: #{hextorgb($thunk-red-300)};
  --thunk-red-400: #{hextorgb($thunk-red-400)};
  --thunk-red-500: #{hextorgb($thunk-red-500)};
  --thunk-red-600: #{hextorgb($thunk-red-600)};
  --thunk-red-700: #{hextorgb($thunk-red-700)};
  --thunk-red-800: #{hextorgb($thunk-red-800)};
  --thunk-red-900: #{hextorgb($thunk-red-900)};
  --thunk-red-1000: #{hextorgb($thunk-red-1000)};

  --thunk-orange-200: #{hextorgb($thunk-orange-200)};
  --thunk-orange-300: #{hextorgb($thunk-orange-300)};
  --thunk-orange-400: #{hextorgb($thunk-orange-400)};
  --thunk-orange-500: #{hextorgb($thunk-orange-500)};
  --thunk-orange-600: #{hextorgb($thunk-orange-600)};
  --thunk-orange-700: #{hextorgb($thunk-orange-700)};
  --thunk-orange-800: #{hextorgb($thunk-orange-800)};
  --thunk-orange-900: #{hextorgb($thunk-orange-900)};
  --thunk-orange-1000: #{hextorgb($thunk-orange-1000)};

  --thunk-yellow-200: #{hextorgb($thunk-yellow-200)};
  --thunk-yellow-300: #{hextorgb($thunk-yellow-300)};
  --thunk-yellow-400: #{hextorgb($thunk-yellow-400)};
  --thunk-yellow-500: #{hextorgb($thunk-yellow-500)};
  --thunk-yellow-600: #{hextorgb($thunk-yellow-600)};
  --thunk-yellow-700: #{hextorgb($thunk-yellow-700)};
  --thunk-yellow-800: #{hextorgb($thunk-yellow-800)};
  --thunk-yellow-900: #{hextorgb($thunk-yellow-900)};
  --thunk-yellow-1000: #{hextorgb($thunk-yellow-1000)};

  //DARK MODE COLORS
  --thunk-green-200-dark: #{hextorgb($thunk-green-200-dark)};
  --thunk-green-300-dark: #{hextorgb($thunk-green-300-dark)};
  --thunk-green-400-dark: #{hextorgb($thunk-green-400-dark)};
  --thunk-green-500-dark: #{hextorgb($thunk-green-500-dark)};
  --thunk-green-600-dark: #{hextorgb($thunk-green-600-dark)};
  --thunk-green-700-dark: #{hextorgb($thunk-green-700-dark)};
  --thunk-green-800-dark: #{hextorgb($thunk-green-800-dark)};
  --thunk-green-900-dark: #{hextorgb($thunk-green-900-dark)};
  --thunk-green-1000-dark: #{hextorgb($thunk-green-1000-dark)};

  --thunk-light-blue-200-dark: #{hextorgb($thunk-light-blue-200-dark)};
  --thunk-light-blue-300-dark: #{hextorgb($thunk-light-blue-300-dark)};
  --thunk-light-blue-400-dark: #{hextorgb($thunk-light-blue-400-dark)};
  --thunk-light-blue-500-dark: #{hextorgb($thunk-light-blue-500-dark)};
  --thunk-light-blue-600-dark: #{hextorgb($thunk-light-blue-600-dark)};
  --thunk-light-blue-700-dark: #{hextorgb($thunk-light-blue-700-dark)};
  --thunk-light-blue-800-dark: #{hextorgb($thunk-light-blue-800-dark)};
  --thunk-light-blue-900-dark: #{hextorgb($thunk-light-blue-900-dark)};
  --thunk-light-blue-1000-dark: #{hextorgb($thunk-light-blue-1000-dark)};

  --thunk-blue-200-dark: #{hextorgb($thunk-blue-200-dark)};
  --thunk-blue-300-dark: #{hextorgb($thunk-blue-300-dark)};
  --thunk-blue-400-dark: #{hextorgb($thunk-blue-400-dark)};
  --thunk-blue-500-dark: #{hextorgb($thunk-blue-500-dark)};
  --thunk-blue-600-dark: #{hextorgb($thunk-blue-600-dark)};
  --thunk-blue-700-dark: #{hextorgb($thunk-blue-700-dark)};
  --thunk-blue-800-dark: #{hextorgb($thunk-blue-800-dark)};
  --thunk-blue-900-dark: #{hextorgb($thunk-blue-900-dark)};
  --thunk-blue-1000-dark: #{hextorgb($thunk-blue-1000-dark)};

  --thunk-purple-200-dark: #{hextorgb($thunk-purple-200-dark)};
  --thunk-purple-300-dark: #{hextorgb($thunk-purple-300-dark)};
  --thunk-purple-400-dark: #{hextorgb($thunk-purple-400-dark)};
  --thunk-purple-500-dark: #{hextorgb($thunk-purple-500-dark)};
  --thunk-purple-600-dark: #{hextorgb($thunk-purple-600-dark)};
  --thunk-purple-700-dark: #{hextorgb($thunk-purple-700-dark)};
  --thunk-purple-800-dark: #{hextorgb($thunk-purple-800-dark)};
  --thunk-purple-900-dark: #{hextorgb($thunk-purple-900-dark)};
  --thunk-purple-1000-dark: #{hextorgb($thunk-purple-1000-dark)};

  --thunk-pink-200-dark: #{hextorgb($thunk-pink-200-dark)};
  --thunk-pink-300-dark: #{hextorgb($thunk-pink-300-dark)};
  --thunk-pink-400-dark: #{hextorgb($thunk-pink-400-dark)};
  --thunk-pink-500-dark: #{hextorgb($thunk-pink-500-dark)};
  --thunk-pink-600-dark: #{hextorgb($thunk-pink-600-dark)};
  --thunk-pink-700-dark: #{hextorgb($thunk-pink-700-dark)};
  --thunk-pink-800-dark: #{hextorgb($thunk-pink-800-dark)};
  --thunk-pink-900-dark: #{hextorgb($thunk-pink-900-dark)};
  --thunk-pink-1000-dark: #{hextorgb($thunk-pink-1000-dark)};

  --thunk-red-200-dark: #{hextorgb($thunk-red-200-dark)};
  --thunk-red-300-dark: #{hextorgb($thunk-red-300-dark)};
  --thunk-red-400-dark: #{hextorgb($thunk-red-400-dark)};
  --thunk-red-500-dark: #{hextorgb($thunk-red-500-dark)};
  --thunk-red-600-dark: #{hextorgb($thunk-red-600-dark)};
  --thunk-red-700-dark: #{hextorgb($thunk-red-700-dark)};
  --thunk-red-800-dark: #{hextorgb($thunk-red-800-dark)};
  --thunk-red-900-dark: #{hextorgb($thunk-red-900-dark)};
  --thunk-red-1000-dark: #{hextorgb($thunk-red-1000-dark)};

  --thunk-orange-200-dark: #{hextorgb($thunk-orange-200-dark)};
  --thunk-orange-300-dark: #{hextorgb($thunk-orange-300-dark)};
  --thunk-orange-400-dark: #{hextorgb($thunk-orange-400-dark)};
  --thunk-orange-500-dark: #{hextorgb($thunk-orange-500-dark)};
  --thunk-orange-600-dark: #{hextorgb($thunk-orange-600-dark)};
  --thunk-orange-700-dark: #{hextorgb($thunk-orange-700-dark)};
  --thunk-orange-800-dark: #{hextorgb($thunk-orange-800-dark)};
  --thunk-orange-900-dark: #{hextorgb($thunk-orange-900-dark)};
  --thunk-orange-1000-dark: #{hextorgb($thunk-orange-1000-dark)};

  --thunk-yellow-200-dark: #{hextorgb($thunk-yellow-200-dark)};
  --thunk-yellow-300-dark: #{hextorgb($thunk-yellow-300-dark)};
  --thunk-yellow-400-dark: #{hextorgb($thunk-yellow-400-dark)};
  --thunk-yellow-500-dark: #{hextorgb($thunk-yellow-500-dark)};
  --thunk-yellow-600-dark: #{hextorgb($thunk-yellow-600-dark)};
  --thunk-yellow-700-dark: #{hextorgb($thunk-yellow-700-dark)};
  --thunk-yellow-800-dark: #{hextorgb($thunk-yellow-800-dark)};
  --thunk-yellow-900-dark: #{hextorgb($thunk-yellow-900-dark)};
  --thunk-yellow-1000-dark: #{hextorgb($thunk-yellow-1000-dark)};
}
