.container {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--recent-menu-z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.content {
  background-color: var(--background-color);
  border: 1px solid var(--recent-menu-border-color);
  box-shadow: 0 6px 22px var(--recent-menu-shadow-color);
  border-radius: 10px;
  padding: 10px;
  width: 400px;
  max-width: 400px;
  min-height: 300px;
}

.recentTitle {
  font-size: 14px;
  color: var(--light-text-color);
  font-weight: 500;
  padding: 4px 10px;
}

.recentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  font-size: 15px;
  border-radius: 6px;
  color: var(--light-text-color);
  font-weight: 500;

  .itemTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.active {
    color: var(--primary-color);
    background-color: var(--page-background-color);
  }

  &:hover {
    color: var(--primary-color);

    .removeIcon {
      display: block;
    }
  }
}
