@import "styles/mixins";

:root {
  --tags-divider-line-color: rgba(var(--thunk-grey-200), 1);
}


@include dark {
  --tags-divider-line-color: rgba(var(--thunk-grey-900), 1);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;

  > * {
    margin: 3px;
  }
}

.divider {
  border: none;
  height: 1px;
  border-radius: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
  background: var(--tags-divider-line-color);
}
