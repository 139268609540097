@import "styles/mixins";

.sidebar {
  --sidebar-collapsed-width: 0px;
  --sidebar-expanded-width: 248px;

  position: fixed;
  top: var(--app-global-padding-top);
  bottom: 0;
  left: 0;
  width: var(--sidebar-expanded-width);
  overflow: hidden;
  z-index: var(--sidebar-z-index);

  transition: width 200ms;

  &.collapsed {
    width: var(--sidebar-collapsed-width);

    & > .onlyExpanded {
      display: none;
    }

    .logo {
      opacity: 0;
      transform: translate3d(20px, 0, 0);
    }
  }
}

.sidebarWrapper {
  position: relative;
  overflow: scroll;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-top: 10px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebarContent {
  padding-bottom: 138px;
}

.sidebarTop {
  margin-top: 10px;
}

.logo {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  opacity: 1;
  transform: translate3d(48px, 0, 0);
  transition: transform 200ms, opacity 180ms, height 200ms;
}

.toggle {
  z-index: var(--header-z-index);
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  height: 70px;
  display: flex;
  align-items: center;
}

.actions {
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  left: 0;
  width: calc(100% - 4px);
  background-color: rgba(var(--page-background-color-rgb), 1);
}

.bottomActionsButton {
  width: calc(100% - 20px);
  margin-left: 20px;
  margin-top: 10px;
  gap: 8px;
  padding-left: 7px;
  text-align: left;
  justify-content: center;
}

.helpButton {
  justify-content: flex-start;
  padding: 0 10px;
  width: 100%;
  gap: 9px;
  background-color: none;
  background: none;

  span {
    display: none;
  }

  &:hover {
    background-color: none;
    background: none;
    color: var(--primary-color);
  }

  &:active {
    background-color: none;
    background: none;
    box-shadow: none;
    color: var(--primary-color);
  }
}

.accountButton {
  justify-content: flex-start;
  padding: 0 9px;
  width: 100%;
  gap: 9px;
  background-color: none;
  background: none;

  span {
    display: none;
  }

  &:hover {
    background-color: none;
    background: none;
    color: var(--primary-color);
  }

  &:active {
    background-color: none;
    background: none;
    box-shadow: none;
    color: var(--primary-color);
  }
}

// .newNoteButton {
//   justify-content: cen;
// }

@include media-sm {
  .sidebar {
    --sidebar-collapsed-width: 0;
    // padding-top: 64px;
  }

  .sidebarTop {
    display: none;
  }

  .sidebarContent {
    padding-top: 10px;
  }
}
