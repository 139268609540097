@import "styles/mixins";

.container {
  flex: 1;
  display: flex;
  padding-bottom: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form {
    width: 100%;
    margin-top: 10px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }

  .hint {
    color: var(--light-text-color);
    text-align: center;
    font-size: 15px;
  }

  .buttons {
    display: flex;
    flex-wrap: nowrap;

    & > * {
      margin-left: 8px;
    }
  }
}

.image {
  width: 100%;
  max-width: 300px;
  margin: 30px 10px 40px 10px;
  min-height: 179px;
}

@include media-sm {
  .actions {
    flex-direction: column;
  }

  .hint {
    margin-bottom: 9px;
  }
}
