@import "styles/mixins";

:root {
  --modal-outline-color: rgba(0, 0, 0, 0.3);
}

@include dark {
}

$modal-width: 500px;

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-overlay-background-color);

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 300ms ease-in-out;

  z-index: var(--modal-z-index);
}

:global {
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

.modalContent {
  position: relative;
  margin: 15px;
  overflow: visible;
  border-radius: 30px;
  outline: none;
  transform: translate3d(0, -3vh, 0);
  width: $modal-width;
  max-height: calc(100vh - 100px);
  background: var(--thunk-drowdown-menu-bg-color);
  display: flex;
  flex-direction: column;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2), 
              0px 0px 0px 1px rgba(0, 0, 0, 1),
              inset 0px -2px 0px var(--card-toolbar-menu-inset-color);
}

.modalScroll {
  padding: 30px 20px 20px 34px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
  color: var(--text-color);
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  & > * {
    display: flex;
  }
}

.mascot {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -35px;
  left: calc(50% - 30px);
  user-select: none;
}

