@import "styles/mixins";

:root {
  --card-header-default-height: 170px;
  --right-left-actions-width: 250px;
}

.container {
  height: 60px;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: var(--card-title-image-z-index);
}

.inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: var(--card-header-default-height);
  overflow: hidden;
  z-index: var(--card-title-image-z-index);

  background-color: rgba(var(--thunk-grey-300), 0);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: height 150ms ease;
}

.backlinksCardContainer {
  position: relative;
  border-bottom: 1px solid var(--card-border-color);
}

.backlinksCardInner {
  height: 60px !important;
}


.miniCardContainer {
}

.miniCardInner {
  height: 60px !important;
  background-color: var(--background-color);
  border: 1px solid var(--card-border-color);
}

.todayImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  // margin-left: -10px;
  // margin-top: -10px;
}

.leftActions{
  display: flex;
  flex-grow: 0;
  width: var(--right-left-actions-width); /// manually setting this to match the width of the rightActions at full width
  flex-shrink: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.rightActions {
  display: flex;
  flex-grow: 0;
  flex-direction: row-reverse;
  width: var(--right-left-actions-width); /// manually setting this to match the width of the leftActions at full width
  flex-shrink: 0;
  margin-right: 10px;
  margin-top: 10px;
}

.centerActions {
  z-index: 500;
  max-width: calc(100% - 20px - 2 * var(--right-left-actions-width));
  height: 40px;
  margin-top: 10px;
  border-radius: 30px;

  &.visible {
    display: flex;
  }

  &.invisible {
    display: none;
  }
}

.topTitleButton {
  max-width: 100%;
}

.titleText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hasNoTitle {
  max-width: 40px;
  padding: 0;
}

.toTopIcon {
  display: none;

  &.visible {
    display: inline;
  }
}

.changeHeaderImageButton {
  display: none;
  position: absolute;
  top: 190px;
  left: calc(50% + 70px);
  margin-left: -160px;
  margin-top: auto;
  margin-bottom: 5px;
  text-align: center;
  z-index: 500;
  font-size: 14px;
  border-radius: 30px;

  Button {
    height: 34px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &.hover {
    border: 1px solid var(--button-card-header-border-hover-color);
    background-color: var(--button-card-header-bg-hover-color);
  }

  &.visible {
    display: flex;
  }
}

[data-header-md] {
  --right-left-actions-width: 50px;
}

[data-header-xs] {
  .changeHeaderImageButton {
    svg {
      width: 16px;
      display: none;
    }
    display: flex;
    left: auto;
    right: 10px;
    top: 196px;

    Button {
      height: 28px;
      padding-left: 14px;
      padding-right: 14px;
      font-size: 12px;
    }
  }
}

@include media-sm {
  .inner {
    border-radius: 0;
  }
}
