@font-face {
  font-family: "AtlasGrotesk";
  font-style: normal;
  font-weight: 100;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-Thin.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: italic;
  font-weight: 100;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-ThinItalic.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: normal;
  font-weight: 300;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-Light.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: italic;
  font-weight: 300;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-LightItalic.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: normal;
  font-weight: 400;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-Regular.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: italic;
  font-weight: 400;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-RegularItalic.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: normal;
  font-weight: 500;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-Medium.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: italic;
  font-weight: 500;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-MediumItalic.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: normal;
  font-weight: 700;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-Bold.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: italic;
  font-weight: 700;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-BoldItalic.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: normal;
  font-weight: 900;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-Black.otf) format("opentype");
}

@font-face {
  font-family: "AtlasGrotesk";
  font-style: italic;
  font-weight: 900;
  src: local("AtlasGrotesk"),
    url(../fonts/AtlasGrotesk-BlackItalic.otf) format("opentype");
}

@font-face {
  font-family: "BougeBold";
  src: local("BougeBold"), url(../fonts/bougebold.ttf) format("TrueType");
}
