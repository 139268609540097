@import "variables.module";

$componentMenuTransition: opacity $componentMenuTransitionDuration
$componentMenuTransitionDelay,
transform $componentMenuTransitionDuration $componentMenuTransitionDelay;

/* forward */
.forward {
  .componentMenuTransition-enter {
    z-index: 1;
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }

  .componentMenuTransition-enter-active {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
    transition: $componentMenuTransition;
  }

  .componentMenuTransition-exit {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }

  .componentMenuTransition-exit-active {
    opacity: 1;
    transform: translate3d(-25%, 0, 0);
    transition: $componentMenuTransition;
  }
}

/* backward */
.backward {
  .componentMenuTransition-enter {
    z-index: 0;
    opacity: 1;
    transform: translate3d(-25%, 0, 0);
  }

  .componentMenuTransition-enter-active {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
    transition: $componentMenuTransition;
  }

  .componentMenuTransition-exit {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }

  .componentMenuTransition-exit-active {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
    transition: $componentMenuTransition;
  }
}
