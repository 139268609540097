@import "styles/mixins";

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 3px;
  border-radius: 5px;
  border-color: #eeeeee;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.active {
  border-color: var(--blue-color);
}

.accepted {
  border-color: var(--green-color);
}

.rejected {
  border-color: var(--red-color);
}

.previewContainer {
  position: relative;
}

.urlToggle {
  padding: 0;
  height: auto;
  justify-content: flex-start;
  margin-top: 0.7rem;
  font-size: 0.8rem;
  color: var(--light-grey-color);
}

.remove {
  position: absolute;
  top: 7px;
  right: 7px;

  svg {
    path {
      fill: #dedede;
    }
  }

  &:hover {
    cursor: pointer;
  }

  @include media-xs {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.preview {
  width: 100%;
}

.fileRejectionMsg {
  width: 100%;
  margin-top: 15px;
  padding: 5px 10px;
  background: lighten(#ff5555, 15%);
  color: white;
  font-weight: 600;
  border-radius: 5px;
}
