.popperContainer {
  width: 226px;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--card-toolbar-menu-background-color);
  box-shadow: 0 2px 4px var(--card-toolbar-menu-shadow-color), 0px -2px 0px 0px var(--card-toolbar-menu-inset-color) inset, 0px 0px 0px 1px var(--card-toolbar-menu-border-color);
}

.themePickerListItem {
  padding: 0;
  width: 100%;
  color: var(--text-color);
  font-size: 14px;
  svg {
    margin-bottom: -5px;
    margin-right: 5px;
  }

  &:hover {
    background-color: var(--thunk-dropdown-menu-item-hover-color);
    cursor: pointer;
  }
}

.themePickerIconAndLabel {
  padding: 3px 8px;
  width: 100%;
}