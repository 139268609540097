@import "styles/mixins";

// hide calendar controller by default
.container {
  display: none;
}

// show the calendar controller if it's the daily note
body[daily-note] {
  .container {
    display: flex;
  }
}

.calendarPopper {
  width: 290px;
  min-height: 356px;
  border-radius: 25px !important;
  padding: 20px 20px;
  z-index: var(--calendar-z-index);
}

.calendarButtonGroup {
  min-width: 132px;
  flex-shrink: 0;
  height: 40px;
  border-radius: 30px;
  margin-left: 8px;
  // box-shadow: 0px 0px 0px 1px var(--button-card-header-outline-color-hover);
}

.leftCalendarControlButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 34px;
  min-width: 34px;
  border-right: none;
  box-shadow: none;

  svg {
    margin-left: 2px !important;
    margin-right: -2px !important;
  }

  &:hover {
    border-right: none;
    box-shadow: none;
  }

  &:active {
    border-right: none;
    box-shadow: none;
  }
}

.rightCalendarControlButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0 !important;
  width: 34px;
  min-width: 34px;
  border-left: none;
  box-shadow: none;

  svg {
    margin-left: -2px !important;
    margin-right: 2px !important;
  }

  &:hover {
    border-left: none;
    box-shadow: none;
  }

  &:active {
    border-left: none;
    box-shadow: none;
  }
}

@include dark {
  .react-calendar__tile {
    border: 4px solid #003b80 !important;
  }
}

.dateButton {
  visibility: visible;
  min-width: 100px;
  border-radius: 0;
  border-right: none;
  border-left: none;
  margin-left: 0 !important;
  box-shadow: none;

  &:hover {
    border-left: none;
    border-right: none;
    box-shadow: none;
  }

  &:active {
    border-left: none;
    border-right: none;
    box-shadow: none;
  }
}

.dateText {
  display: block;
}

.dateTextMini {
  display: none;
}

[data-header-sm] {
}

@include media-sm{
  .dateButton {
    min-width: 60px;
  }

  .dateText {
    display: none;
  }

  .dateTextMini {
    display: block;
  }
}

@include media-xxs{
  .dateButton {
    min-width: 50px;
  }
}

