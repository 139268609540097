.input {
  width: 100%;
  padding: 0.5rem 1rem;

  border-radius: 6px;
  font-size: 1rem;
  outline: none;

  color: var(--text-color);
  background: var(--input-background-color);
  border: 1px solid var(--input-border-color);


  &.isMonospace {
    font-family: monospace;
  }

  &:focus {
    border: 1px solid var(--input-focus-border-color);
    background: var(--input-focus-background-color);
  }

  &::placeholder {
    color: var(--placeholder-text-color);
  }
}

.inputWithError {
  border: 1px solid var(--validation-color);
}

.inputError {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: var(--validation-color);
  line-height: 16px;
}

.feedback {
  position: absolute;
  right: 0.7rem;
  bottom: 0;
  height: 48px;

  color: var(--text-color);
  font-size: 0.9rem;

  display: flex;
  align-items: center;
}

.feedbackIcon {
  margin-left: 10px;
  width: 26px;
  height: 26px;
}

.loadingIcon {
  margin-left: 10px;
  margin-top: -2px;
  width: 34px;
  height: 34px;
}

.contentRight {
  display: flex;
  align-items: center;
}

.inputContainer {
  display: flex;
}

.inputWrapper {
  flex: 1;
  position: relative;
}
