@import "styles/mixins";

.sidebarToggle {
  border: none;
  transform: scale(-1, 1);
  text-align: left;
  transition: transform 200ms;
}

@include sidebar-opened {
  .sidebarToggle {
    transform: scale(1, 1);
  }
}
