@import "styles/mixins";

.container {
  max-width: 1440px !important;
  margin: 0 auto 70px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  margin-bottom: 10px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.main {
  display: flex;
  flex: 1;
}

.filter {
  flex: 0.3;
  margin-right: 20px;
}

.content {
  flex: 1;
}

.title {
  margin-top: 12px;
  margin-bottom: 0px;
  padding: 0;
  margin-right: 18px;
  margin-left: 10px;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 250px;
}

.leftActions {
}

.rightActions {
  display: flex;

  > * {
    margin-left: 6px;
  }
}

@include media-md {
  .filter {
    display: none;
  }
}

@include media-sm {
  .container {
    max-width: 100%;
  }

  .title {
    font-size: 30px;
    min-width: unset;
  }
}

// @include media-xs {
//   .header {
//     flex-direction: column;
//     align-items: flex-start;
//   }
// }

@include media-md {
  .header {
    padding: 0 10px;
  }
}
