pre[class*="language-"],
code[class*="language-"] {
  color: #22dddd;
  text-shadow: none;
  direction: ltr;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: break-word;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
pre[class*="language-"]::selection,
code[class*="language-"]::selection,
pre[class*="language-"]::mozselection,
code[class*="language-"]::mozselection {
  text-shadow: none;
  background: #667e99;
}
@media print {
  pre[class*="language-"],
  code[class*="language-"] {
    text-shadow: none;
  }
}
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  background: #182e47;
}
:not(pre) > code[class*="language-"] {
  padding: 0.1em 0.3em;
  border-radius: 0.3em;
  color: #ff5555;
  background: #f9f2f4;
}
/*********************************************************
* Tokens
*/
.namespace {
  opacity: 0.7;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #8d9399;
}
.token.punctuation {
  color: #b4d7ff;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #08baff;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #00cc88;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #0177ff;
  background: transparent;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #d0bcff;
}
.token.function {
  color: #ff75ac;
}
.token.regex,
.token.important,
.token.variable {
  color: #ffd666;
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
/*********************************************************
* Line highlighting
*/
pre[data-line] {
  position: relative;
}
pre[class*="language-"] > code[class*="language-"] {
  position: relative;
  z-index: 1;
}
.line-highlight {
  position: absolute;
  left: 0;
  right: 0;
  padding: inherit 0;
  margin-top: 1em;
  background: #2a415c;
  box-shadow: inset 5px 0 0 #667e99;
  z-index: 0;
  pointer-events: none;
  line-height: inherit;
  white-space: pre;
}
