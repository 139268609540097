@import "variables.module";

$helpPageTransition: opacity $helpPageTransitionDuration
    $helpPageTransitionDelay,
  transform $helpPageTransitionDuration $helpPageTransitionDelay;

/* forward */
.forward {
  .helpPageSlide-enter {
    z-index: 1;
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }

  .helpPageSlide-enter-active {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
    transition: $helpPageTransition;
  }

  .helpPageSlide-exit {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }

  .helpPageSlide-exit-active {
    opacity: 1;
    transform: translate3d(-25%, 0, 0);
    transition: $helpPageTransition;
  }
}

/* backward */
.backward {
  .helpPageSlide-enter {
    z-index: 0;
    opacity: 1;
    transform: translate3d(-25%, 0, 0);
  }

  .helpPageSlide-enter-active {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
    transition: $helpPageTransition;
  }

  .helpPageSlide-exit {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }

  .helpPageSlide-exit-active {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
    transition: $helpPageTransition;
  }
}
