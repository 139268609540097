@import "styles/mixins";

// we need to override tippy classes globally
:global {
  .simpleTooltip {
    --simple-tooltip-background: var(--light-black-color);
  }

  @include dark {
    .simpleTooltip {
      --simple-tooltip-background: rgba(var(--thunk-grey-400), 1);
    }
  }

  .simpleTooltip {
    &.tippy-box {
      background-color: var(--simple-tooltip-background);
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      color: var(--background-color);

      .tippy-content {
        padding: 10px 16px 8px 16px;
      }

      .tippy-arrow {
        color: var(--simple-tooltip-background);
      }
    }
  }
}
