@import "styles/mixins";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 45vh;
  background: var(--background-color);
  box-shadow: 0px 3px 6px var(--card-box-shadow), inset 0px 0px 0px 1px var(--card-border-color);

  border-radius: 30px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.footer {
  padding: 10px 20px;
}

@include media-sm {
  .container {
    min-height: calc(100vh - var(--app-global-padding-top) - 50px);
    border-radius: 0px;
  }
}