.root {
  display: flex;
  align-items: center;
  max-width: 425px;
  margin-top: 45px;
  padding: 20px;
  background: var(--blue-color);
  border-radius: 20px;
  border: 1px solid rgba(var(--thunk-blue-800),1);
}

.copyPreviewTitle {
  color: white;
  font-size: 18px;
}

.onboardingImage {
  width: 50px;
  margin-right: 20px;
}
