.control {
  display: flex;
  align-items: center;
}

.control + .control {
  margin-top: 10px;
}

.label {
  margin-left: 6px;
  margin-top: 3px;
  user-select: none;
  color: #ffffff;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}
