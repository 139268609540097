.container {
  position: relative;
  padding: var(--card-padding-horizontal);
}

.templatesSection {
  margin-top: 10px;
  margin-bottom: 30px;
}

.templatesButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: var(--card-padding-horizontal);
}

.insertTemplateTitle {
  font-weight: 700;
  margin-bottom: 10px;
}

.moreButton {
  margin-top: 10px;
}