.container {
  position: relative;
}

.borderFade {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 15px;
  z-index: 1;

  background: linear-gradient(
    90deg,
    rgba(var(--page-background-color-rgb), 1) 0%,
    rgba(var(--page-background-color-rgb), 0) 100%
  );
}

.noItemsMessage {
  pointer-events: none;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  user-select: none;
  z-index: 1;
  padding-bottom: 20px;

  visibility: hidden;
  opacity: 0;
  transition: opacity 400ms;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
