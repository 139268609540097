@import "styles/mixins";

.drawer {
  background-color: var(--background-color);
  box-shadow: var(--help-drawer-shadow);
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 990;
  overflow-y: auto;
  overflow-x: hidden;

  transform: none;
  transition: transform 280ms;

  min-width: 420px;
  max-width: 700px;
  width: 100vw;
  height: 40vh;

  padding-right: 18px;
}

.drawerClosed {
  transform: translateY(calc(100% + 100px));
}

.drawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 989;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: auto;

  display: block;

  opacity: 1;
  transition: opacity 300ms;
  transition-timing-function: ease-out;
}

.drawerOverlayClosed {
  opacity: 0;
  pointer-events: none;
}

@include media-xs {
  .drawer {
    min-width: unset;
    width: 100vw;
  }
}
