.pointer {
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.horizontalPointer {
  height: 3px;
  transition-property: left, width;
}

.verticalPointer {
  width: 3px;

  transition-property: top, height;
}
