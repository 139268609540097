@import "styles/mixins";

.root {
  width: 100%;
}

.btn {
  width: 100%;
  padding: 2rem;
}

.selected {
    border: 1px solid var(--blue-color);
}
