@import "../../../../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 350px;
  // padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .placeholder {
    width: 324px;
    height: 52px;
    padding-top: 14px;
    text-align: center;
  }
}
