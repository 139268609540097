@import "styles/mixins";

:root {
  --template-label-color: var(--pink-color);
  --template-label-bg-color: rgba(var(--thunk-pink-200), 1);
}

@include dark {
  --template-label-color: rgba(var(--thunk-pink-700-dark), 1);
  --template-label-bg-color: rgba(var(--thunk-pink-300-dark), 1);
}

.templateLabel {
  color: var(--template-label-color);
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
  font-weight: 500;
  border-radius: 30px;
  font-size: 14px;
  width: 68px;
}

