@import "styles/mixins";

:root {
  --search-focus-background-color: rgba(var(--thunk-blue-200), 1);
  --search-focus-border-color: rgba(var(--thunk-blue-300), 1);
  --clear-button-bg-color: rgba(var(--thunk-grey-500), 1);
  --clear-button-bg-color-hover: var(--primary-color);
  --clear-button-color: rgba(var(--thunk-grey-200), 1);
}

@include dark {
  --search-focus-background-color: rgba(var(--thunk-blue-200-dark), 1);
  --search-focus-border-color: rgba(var(--thunk-blue-300-dark), 1);
  --clear-button-bg-color: rgba(var(--thunk-grey-600), 1);
  --clear-button-color: black;
}

.menuContainer {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: var(--range-popper-z-index);
  opacity: 0;
  visibility: hidden;
  background: var(--thunk-drowdown-menu-bg-color);
  padding-top: 5px;
  padding-bottom: 5px;

  max-width: 550px;

  border-radius: 10px;
  box-shadow: 0 2px 4px var(--component-menu-shadow-color), 0px -2px 0px 0px var(--component-menu-inset-color) inset, 0px 0px 0px 1px var(--component-menu-border-color);

  transition: opacity 0.2s;
  min-width: 350px;
  overflow: hidden;

  transition: opacity 0.3s;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.visibleIcon {
  // display: block;
}

.invisibleIcon {
  display: none;
}

.searchInputContainer {
  position: relative;
  width: 100%;

  .searchIcon {
    position: absolute;
    top: 11px;
    left: 14px;
    pointer-events: none;
  }

  .keyboardHint {
    position: absolute;
    top: 5px;
    right: 12px;
    font-weight: 500;
    font-size: 14px;
    height: 30px;
    color: rgba(var(--thunk-grey-500), 1);
  }

  .clearButton {
    position: absolute;
    display: none;
    top: 8px;
    right: 8px;
    width: 24px !important;
    padding: 0;
    height: 24px;
    background-color: var(--clear-button-bg-color);
    color: var(--clear-button-color);
    min-width: 24px;

    &:hover {
      background-color: var(--clear-button-bg-color-hover);
      cursor: pointer;
    }

    &.visibleClearButton {
      display: flex;
    }
  }

  &.containerFocused {
    .shortcut {
      display: none;
    }

    .keyboardHint {
      display: none;
    }
  }

  &.hasValue{
    .keyboardHint {
      display: none;
    }
    .clearButton {
      display: flex;
    }
  }

  &.isEmpty{
    &:hover {
    .keyboardHint {
      display: flex;
      color: var(--primary-color);
    }

    .clearButton {
      display: none;
    }
   }
  }


  p {
    font-weight: bold;
  }

  .searchInput {
    display: block;
    width: 100%;
    height: 40px;
    max-width: 100%;
    padding: 6px 0 5px 39px;
    border-radius: 40px;
    background: var(--button-electron-header-bg-color);

    font-size: 16px;
    // font-weight: 500;
    border: none;
    outline: none;
    color: var(--text-color);
    border: 1px solid transparent;

    &:active,
    &:focus {
      background: var(--search-focus-background-color);
      border: 1px solid var(--search-focus-border-color);
    }

    &:focus::placeholder {
      color: var(--light-text-color);
    }

    &::placeholder {
      transition: 250ms color;
      color: rgba(var(--thunk-text), 0.5);
    }
  }
}

@include media-xs {
  .searchInputContainer {

    .clearButton {
      top: 18px;
      display: none !important;
    }

    .searchIcon {
      left: 11px;
    }

    .searchInput {
      padding-left: 14px;
      width: 40px;
      color: rgba(0, 0, 0, 0);

      &:active,
      &:focus {
        color: var(--text-color);
      }

      &::placeholder {
        color: rgba(var(--thunk-text), 0);
      }
    }
  }

  .containerFocused {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: var(--electron-header-height);
    z-index: var(--electron-search-z-index);
    border-radius: 0;

    .clearButton {
      top: 18px;
      display: flex !important;
      z-index: 9000;
    }

    .searchInput {
      height: var(--electron-header-height);
      padding-left: 50px;
      border-radius: 0;
      width: 100%;
    }

    .searchIcon {
      top: 20px;
      left: 18px;
    }
  }

  body[data-electron-app] {
    .containerFocused {
      left: 88px;
    }

    .containerFocused {
      .searchInput {
        width: calc(100% - 60px);
        max-width: 100%;
      }
    }
  }
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: var(--search-menu-item-color);
  color: var(--search-menu-text);
  line-height: 1.3rem;

  svg path {
    fill: var(--search-menu-text);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .menuItemText {
    display: flex;
    font-size: 14px;
    font-weight: 400;
  }

  .menuItemIcon {
    position: relative;
    top: 0;
    margin-right: 1rem;
  }

  .menuItemResultText {
    width: 100%;
  }

  .menuItemResultTitle {
    font-weight: bold;
  }

  .menuItemResultTextContent {
    font-size: 0.9rem;
  }

  .menuItemResultTitle,
  .menuItemResultTextContent {
    mark {
      line-height: 1rem;
      display: inline-block;
      padding-top: 1px;
      color: var(--search-menu-mark-text-color);
      background-color: var(--search-menu-mark-bg-color);
      border-radius: 3px;
    }
  }

  &.focused {
    background-color: var(--at-menu-item-focused-background);
  }
  
  &:hover {
    cursor: pointer;
    background: var(--at-menu-item-hovered-background);
  }
}

.highlighted {
  background: #ffe499;
}

@include media-sm {
  .menuContainer {
    min-width: 100vw;
    border-radius: 0;
  }
}