.container {
  z-index: 1;
  position: fixed;
  right: 50px;
  top: var(--app-global-padding-top);
  width: 40vw;
  min-width: 450px;
  max-width: 600px;
  margin-bottom: 50px;
  padding-left: 30px; // for dnd handle
  max-height: 100vh;
  overflow-x: scroll;
  overflow-y: visible;

  opacity: 0;
  transform: translate3d(100px, 0, 0);
  will-change: opacity, transform;
  transition-property: opacity, transform;
  transition-duration: 300ms;
  transition-delay: 250ms;
}

.visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.cardWrapper {
  background-color: var(--background-color);
  border-radius: 30px;
  margin-top: 50px;
  box-shadow: 0 5px 10px rgba(0,0,0, 0.075);
  margin-right: 4px;
  margin-bottom: 240px;

  & > *  {
    min-height: 250px!important;
  }
}
