@import "styles/mixins";

.popperContainer {
  width: 400px;
  max-width: 100vw;
  overflow-y: scroll;
}

@include media-xxs {
  .popperContainer {
    width: 100vw;
    max-height: 300px;
  }
}

.popperTitle {
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
  margin-left: 14px;
  margin-bottom: -2px;
}

.noImageBtn {
  background-color: rgba(var(--thunk-grey-200), 1);
}
@include dark {
  .noImageBtn {
    background-color: rgba(var(--thunk-grey-1000), 1);
  }
}

.headerImagePopperItems {
  display: flex;
  flex-wrap: wrap;


  Button {
    flex-grow: 1;
    width: 33%;
    height: 70px;
  }
}
