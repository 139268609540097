@import "styles/mixins";

.sidePanel {
  position: fixed;
  top: var(--app-global-padding-top);
  right: 0;
  bottom: 0;
  padding: 0 var(--base-margin-horizontal) 0 0;
  width: 100%;
  max-width: 540px;
  transform: translateX(100%);
  z-index: var(--side-panel-container);
  transition: transform 250ms;
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
}

@include side-panel-opened {
  .sidePanel {
    transform: translateX(0);
  }
}

@include media-sm {
  .sidePanel {
    display: block;
  }
}

@include media-xs {
  .sidePanel {
    max-width: 100vw;
  }
}

@include media-xs {
  .sidePanel {
    padding-left: var(--base-margin-horizontal);
  }
}
