.hint {
  font-size: 14px;
  height: 28px;
  width: 100%;

  &:after {
    display: block;
    content: "";
    height: 28px;
    width: 423px;
    background: url(../../../../images/placeholder_text.svg) no-repeat;
  }
}

[theme="dark"] {
  .hint {
    &:after {
      background: url(../../../../images/placeholder_text_dark_mode.svg) no-repeat;
    }
  }
}
