@import "styles/mixins";

.filterToolbar {
  --t-br-color: #d0d3d6;
  --t-bg-color: #f0f2f5;
}

@include dark {
  .filterToolbar {
    --t-br-color: #1b2633;
    --t-bg-color: rgba(var(--thunk-grey-900), 1);
  }
}

.filterToolbar {
  display: none;
  padding: 8px;
  background-color: var(--t-bg-color);
  border-top: 1px solid var(--t-br-color);
  border-bottom: 1px solid var(--t-br-color);

  > :first-child {
    flex: 1;
  }

  > * + * {
    margin-left: 10px;
  }
}

.filterButtonActive {
  color: var(--primary-color);
}

@include media-sm {
  .filterToolbar {
    display: flex;
  }
}
