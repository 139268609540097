@import "styles/mixins";

.referenceGroup {
  padding-bottom: 18px;
}

.sourceTitle {
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: var(--purple-color);
  opacity: 0.7;
  letter-spacing: 0.2px;
  margin-bottom: 5px;


  &:hover {
    opacity: 1;
    text-decoration: underline;
  }

  &.sourceTitleTemplate {
    color: var(--pink-color);
  }
}

.referenceSourceTitle {
  display: flex;
  margin-left: -31px;
  padding: var(--card-padding-horizontal);
}

.referenceTitleCollapseArrow {
  height: 16px;
  margin-right: 13px;
}

[data-card-xs] {
  .referenceSourceTitle {
    margin-left: -8px;
  }
}

.placeholderBlock {
  padding: var(--card-padding-horizontal);
  padding-top: 2px;
  padding-bottom: 2px;
  animation: placeholder-loading 1s linear infinite alternate;
}

.blockEditorWrapper {
  padding: var(--card-padding-horizontal);
}

@keyframes placeholder-loading {
  0% {
    opacity: 90%;
  }

  100% {
    opacity: 40%;
  }
}
