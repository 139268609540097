.elementPopper {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: var(--range-popper-z-index);
  opacity: 0;
  visibility: hidden;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
