@import "styles/mixins";

.menuTooltip {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: var(--range-popper-z-index);
  opacity: 0;
  visibility: hidden;
  background: var(--thunk-drowdown-menu-bg-color);
  border-radius: 10px;
  transition: opacity 0.2s;
  min-width: 350px;
  //padding-bottom: 5px;
  // overflow: hidden;
  box-shadow: 0 2px 4px var(--component-menu-shadow-color), 0px -2px 0px 0px var(--component-menu-inset-color) inset, 0px 0px 0px 1px var(--component-menu-border-color);
}

.searchInput {
  border-bottom: 1px solid var(--card-toolbar-menu-divider-line-color);
  height: 38px;
  font-size: 14px;
  font-weight: 500;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  width: 100%;
  padding: 12px 10px 10px 17px;
  background-color: var(--thunk-drowdown-menu-bg-color);
  color: var(--component-menu-search-color);

  &::placeholder {
    color: var(--component-menu-search-placeholder-color);
  }
}
