@import "styles/mixins";

:root {
  --checkbox-border-hover-color: rgba(var(--thunk-blue-500), 1);
  --checkbox-shadow-color: rgba(var(--thunk-blue-300), 1);
}

@include dark {
  --checkbox-border-hover-color: rgba(var(--thunk-blue-900-dark), 1);
  --checkbox-shadow-color: rgba(var(--thunk-blue-400-dark), 1);
}

.checkWrapper {
  --default-border: var(--text-color);
  --default-background: var(--background-color);
  --default-mark: transparent;
  --default-outline: transparent;
  --effect-background: var(--primary-color);
  --effect-mark: white;
  --effect-outline: var(--primary-color);
  --disabled-border: gray;
  --disabled-effect-background: gray;

  position: relative;
  -webkit-tap-highlight-color: transparent;
  padding-left: 24px;
  //display: inline-block;
  //width: 22px;
  //height: 20px;

  &.withLabel {
    width: 100%;
  }
}

th,
tr {
  .checkWrapper {
    vertical-align: middle;
  }
}

.checkInput {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  top: 2px;
  left: 2px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.checkSvg {
  position: absolute;
  top: calc(50% - 9px);
  width: 16px;
  height: 16px;
  margin-left: -22px;

  &:hover {
    cursor: pointer;

    .checkRect {
      stroke: var(--checkbox-border-hover-color);
    }


    .checkShadow {
      transform-origin: center;
      transform: scale(1.3);
    }
  }

  &:active {
    .checkRect {
      stroke: var(--primary-color);
      stroke-width: 1.75px;
      transform: scale(0.9);
    }

    .checkMark {
      transform: scale(0.9);
    }

    .checkShadow {
      transform: scale(0.9);
    }
  }
}

/* Initial */

.checkRect {
  stroke: var(--default-border);
  fill: var(--default-background);

  stroke-width: 1.5px;

  transform-origin: center;
  transition-property: stroke, fill;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.checkShadow {
  transition: all 0.1s ease;
  stroke: var(--checkbox-shadow-color);
  fill: var(--checkbox-shadow-color);
}

.checkMark {
  stroke: var(--default-mark);

  transform-origin: center;
  transition: stroke-dashoffset 0.2s ease;
  stroke-dasharray: 24px;
  stroke-dashoffset: 24px;
}

.checkCircle {
  fill: var(--default-outline);
  transform: scale(1);
  transform-origin: center center;
  opacity: 0.2;
  transition: all 0.2s ease;
}

/* Checked */

.checkInput:checked + .checkSvg .checkRect {
  stroke: var(--effect-background);
  fill: var(--effect-background);
  transition-duration: 0.1s;
}

.checkInput:checked + .checkSvg .checkMark {
  stroke: var(--effect-mark);
  stroke-dashoffset: 0px;
}

/* Focused */

.checkInput:focus-visible + .checkSvg .checkCircle {
  fill: var(--effect-outline);
  transform: scale(1.9);
  opacity: 0.3;
}

/* Disabled */

.checkInput:disabled + .checkSvg {
  &:hover {
    cursor: default;
  }
}

.checkInput:disabled + .checkSvg .checkRect {
  stroke: var(--disabled-border);
}

.checkInput:checked:disabled + .checkSvg .checkRect {
  fill: var(--disabled-effect-background);
}
