@import "styles/mixins";

.electronContainer {
  --header-background: rgba(var(--thunk-grey-200), 1);
}

@include dark {
  .electronContainer {
    --header-background: black;
  }
}

.electronContainer {
  display: flex;
  flex-direction: column;
}

.electronHeader {
  position: fixed;
  top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  -webkit-app-region: drag;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  z-index: var(--electron-header-z-index);
  background: var(--header-background);
  height: var(--electron-header-height);
  overflow: hidden;

  /* Prevent fixed position elements from scrolling on mobile */
  -webkit-overflow-scrolling: touch;

  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.electronHeaderLeft {
  flex: 2 1;
  min-width: 0; // for text-overflow: ellipsis; children support: https://css-tricks.com/flexbox-truncated-text/
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 8px;

  .backwardText {
    padding-left: 26px;
    font-size: 14px;
    font-weight: 400;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    opacity: 0.4;

    &:hover {
      opacity: 1;
      color: var(--primary-color);
    }
  }
}

.electronHeaderCenter {
  padding: 0 20px;
}

.electronHeaderRight {
  padding: 0 10px;
  flex: 1;
  justify-content: flex-end;

  > * {
    margin-left: 8px;
  }
}

.electronHeaderTodayLink {
  background-color: var(--button-electron-header-bg-color);
  display: flex;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 40px;
  padding: 0 20px 0 14px;
  color: var(--text-color);
  white-space: nowrap;
  align-items: center;

  &:hover {
    background-color: var(--button-electron-header-bg-hover-color);
  }

  &:active {
    box-shadow: 0px 2px 4px 0px var(--button-electron-header-inner-shadow-color-active) inset,
  }

  &:focus {
    text-decoration: none;
  }
}

.electronHeaderTodayLinkMobile {
  background-color: var(--button-electron-header-bg-color);
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 40px;
  padding: 0 0 0 9px;
  color: var(--text-color);
  white-space: nowrap;
  align-items: center;
  width: 40px;
  display: none;

  &:hover {
    background-color: var(--button-electron-header-bg-hover-color);
  }

  &:focus {
    text-decoration: none;
  }
}

.todayIcon {
  margin-right: 9px;
}

.electronNavButtons {
  display: none;
  align-items: center;
  margin-left: 8px;
  margin-right: 0px;
  margin-top: 2px;

  button:hover {
    cursor: pointer;
  }
}

.electronSearch {
  display: flex;
  align-items: center;
  width: 26vw;
}

// @include media-sm {

//   .electronNavButtons {
//     padding-left: 10px;
//   }
// }

@include media-sm {
  .electronNavButtons {
    display: none;
  }

  .electronHeaderCenter {
    padding: 0 3px;
  }

  .electronHeaderTodayLink {
    display: none;
  }

  .electronHeaderTodayLinkMobile {
    display: flex;
  }
  .electronSearch {
    width: 20vw;
  }
}

.breadcrumb {
  margin-left: 15px;
}

@include media-xs {
  .breadcrumb {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .electronSearch {
    width: 40px;
  }
}

// add padding for the buttons on desktop and show the back/forward buttons
body[data-electron-app] {
  .electronHeader {
    padding-left: 80px;
  }
  
  .electronNavButtons {
    display: flex;
  }

  @include media-sm {
    .electronNavButtons {
      margin-right: -3px;
    }
  }

  @include media-xs {
    .electronNavButtons {
      display: none;
    }
  }
}