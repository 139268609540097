@import "styles/mixins";

.container {
  position: fixed;
  top: calc(var(--electron-header-height));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--thunk-grey-800), 1);
  height: calc(var(--expired-notification-height) - 5px);
  margin-bottom: 4px;
  padding: 10px 20px;
  cursor: pointer;
  z-index: var(--expired-notification-z-index);

  &:hover {
    background-color: black;
  }
}

.link {
  color: white;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  font-weight: 500;
}

@include dark {
  .container {
    background-color: rgba(var(--thunk-grey-700), 1);

    &:hover {
      background-color: rgba(var(--thunk-grey-800), 1);
    }
  }

  // .link {
  //   color: black;
  // }
}

@include media-md {
  .link {
    font-size: 13px;
  }
}

@include media-sm {
  .link {
    font-size: 12px;
  }
}
