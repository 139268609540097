@import "styles/mixins";

.templateOptionRow {
  border-radius: 5px;

  &:nth-child(odd) {
    background: rgba(var(--thunk-grey-200), 1);
  }
}

.templateOptionRowInput {
  padding: 7px 12px;
  font-size: 1.1rem;
  font-weight: 500;
  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
    margin-right: 10px;
  }
}

.previewCard {
  min-height: 500px;
  max-width: 750px;
  margin: 0 auto;
}

.previewSection {
  h3 {
    margin-bottom: 1rem;
  }
  margin-top: 1.5rem;
}

.backlink {
  color: var(--purple-color);
  font-weight: 500;
}

.bullet {
  font-size: 2.5rem;
  vertical-align: bottom;
  padding-right: 5px;
}

.nextBtn {
  display: block;
}

.nextBtnMobile {
  display: none;
}

@include media-sm {
  .templateOptionRowInput {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .previewTitle {
    font-size: 1.4rem;
    margin-bottom: 0.1rem;
  }
  .previewCard {
    min-height: 425px;
  }
  .previewSection {
    h3 {
      font-size: 1rem;
      margin-bottom: 0.2rem;
    }
    margin-top: 1rem;
  }
  .nextBtn {
    display: none;
  }
  .nextBtnMobile {
    display: block;
  }
}
