@import "styles/mixins";

.container {
  --text-color: #1b2633;
  --text-color-not-selected: #8B9197;
  --text-color-selected: #1b2633;
  --text-color-sibling: #8954ff;

  --stroke-color: #A4A8AD;
  --stroke-color-not-selected: #CFD1D4;
  --stroke-color-selected: #1b2633;
  --stroke-color-sibling: #8954ff;

  --fill-color: #ffffff;
  --fill-color-not-selected: var(--page-background-color);
  --fill-color-selected: #ffffff;
  --fill-color-sibling: #ffffff;

  --line-color: #D8C9FD;
  --line-color-sibling: #d8c9fd;
  --line-color-not-selected: #E9E2FC;
}

@include dark {
  .container {
    --text-color: #c2cbd6;
    --text-color-not-selected: #6F7884;
    --text-color-selected: #c2cbd6;
    --text-color-sibling: #8954ff;

    --stroke-color: #000000;
    --stroke-color-not-selected: #000000;
    --stroke-color-selected: #c2cbd6;
    --stroke-color-sibling: #8954ff;

    --fill-color: #17202b;
    --fill-color-not-selected: #19222e;
    --fill-color-selected: #17202b;
    --fill-color-sibling: #17202b;

    --line-color: #3D346F;
    --line-color-not-selected: #2D2D51;
  }
}

.container {
  max-width: 100% !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: transparent;
  overflow: hidden;
}

.container svg {
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--page-background-color);
}

:global {
  .graphLinksContainer {
    stroke: var(--line-color);

    line {
      transition-property: stroke, stroke-width;
      transition-duration: 300ms;
      stroke-width: 1.2;

      &.selectedConnected,
      &.hoveredConnected {
        stroke: var(--line-color);
        stroke-width: 8;
      }
    }

    &.hasSelection {
      stroke: var(--line-color-not-selected);
    }
  }

  .graphNodesContainer {
    transition-property: stroke, fill, color;
    transition-duration: 300ms;

    fill: var(--fill-color);
    stroke: var(--stroke-color);
    color: var(--text-color);

    &.hasSelection {
      fill: var(--fill-color-not-selected);
      stroke: var(--stroke-color-not-selected);
      color: var(--text-color-not-selected);
    }

    .graphNode {
      rect {
        cursor: pointer;
        width: 30px;
        height: 44px;
      }

      .nodeLabelContainer {
        pointer-events: none;
        overflow: visible;
        line-height: 17px;
      }

      .nodeLabel {
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 15px;
        user-select: none;
      }

      &.selectedSibling,
      &.hoveredSibling {
        color: var(--text-color-sibling);

        rect {
          stroke: var(--stroke-color-sibling);
          fill: var(--fill-color-sibling);
          filter: drop-shadow(0px 2px 2px rgba(var(--purple-color-rgb), 0.2));
        }
      }

      &:hover {
        rect {
          stroke: var(--stroke-color-selected);
          fill: var(--fill-color);
          filter: drop-shadow(0px 2px 2px rgba(var(--purple-color-rgb), 0.2));
        }

        .nodeLabel {
          color: var(--text-color-selected);
        }
      }

      &.selected,
      &.hovered {
        fill: var(--fill-color-selected);
        color: var(--text-color-selected);

        rect {
          stroke: var(--stroke-color-selected);
          filter: drop-shadow(0px 2px 2px rgba(var(--purple-color-rgb), 0.2));
        }
      }
    }
  }
}
