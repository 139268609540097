:root {
  --component-font-size: 16px;
  --component-line-height: 26px;
  --component-margin-top: 0;
  --component-margin-bottom: 0;
}

.slate-component-h1 {
  --component-font-size: 26px;
  --component-line-height: 36px;
  --component-margin-top: 21px;
  --component-margin-bottom: -4px;
}

.slate-component-h2 {
  --component-font-size: 22px;
  --component-line-height: 32px;
  --component-margin-top: 18px;
  --component-margin-bottom: -4px;
}

.slate-component-h3 {
  --component-font-size: 18px;
  --component-line-height: 28px;
  --component-margin-top: 14px;
  --component-margin-bottom: -4px;
}

.slate-component-list_item {
  --component-line-height: 26px;
}

[data-card-type="side-panel-card"],
[data-card-sm] {
  .slate-component-h1 {
    --component-font-size: 23px;
    --component-line-height: 33px;
    --component-margin-top: 18px;
    --component-margin-bottom: -4px;
  }

  .slate-component-h2 {
    --component-font-size: 19px;
    --component-line-height: 28px;
    --component-margin-top: 15px;
    --component-margin-bottom: -4px;
  }

  .slate-component-h3 {
    --component-font-size: 16px;
    --component-line-height: 26px;
    --component-margin-top: 13px;
    --component-margin-bottom: -4px;
  }
}

[data-card-xxs] {
  .slate-component-h1 {
    --component-font-size: 21px;
    --component-line-height: 33px;
    --component-margin-top: 18px;
    --component-margin-bottom: -4px;
  }

  .slate-component-h2 {
    --component-font-size: 18px;
    --component-line-height: 28px;
    --component-margin-top: 15px;
    --component-margin-bottom: -4px;
  }

  .slate-component-h3 {
    --component-font-size: 16px;
    --component-line-height: 26px;
    --component-margin-top: 13px;
    --component-margin-bottom: -4px;
  }
}
