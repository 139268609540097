@import "styles/mixins";

:root {
  --selected-backlink-color: rgba(var(--thunk-purple-700), 1);
  --selected-backlink-bg-color: rgba(var(--thunk-purple-300), 1);
}

@include dark {
  --selected-backlink-color: rgba(var(--thunk-purple-400), 1);
  --selected-backlink-bg-color: rgba(var(--thunk-purple-400-dark), 1);
}

.backlink {
  color: var(--backlink-color);
}

.helpBacklink {
  color: var(--primary-color);
}

.selectedBacklink {
  background-color: var(--selected-backlink-bg-color);
  box-shadow: 0 0 0 3px var(--selected-backlink-bg-color);
  border-radius: 2px;

  a {
    color: var(--selected-backlink-color);
  }
}