@import "styles/mixins";

:root {
  --shared-page-line-under-avatar-color: rgba(var(--thunk-grey-300), 1);
}

@include dark {
  --shared-page-line-under-avatar-color: rgba(var(--thunk-grey-800), 1);
}

.pageContainer {
  background-color: var(--background-color);
  margin: 10px;
  max-width: calc(100vw - 20px);
  border-radius: 30px;
}

.header {
  height: 60px;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: var(--card-title-image-z-index);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
}

.inner {
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: 0 0 10px;
  padding: 10px;
  width: 100%;
  height: var(--card-header-default-height);
  overflow: hidden;
  z-index: var(--card-title-image-z-index);

  background-color: rgba(var(--thunk-grey-300), 0);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: height 150ms ease;
}

.headerImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: -10px;
  margin-top: -10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.userInfo {
  line-height: 17px;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 2px;
  border-radius: 30px;
  height: 40px;
  z-index: 500;

  img {
    height: 36px;
    width: 36px;
  }
}

.leftActions{
  display: flex;
  flex-grow: 0;
  width: 70px; /// manually setting this to match the width of the Edit button
  flex-shrink: 0;
}

.centerActions {
  flex-grow: auto;
  display: none;
  z-index: 500;
  max-width: calc(100% - 160px);
  border-radius: 30px;

  &.visible {
    display: block;
  }

  &.invisible {
    display: none;
  }
}

.titleButton {
  max-width: 100%;
}

.titleText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

@include media-sm {
  .container {
    display: none;
  }
}

.userInfoText {
}

.userName {
  font-size: 14px;
  white-space: nowrap;
}

.author {
  opacity: 0.6;
  // font-size: 12px;
  padding-right: 3px;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
}

.sharedPage {
  min-height: auto;
}

.editButtonWrapper{
  height: 40px;
  border-radius: 30px;
}

.joinButton {
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 30px;
  height: 40px;
  padding: 0;
  width: 70px // manually setting this to match the width of .leftActions

  // &.mobileButton {
  //   display: none;
  //   margin-right: 30px;
  //   align-self: flex-end;
  // }
}


.sharedCardAvatar{
  display: flex;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  padding: var(--card-padding-horizontal);
  margin-top: 20px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.lineUnderAvatar {
  border: none;
  height: 1px;
  margin-top: 12px;
  margin-bottom: 12px;
  background: var(--shared-page-line-under-avatar-color);
  margin: var(--card-padding-horizontal);
  margin-bottom: 20px;
  border-radius: 10px;
}

.usernameText {
  margin-left: 10px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;

  > * {
    margin: 3px;
  }
}

.tagsDivider {
  border: none;
  height: 1px;
  margin-top: 12px;
  margin-bottom: 12px;
  background: rgba(var(--thunk-grey-300), 1);
}

.sharePageFooter {
  width: 100vw;
  height: 300px;
  background-color: var(--page-background-color);
  text-align: center;
}

.footerTop {
  height: 60px;
  background-color: var(--background-color);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  max-width: calc(100vw - 20px);
}

.madeWithImage {
  width: 233.33px;
  height: 40px;
  margin-top: 80px;
  margin-left: -40px;
  display: inline-block;
}

.madeWithImageDark {
  width: 233.33px;
  height: 40px;
  margin-top: 80px;
  margin-left: -40px;
  display: none;
}

[theme="dark"] {
  .madeWithImage {
    display: none;
  }

  .madeWithImageDark {
    display: inline-block;
  }
}

@include media-xl {
  .pageContainer {
    --base-margin-horizontal: 10px;
  }
}

@include media-sm {
  .userInfo {
    width: 40px;
  }

  .userInfoText {
    visibility: hidden;
  }
}

@include media-xs {

.inner {
  border-radius: 0;
}
.header {
  border-radius: 0;
}
.headerImage {
  border-radius: 0;
}

  .pageContainer {
    border-radius: 0;
    margin: 0;
    max-width: 100vw;
  }

  .footerTop {
    border-radius: 0;
    border: 0;
    max-width: 100vw;
  }
}

@include media-xxs {
  .pageContainer {
    --base-margin-horizontal: 0px;
  }
}
