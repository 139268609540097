.link {
  width: 100%;
  margin: 6px 0;
  padding: 0 12px;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: 500;
  font-size: 14px;
  user-select: none;

  &:hover:not(.active) {
    color: var(--primary-color);
  }

  .kbd {
    display: none;
  }

  &:hover .kbd {
    display: inline;
    padding-left: 10px;
    font-size: 0.7rem;
    line-height: 0.8rem;
    opacity: 0.5;
  }

  &.active {
    transition: color 250ms;

    .icon {
      opacity: 0;
    }

    .activeIcon {
      opacity: 1;
    }
  }
}

.iconContainer {
  min-width: 24px;
  height: 24px;
  position: relative;

  & > * {
    position: absolute;
    transition: opacity 250ms;
    color: inherit;
  }
}

.icon {
  opacity: 1;
}

.activeIcon {
  opacity: 0;
}

.text {
  margin-left: 10px;
  margin-top: 3px;
  white-space: nowrap;
}
