:root {
  --due-date-calendar-action-hover-bg: #f0f4f7;
}

.calendarAction {
  display: flex;
  padding: 3px 15px;
  color: #1b2633;
  .icon {
    display: flex;
    align-items: center;
  }
  .text {
    position: relative;
    flex: 1;
    top: 1px;
    font-size: 14px;
    margin-left: 7px;
    font-weight: 500;
  }
  .hint {
    position: relative;
    top: 2px;
    font-size: 12px;
    margin-left: 7px;
    opacity: 0.5;
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 6px;
  }
  &:last-child {
    padding-bottom: 6px;
  }
  &:hover {
    cursor: pointer;
    background: var(--due-date-calendar-action-hover-bg);
  }
}
