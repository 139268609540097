.selectStyles,
.select {
  --select-border: rgba(var(--thunk-grey-600), 1);
  --select-focus: var(--blue-color);
  --select-arrow: var(--select-border);
}

.selectStyles {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: 1.2rem;
  cursor: inherit;
  line-height: inherit;
  color: var(--text-color);

  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;

  &:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
  }
}

.buttonList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .buttonListOption {
    margin-bottom: 0.5rem;
    margin-right: 0.8rem;
  }

  &.vertical {
    flex-direction: column;

    .buttonListOption {
      margin-right: 0rem;
    }
  }
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  width: 100%;

  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.6em 0.5em;

  font-size: 1.25rem;
  color: var(--text-color);
  cursor: pointer;
  line-height: 1.1;

  // Optional styles
  // remove for transparency
  background-color: #fff;

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}

.calOption {
  width: 100%;
  margin-bottom: 2rem;

  &:first-of-type {
    margin-top: 3rem;
  }
}

.addToCal {
  text-align: right;
  margin: 2rem 0 4rem;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;

  button {
    margin-bottom: 1rem;
  }
}
