@import "styles/mixins";

:root {
  .dragActive {
    --drag-active-bg: rgba(var(--thunk-blue-300), 1);
  }
}

@include dark {
  .dragActive {
    --drag-active-bg: rgba(var(--thunk-blue-1000), 1);
  }
}

.dragging {
  .slate-editor {
    user-select: none;
  }
}

.dragActive {
  background: var(--drag-active-bg);
}

// //if it's the main editor, give it a minimum height
// .mainEditorTrue {
//   min-height: 200px;
// }

.slate-editor {
  width: 100%;

  &:not(.snippet) {
    flex: 1;
  }

  input[type="checkbox"] {
    cursor: pointer;
  }

  &.readOnly {
    input {
      pointer-events: none;
    }

    .slate-ImageElement-resizable {
      & > :last-child {
        display: none;
      }
    }

    .slate-CodeBlockElement {
      select {
        display: none;
      }
    }
  }

  .slate-ImageElement-img {
    cursor: default;
    border-radius: 20px;
  }

  .slate-ImageElement-caption {
    display: none;
  }

  .slate-CodeBlockElement {
    background-color: var(--slate-codeblock-background-color);
    border-radius: 0;
    color: var(--slate-codeblock-text-color);
    border: 1px solid var(--slate-codeblock-border-color);
    border-radius: 20px;

    padding: var(--card-padding-horizontal);
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 2px;
    margin-bottom: 5px;

    &:hover select {
      visibility: visible;
    }

    select {
      position: absolute;
      top: 10px;
      right: var(--card-pr);
      cursor: pointer;
      visibility: hidden;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      display: block;
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      font-family: AtlasGrotesk, Helvetica, sans-serif;
      border: none;
      opacity: 0.7;
      color: #c2cbd6;
      padding: 0 28px 0 0;
      text-align: right;

      background: linear-gradient(45deg, transparent 50%, #c2cbd6 50%),
        linear-gradient(135deg, #c2cbd6 50%, transparent 50%);
      background-position: calc(100% - 15px) calc(50%),
        calc(100% - 9px) calc(50%), 100% 0;
      background-size: 5px 6px, 6px 6px;
      background-repeat: no-repeat;

      &:hover {
        opacity: 1;
        color: white;
        background: linear-gradient(45deg, transparent 50%, white 50%),
          linear-gradient(135deg, white 50%, transparent 50%);
        background-position: calc(100% - 15px) calc(50%),
          calc(100% - 9px) calc(50%), 100% 0;
        background-size: 5px 6px, 6px 6px;
        background-repeat: no-repeat;
      }
    }
  }

  .slate-blockquote {
    color: var(--text-color);
    padding: 0px 20px 0px 20px;
    border-left: 3px solid var(--text-color);
    margin-left: 8.5px;
  }

  // codeblock
  .slate-code {
    background-color: var(--slate-code-background-color);
    border-radius: 6px;
    color: var(--slate-code-color);
    padding: 3px 8px;
    border: 1px solid var(--slate-code-border-color);
    margin: 0 3px;
    font-size: 13px;
    font-weight: 700;
    white-space: pre-wrap;
    font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
      monospace;
  }

  // keycap
  .slate-kbd {
    background: linear-gradient(
      180deg,
      var(--slate-keycap-background-gradient-start) 0%,
      var(--slate-keycap-background-gradient-end) 100%
    );
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid var(--slate-keycap-border-color);
    box-shadow: 0 4px 4px var(--slate-keycap-shadow-color);
    padding: 5px 8px;
    font-weight: 500;
    font-family: AtlasGrotesk, Helvetica, sans-serif;
    color: var(--text-color);
  }

  .slate-LinkElement {
    color: var(--blue-color);
    font-weight: 500;
    text-decoration: none;
  }

  .slate-LinkElement:hover {
    color: var(--blue-color);
    cursor: pointer;
  }

  .slate-img {
    img {
      margin: 0 auto;
      min-height: 66px; // same as button to upload
      max-height: 580px; // same as note content max width
    }
  }

  .slate-list-item {
    .slate-p {
      line-height: 26px;
    }
  }
}

.slate-p,
.slate-h1,
.slate-h2,
.slate-h3,
.placeholder-h1,
.placeholder-h2,
.placeholder-h3 {
  font-size: var(--component-font-size);
  line-height: var(--component-line-height);
  margin: var(--component-margin-top) 0 var(--component-margin-bottom);
  padding: 0;
}

.slate-h1,
.slate-h2,
.slate-h3 {
  font-weight: 700;
  color: var(--text-color);
}

.placeholder-h1,
.placeholder-h2,
.placeholder-h3 {
  color: var(--light-text-color);
  font-weight: 700;
  opacity: 0.3;
}



mark.slate-highlight {
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  background-color: rgba(var(--thunk-yellow-400), 1);
}

@include dark {
  mark.slate-highlight {
    background-color: rgba(var(--thunk-yellow-300-dark), 1);
    color: rgba(var(--thunk-yellow-900-dark), 1);
  }
}

[data-card-lg] {
  .slate-CodeBlockElement {
    border-radius: 0;
  }
}