@import "styles/mixins";

.section {
  margin-top: 26px;
  padding-left: 10px;
  width: 100%;
}

.sectionHeader {
  width: 100%;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  white-space: nowrap;

  &:hover {
    .actions {
      display: block;
    }
  }
}

.button {
  color: var(--text-color);
}

.actions {
  display: none;
  user-select: none;
  cursor: default;
  color: var(--text-color);
  opacity: 0.5;
}

.emptyText {
  font-size: 14px;
  margin-left: 48px;
  font-style: italic;
  color: var(--text-color);
  white-space: nowrap;
}

.content {
  overflow: hidden;
  color: var(--text-color);
  height: 0;
}

@include media-sm {
  .content {
    height: 100%;
  }
}

@include sidebar-opened {
  .content {
    height: 100%;
  }
}
