body {
  .react-notification-root {
    height: auto;
    bottom: 0;
  }

  .notification-container-bottom-left {
    width: 360px;
    bottom: calc(20px + var(--expired-notification-height));

    .notification-item {
      border-radius: 15px;
    }
  }

  .notification-container-mobile-bottom {
    bottom: calc(20px + var(--expired-notification-height));
  }

  .notification-container-bottom-center {
    width: 240px;
    left: calc(50% - 140px);
    bottom: calc(20px + var(--expired-notification-height));
  }

  .notification-item {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
  }

  .notification-item .notification-content {
    padding: 0;
  }

  .notification-item .notification-content .notification-message {
    max-width: 100%;
  }
}
