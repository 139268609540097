@import "styles/mixins";

.title {
  width: 100%;
  padding-left: calc(var(--spacing, 0px));
}

.date {
  font-size: 14px;
  font-weight: 500;
  min-width: 170px;
}

.tags {
  min-width: 230px;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
}

@include media-sm {
  .date {
    display: none;
  }
}

.tagsToDelete {
  font-weight: bold;
}