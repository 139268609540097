@import "styles/mixins.scss";

:root {
  --due-date-calendar-background-color: #ffffff;
  --due-date-calendar-text-color: #1b2633;
  --due-date-calendar-now-tile-background-color: #f0f4f7;
  --due-date-calendar-now-tile-color: #3b4b5e;
  --due-date-calendar-weekend-day-color: var(--due-date-calendar-text-color);
  --due-date-calendar-hover-tile-background-color: #f0f4f7;
  --due-date-calendar-hover-tile-color: #1b2633;
  --due-date-calendar-selected-tile-background-color: #f0f4f7;
  --due-date-calendar-selected-tile-color: var(--primary-color);
}

@include dark {
  --due-date-calendar-background-color: #ffffff;
  --due-date-calendar-text-color: #1b2633;
  --due-date-calendar-now-tile-background-color: #f0f4f7;
  --due-date-calendar-now-tile-color: var(--primary-color);
  --due-date-calendar-weekend-day-color: var(--due-date-calendar-text-color);
  --due-date-calendar-hover-tile-background-color: #f0f4f7;
  --due-date-calendar-hover-tile-color: #1b2633;
  --due-date-calendar-selected-tile-background-color: #f0f4f7;
  --due-date-calendar-selected-tile-color: var(--primary-color);
}

.dueDateCalendar {
  margin-top: 20px;
  padding: 0 20px 20px 20px;

  .react-calendar {
    background: transparent;
    line-height: 1.5em;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    width: 100%;

    abbr[title] {
      text-decoration: none;
    }
  }

  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 26px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    display: flex;
  }
  .react-calendar__navigation button {
    min-width: 31px;
    background: none;
    cursor: default;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    //background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    //background-color: #f0f0f0;
  }
  .react-calendar__navigation__label {
    color: var(--due-date-calendar-text-color);
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    color: var(--due-date-calendar-text-color);
  }
  .react-calendar__month-view__weekdays__weekday {
    font-size: 10px;
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }

  .react-calendar__month-view__days__day {
    color: var(--due-date-calendar-text-color);
  }

  .react-calendar__month-view__days__day--weekend {
    color: var(--due-date-calendar-weekend-day-color);

    .react-calendar__tile--mark {
      color: var(--due-date-calendar-weekend-day-color);
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(black, 0.2);
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.48em 0.3em;
    background: none;
    border-radius: 11px;
    border: 4px solid #ffffff;
    margin-bottom: -4px;
  }
  .react-calendar__tile:disabled {
    color: rgba(var(--light-grey-color-rgb), 0.2);
    cursor: default;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: var(--due-date-calendar-hover-tile-background-color);
    color: var(--due-date-calendar-text-color);

    .react-calendar__tile--mark {
      color: var(--due-date-calendar-hover-tile-color);
    }
  }
  .react-calendar__tile--now {
    background: var(--due-date-calendar-now-tile-background-color);
    color: var(--due-date-calendar-now-tile-color);

    .react-calendar__tile--mark {
      color: var(--due-date-calendar-now-tile-color);
    }
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: var(--due-date-calendar-now-tile-background-color);
    color: var(--due-date-calendar-now-tile-color);
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #f0f4f7;
    color: var(--primary-color);

    .react-calendar__tile--mark {
      color: var(--primary-color);
    }
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: var(--due-date-calendar-selected-tile-background-color);
    color: var(--due-date-calendar-selected-tile-color);
    .react-calendar__tile--mark {
      color: var(--due-date-calendar-selected-tile-color);
    }
  }
  .react-calendar__tile--mark {
    position: relative;
    width: 6px;
    height: 5px;
    color: var(--due-date-calendar-text-color);

    &::after {
      opacity: 0;
      content: "●";
      position: absolute;
      font-size: 6px;
      left: 7px;
      top: 1px;
      transition: 400ms opacity;
    }
  }
  .react-calendar__tile--markActive::after {
    opacity: 1;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation .react-calendar__navigation__arrow {
    text-align: center;
    cursor: pointer;
    padding-bottom: 2px;

    svg {
      stroke: var(--due-date-calendar-text-color);
    }

    &:hover {
      svg {
        stroke: #1b2633;
      }
    }
  }
}

@include dark {
  .dueDateCalendar {
    .react-calendar__tile {
      border: 4px solid #ffffff !important;
    }
  }
}
