.container {
  margin: 8px 0;
  display: flex;
  width: 100%;
  flex-direction: column;

  .icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin: 3px 10px 0 4px;
  }

  .content {
    padding: 5px 12px;
    display: flex;
    background-color: var(--alert-background-color);
    color: white;
    border-radius: 12px;
    line-height: 28px;

    a {
      color: var(--light-blue-text-color);
    }
  }

  .code {
    color: var(--light-text-color);
    font-style: italic;
    margin-top: 6px;
    margin-right: 10px;
    font-size: 14px;
    text-align: end;
    opacity: 0.5;
  }

  &.gift {
    .content {
      fill: var(--alert-gift-color);
      color: var(--alert-gift-color);
      background-color: var(--alert-gift-background-color);
    }
  }
}
