.container {
  width: 100%;
  padding: 8px 12px;
  background-color: var(--notification-bakcground-color);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--notification-text-color);
  font-size: 16px;
  font-weight: 500;

  &.bottomCenter {
    font-size: 16px;
    font-weight: 500;
    border-radius: 15px;
    background-color: var(--notification-center-background-color);
    border: 1px solid var(--notification-bakcground-color);
    padding: 6px 7px 6px 14px;
    flex-direction: row;
  }

  &.bottomRight {
    background-color: white;
    border-radius: 16px;
    padding: 29px 36px;
    border: 1px solid #d6dce3;
    min-width: 460px;
    max-height: 220px;
    margin: -140px;
    color: black;
    box-shadow: 0px 6px 22px rgba(61, 75, 92, 0.16);
  }
}

.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > * {
    margin-right: 10px;
  }
}

.statusIcon {
  width: 20px;
  height: 20px;
  margin-top: 0;
  min-width: 20px;
}

.loadingIcon {
  width: 22px;
  height: 22px;
  position: relative;

  svg {
    position: absolute;
    width: 30px;
    height: 30px;
    left: -7px;
    top: -6px;
  }
}

.logoIcon {
  margin-top: -64px;
  margin-left: auto;
  margin-right: auto;
}

.dismissIcon {
  fill: var(--notification-text-color);
  margin-right: -2px;
}

.buttons {
  display: flex;
  width: 100%;
  padding: 5px;
  justify-content: flex-end;
}
