@import "styles/variables.module";

@mixin screen {
  @media only screen {
    @content;
  }
}

@mixin media-xxl {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

@mixin media-xl {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin media-lg {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin media-md {
  @media only screen and (max-width: $media-md-breakpoint) {
    @content;
  }
}

@mixin media-sm {
  @media only screen and (max-width: $media-sm-breakpoint) {
    @content;
  }
}

@mixin media-xs {
  @media only screen and (max-width: 540px) {
    @content;
  }
}

@mixin media-xxs {
  @media only screen and (max-width: 420px) {
    @content;
  }
}

@mixin sidebar-opened {
  body[data-sidebar-opened] {
    @content;
  }
}

@mixin sidebar-collapsed {
  body:not([data-sidebar-opened]) {
    @content;
  }
}

@mixin side-panel-opened {
  body[data-side-panel-opened] {
    @content;
  }
}

@mixin side-panel-collapsed {
  body:not([data-side-panel-opened]) {
    @content;
  }
}

@mixin dark {
  :root[theme="dark"] {
    @content;
  }
}
