@import "styles/mixins";

:root {
  --thunk-text: var(--thunk-grey-800);
}


@include dark {
  color-scheme: dark;

  --thunk-text: var(--thunk-grey-400);
}
