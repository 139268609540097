@import "styles/mixins";

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.customFileUpload {
  cursor: pointer;

  input[type="file"] {
    display: none;
  }

  .uploadButton {
    pointer-events: none;
  }
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
  margin-bottom: 10px;

  & > * {
    margin-left: 12px;
    margin-top: 10px;
  }
}

.avatarContainer {
  position: relative;
  margin-right: 6px;
}

@include media-sm {
  .container {
    justify-content: center;
  }

  .buttons {
    justify-content: center;
  }
}
