@import "styles/mixins";

:root {
  --selected-link-background-color: rgba(var(--thunk-light-blue-300), 1);
  --selected-link-color: rgba(var(--thunk-blue-700), 1);
}

@include dark {
  --selected-link-background-color: rgba(var(--thunk-blue-400-dark), 1);
  --selected-link-color: rgba(var(--thunk-blue-400), 1);
}

.selected {
  text-decoration: underline!important;
  background-color: var(--selected-link-background-color);
  box-shadow: 0 0 0 3px var(--selected-link-background-color);
  border-radius: 2px;
  color: var(--selected-link-color) !important;
}
