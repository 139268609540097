@import "styles/mixins";

:root {
  --referencesTitle-border-color: #e7ddff;
  --referencesTitle-background-color: #faf7ff;
}

@include dark {
  --referencesTitle-border-color: #151a26;
  --referencesTitle-background-color: #1c2233;
}

.referencesSection {
  margin-bottom: 90px;
  border: 1px solid var(--card-border-color);
  border-radius: 20px;
  overflow: hidden;
}

.collapseButton {
  color: pink;
}

.refHeaderCollapsed {
  border-radius: 0px 0px 30px 30px;
}

.refCollapseButton {
  background-color: var(--references-collapse-button-bg-color);
  padding: var(--card-padding-horizontal);
  width: 100%;
  padding: 20px 0px 20px 9px;

  &:hover {
    background-color: var(--references-collapse-button-bg-color-hover);

    // .referencesTitle {
    //   color: var(--primary-color);
    // }
    // .refCollapseArrow {
    //   color: var(--primary-color);
    // }
  }
}

.refCollapseArrow {
  color: var(--text-color);
}

.refGroupsContainer {
  // padding-bottom: 34px;
  padding-top: 16px;
  border-top: 1px solid var(--card-border-color);
  background-color: var(--references-background-color);
}

.referencesTitle {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

[data-card-lg] {
  .refCollapseButton {
    padding-left: calc(var(--card-pl) - 30px);
    padding-right: var(--card-pr);
  }

  .referencesSection {
    margin-bottom: 0;
    border-radius: 0px;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  .refHeaderCollapsed {
    .refCollapseButton {
      padding-left: 20px;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

[data-card-xs] {
  .refCollapseButton {
    padding-left: calc(var(--card-pl) - 10px);
    padding-right: var(--card-pr);
  }
}

@include media-sm {
  .referencesSection {
    border-radius: 0 !important;
    border: none !important;
  }

  .refHeaderCollapsed {
    border: none;
  }
}